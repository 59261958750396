import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import Basic from "../Assets/Images/Basic.svg";
import Side from "../Assets/Images/Side.svg";
import Pro from "../Assets/Images/Pro.svg";

const EcommerceService = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `عز للتحول الرقمي - ${t("e-commerce-design")}`;
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 text-center" data-aos="zoom-in-down">
              <h1 className="p-5 text-warning font-weight-bold">{t("e-commerce-design")}</h1>
            </div>
          </div>
          {t("language") === "en" ? (
            <div>
              <div className="row text-center">
                <div className="col-lg-12">
                  <h2 dir="RTL" className="title-service">
                    <strong>يمكنك الاختيار من بين التصميمات التاليه</strong>
                  </h2>
                </div>
                <div className="col-lg-4">
                  <img className="img-fluid mb-3 rounded" src={Pro} alt="pro" />
                  <h3 className="mt-3 text-danger">
                    <strong>برو</strong>
                  </h3>
                </div>
                <div className="col-lg-4">
                  <img className="img-fluid mb-3 rounded" src={Side} alt="side" />
                  <h3 className="mt-3 text-warning">
                    <strong>بلس</strong>
                  </h3>
                </div>
                <div className="col-lg-4">
                  <img className="img-fluid mb-3 rounded" src={Basic} alt="basic" />
                  <h3 className="mt-3 text-primary">
                    <strong>بيسك</strong>
                  </h3>
                </div>
              </div>
              <hr className="m-5" />
            </div>
          ) : (
            <div>
              <div className="row text-center">
                <div className="col-lg-12">
                  <h2 className="title-service">
                    <strong>You can choose between these options:</strong>
                  </h2>
                </div>
                <div className="col-lg-4">
                  <img className="img-fluid mb-3 rounded" src={Basic} alt="basic" />
                  <h3 className="mt-3 text-primary">
                    <strong>Basic</strong>
                  </h3>
                </div>
                <div className="col-lg-4">
                  <img className="img-fluid mb-3 rounded" src={Side} alt="side" />
                  <h3 className="mt-3 text-warning">
                    <strong>Plus</strong>
                  </h3>
                </div>

                <div className="col-lg-4">
                  <img className="img-fluid mb-3 rounded" src={Pro} alt="pro" />
                  <h3 className="mt-3 text-danger">
                    <strong>Pro</strong>
                  </h3>
                </div>
              </div>
              <hr className="m-5" />
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="zoom-in">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EcommerceService;
