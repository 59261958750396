import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - سياسة الخصوصية";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container pt-5" data-aos="zoom-in">
      <div className="row pt-5">
        <div className="col-lg-12 p-4 mt-3 text-center">
          <h1 className="text-warning">{t("privacypolicy")}</h1>
          {t("language") === "en" ? (
            <div className="text-right mt-5">
              <h2 dir="RTL" className="font-weight-bold">
                الخصوصية وبيان سريّة المعلومات
              </h2>
              <p dir="RTL">
                نقدر مخاوفكم واهتمامكم بشأن خصوصية بياناتكم على شبكة الإنترنت.
                <br />
                لقد تم إعداد هذه السياسة لمساعدتكم في تفهم طبيعة البيانات التي نقوم بتجميعها منكم عند زيارتكم لموقعنا على شبكة
                الانترنت وكيفية تعاملنا مع هذه البيانات الشخصية.
              </p>
              <h2 dir="RTL" className="font-weight-bold">
                كيف نحمي معلوماتك؟
              </h2>
              <p dir="RTL">
                يتم فحص موقعنا على أساس منتظم لثغرات الأمن ونقاط الضعف المعروفة من أجل جعل زيارتك لموقعنا آمنة قدر الإمكان.
                <br />
              </p>
              <p dir="RTL">نحن نستخدم مسح البرامج الضارة العادية.</p>
              <p dir="RTL">
                وترد المعلومات الشخصية الخاصة بك وراء الشبكات المضمونة ولا يمكن الوصول إليها إلا من قبل عدد محدود من الأشخاص الذين
                لديهم حقوق خاصة للوصول إلى هذه النظم، ويطلب منهم للحفاظ على سرية المعلومات.
                <br />
              </p>
              <p dir="RTL">
                نحن ننفذ مجموعة متنوعة من التدابير الأمنية عندما يضع المستخدم طلبا يدخل أو يرسل أو يصل إلى معلوماته للحفاظ على
                سلامة المعلومات الشخصية الخاصة بك.
              </p>
              <h2 dir="RTL" className="font-weight-bold">
                هل نستخدم "ملفات تعريف الارتباط"؟
              </h2>
              <p dir="RTL">نحن لا نستخدم ملفات تعريف الارتباط لأغراض التتبع</p>
              <p dir="RTL">
                يمكنك اختيار أن يقوم جهاز الكمبيوتر بتحذيرك في كل مرة يتم فيها إرسال ملف تعريف ارتباط، أو يمكنك اختيار إيقاف تشغيل
                كافة ملفات تعريف الارتباط. يمكنك إجراء ذلك من خلال إعدادات المتصفح. نظرا لأن المتصفح مختلف قليلا، فراجع قائمة
                المساعدة في المتصفح للتعرف على الطريقة الصحيحة لتعديل ملفات تعريف الارتباط.
              </p>
              <p dir="RTL">
                إذا قمت بإيقاف تشغيل ملفات تعريف الارتباط، فقد لا تعمل بعض الميزات التي تجعل موقعك أكثر كفاءة على نحو سليم. مما
                يجعل موقعك أكثر كفاءة وقد لا يعمل بشكل صحيح.
              </p>
              <h2 dir="RTL" className="font-weight-bold">
                الكشف من طرف ثالث
              </h2>
              <p dir="RTL">نحن لا نبيع معلوماتك الشخصية أو نتعامل معها أو نقلها إلى أطراف خارجية.</p>
              <h2 dir="RTL" className="font-weight-bold">
                إفشاء المعلومات
              </h2>
              <p dir="RTL">
                سنحافظ في كافة الأوقات على خصوصية وسرية كافة البيانات الشخصية التي نتحصل عليها. ولن يتم إفشاء هذه المعلومات إلا
                إذا كان ذلك مطلوباً بموجب أي قانون أو عندما نعتقد بحسن نية أن مثل هذا الإجراء سيكون مطلوباً أو مرغوباً فيه للتمشي
                مع القانون , أو للدفاع عن أو حماية حقوق الملكية الخاصة بهذا الموقع أو الجهات المستفيدة منه.
              </p>
              <h2 dir="RTL" className="font-weight-bold">
                عند الاتصال بنا
              </h2>
              <p dir="RTL">
                سيتم التعامل مع كافة البيانات المقدمة من قبلك على أساس أنها سرية . تتطلب النماذج التي يتم تقديمها مباشرة على
                الشبكة تقديم البيانات التي ستساعدنا في تحسين موقعنا.سيتم استخدام البيانات التي يتم تقديمها من قبلك في الرد على
                كافة استفساراتك , ملاحظاتك , أو طلباتك من قبل هذا الموقع أو أيا من المواقع التابعة له .
              </p>
              <h2 dir="RTL" className="font-weight-bold">
                الاتصال بنا
              </h2>
              <p dir="RTL">
                إذا كانت لديك أية أسئلة بخصوص سياسة الخصوصية هذه، يمكنك الاتصال بنا باستخدام المعلومات أدناه.
                <br />
                <br />
                <strong>مؤسسة عز للتحول الرقمي</strong>
                <br />
                العلا
                <br />
                المملكة العربية السعودية
                <br />
                info@ezzdt.com
                <br />
                966530559346
              </p>
            </div>
          ) : (
            <div className="text-left mt-5">
              <h2>
                <strong>Privacy and confidentiality of information </strong>
              </h2>
              <p>We appreciate your concerns and concern about the privacy of your data on the Internet.</p>
              <p>
                This policy has been prepared to help you understand the nature of the data That we collect from you when you
                visit our website and how we deal with this personal data .
              </p>
              <h2>
                <strong>How Protect your information? </strong>
              </h2>
              <p>
                Our site is checked on a regular basis for security holes and known vulnerabilities. In order to make your visit
                to our website as safe as possible.
              </p>
              <p>We use regular malware scans.</p>
              <p>
                Your personal information is contained behind secured networks and can only be accessed by a limited number of
                people who have special rights to access these systems, and they are required to keep On confidentiality of
                information.
              </p>
              <p>
                We implement a variety of security measures when a user places an application that enters, sends, or accesses
                their information to maintain the safety of private personal information.
              </p>
              <h2>
                <strong>Do we use "cookies"? </strong>
              </h2>
              <p>We do not use cookies for tracking purposes</p>
              <p>
                you can choose to have your computer warn you every time a cookie is sent, or you can choose to have enough off
                Cookies. You can do this through your browser settings. Since the browser is a little different, see the browser's
                Help menu to learn the correct way to modify cookies.
              </p>
              <p>
                if you turn off Cookies, some features that make your site more efficient may not function properly. This makes
                your site more efficient and may not function properly.
              </p>
              <h2>
                <strong>3rd party detection </strong>
              </h2>
              <p>We do not sell, handle, or transfer your personal information to outside parties.</p>
              <h2>
                <strong>Disclosure of information </strong>
              </h2>
              <p>
                We will maintain at all times the privacy and confidentiality of all personal data that we obtain. This
                information will not be disclosed unless it is required by any law or when we believe in good faith that such
                action will be required or desirable to comply with the law, or to defend or protect the property rights of this
                site or its beneficiaries.
              </p>
              <h2>
                <strong>When contacting us </strong>
              </h2>
              <p>
                All data provided by you will be treated as confidential. Forms submitted directly on the network require the
                provision of data that will help us improve our site. The data provided by you will be used to respond to all your
                inquiries, comments, or requests from this site or any of its affiliates.
              </p>
              <h2>
                <strong>Contact us </strong>
              </h2>
              <p>If you have any questions about this privacy policy, you cannot Contact us using the information below.</p>
              <p>Ezz Foundation for Digital Transformation</p>
              <p>Alula</p>
              <p>Saudi Arabia</p>
              <p>info@ezzdt.com</p>
              <p>966530559346</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
