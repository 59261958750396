import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import question from "../Assets/Images/question.svg";
import programming from "../Assets/Images/programming.svg";
import content from "../Assets/Images/content.svg";
import launch from "../Assets/Images/launch.svg";
import support from "../Assets/Images/support.svg";
import webDesign from "../Assets/Images/webDesign.svg";
import analysis from "../Assets/Images/analysis.svg";
import page from "../Assets/Images/page.svg";

const WebDesignService = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `عز للتحول الرقمي - ${t("create-web")}`;
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 text-center" data-aos="zoom-in-down">
              <h1 className="p-5 text-warning font-weight-bold">{t("create-web")}</h1>
            </div>
          </div>
        </div>
        {t("language") === "en" ? (
          <div>
            <div className="container">
              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={question} alt="phone" />
                </div>
                <div className="col-lg-7 text-center mt-5">
                  <h3 dir="RTL">
                    <strong>هل تبحث عن وسيلة لتحويل أفكارك وأحلامك إلى رؤية ملموسة على أرض الواقع؟</strong>
                  </h3>
                  <h3 dir="RTL" className="mt-5">
                    <strong>هل ترغب في تصميم موقع جديد خاص بمشروعك ولا تعرف من أين تبدأ؟</strong>
                  </h3>
                </div>
              </div>
              <br />
              <br />
              <div className="text-center">
                <h4 dir="RTL">
                  يسرُنا في مؤسسة عز للتحول الرقمي أن نُساعدك على تحقيق هدفك، فنحن نقدم خدمات تصميم مواقع الإنترنت بشكل مُميز
                  واحترافي يخدم الهدف الرئيسي للموقع ونوفر لكم أحدث الخدمات والأفكار التي قد تُساعد على إنشاء موقع إلكتروني قوي
                  واحترافي.
                </h4>
              </div>
              <br />
              <br />
              <div className="text-center">
                <h2 dir="RTL" className="title-service">
                  <strong>أضف اللمسات السحرية الى موقعك الإلكتروني</strong>
                </h2>
              </div>
            </div>
            <div className="container-fluid text-center web-text">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <img className="img-fluid" src={page} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-right">
                    <h5 dir="RTL" className="mt-5">
                      لأننا ندرك تماما أن موقعك الإلكتروني هو لاعب هام جدًا ضمن إستراتيجية التسويق الخاص بك
                    </h5>
                    <h5 dir="RTL" className="mt-5">
                      فنحن في مؤسسة عز للتحول الرقمي نسعى دائما لبذل قصارى جهدنا لنصل بالموقع الخاص بكم لكي يحتوي على جميع المزايا
                      التي تساعد على نجاحكم.
                    </h5>
                    <h5 dir="RTL" className="mt-5">
                      ندرك تماما أن الموقع الإلكتروني لشركتكم او مشروعكم يعتبر محور وركيزة نشاطك عبر صفحات الإنترنت
                    </h5>
                    <h5 dir="RTL" className="my-5">
                      كما ندرك أنه حيث ستتمكن من جني المزيد من الارباح بإذن الله ولذلك نعمل بكد على تصميم الموقع ليخرج بالشكل الذي
                      يتناسب مع متطلبات عملكم ويكون أساس قوي لاستثمار ناجح.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center container">
              <h2 dir="RTL" className="title-service">
                <strong>
                  ونوفر لكم أساس قوي من خلال تصميم موقع يحتوي على أهم ثلاث مميزات قد يحتاج إليها أي موقع ناجح منذ البداية ألا وهي:
                </strong>
              </h2>

              <ul className="steps">
                <li>
                  <div className="card">
                    <h2 className="number">1</h2>
                    <div className="card-body">
                      <h5 className="card-title">سهولة وسلاسة وسرعة في تصفح الزائر للموقع</h5>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <h2 className="number">2</h2>
                    <div className="card-body">
                      <h5 className="card-title">قالب أو تصميم متجاوب مع جميع الاجهزة</h5>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <h2 className="number">3</h2>
                    <div className="card-body">
                      <h5 className="card-title">تصميم جذاب يترك بصمة في ذهن الزائر</h5>
                    </div>
                  </div>
                </li>
              </ul>

              <h2 dir="RTL" className="title-service">
                <strong>خطوات تصميم مواقع الكترونية مميزة </strong>
              </h2>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={analysis} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة اكتشاف وتحليل مشروعك</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    تّعد هذه المرحلة من أهم مراحل تصميم موقع إلكتروني ويقوم فريق عمل عز للتحول الرقمي المُكون من مجموعة من أفضل
                    مصممي ومطوري المواقع بدراسة فكرة مشروعك ومجال عملك ودراسة المنافسين وظروف العمل، ثم بعد ذلك يقوم محترفو عز
                    للتحول الرقمي بعمل تصور كامل لكيفية تصميم وتطوير موقعك الإلكتروني ليُلبي احتياجات عملك.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة التصميم والإبداع</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يعمل المصممين لدينا في هذه المرحلة على تصميم موقع إلكتروني جذاب ومميز يخطف عين وقلب كل من يتصفحه، حيث نعمل في
                    عز للتحول الرقمي على إنشاء تصميم موقع بألوان بسيطة وجذابة تناسب طبيعة عمل مشروعك، ويهتم المهندسون المختصون
                    لدينا بدراسة كل تفاصيل التصميم بحيث يكون موقع{" "}
                    <a
                      href="https://ar.wikipedia.org/wiki/%D9%82%D8%A7%D8%A8%D9%84%D9%8A%D8%A9_%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AE%D8%AF%D8%A7%D9%85"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      سهل الاستخدام{" "}
                    </a>
                    وسهل الوصول وتصميمه بسيطة ومريح للعين ويُلبي احتياجات زائر موقعك.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={webDesign} alt="phone" />
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={programming} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة التطوير والبرمجة</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    إن مرحلة برمجة موقع الكتروني في مؤسسة عز تُعد من أهم المراحل على الإطلاق حيث يتم تحويل التصميم الذي تم عمله
                    بعناية شديدة في الخطوة السابقة من مجموعة من الصور والايقونات إلى موقع حي يعمل بالفعل ويمكنك إستخدامة من قبل أي
                    متصفح، ويعمل مصممي مؤسسة عز على تطوير وبرمجة موقعك الإلكتروني بأحدث التقنيات والتكنولوجيا المستخدمة على
                    الإطلاق ليصبح موقعك سريعا وسهل الاستخدام وخالي من الاخطاء التقنية، وهذا هو ما يجعل عز للتحول الرقمي افضل مؤسسة
                    تصميم مواقع الكترونية بالمملكة.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة إنشاء محتوى موقعك الإلكتروني</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    نمتلك باقة من أفضل صانعي المحتوى ويمكن في مؤسسة عز إنشاء محتوى موقعك مهما كانت لغة موقعك سواء كانت اللغة
                    العربية أو الإنجليزية أو غيرها من اللغات الاخرى، يقوم المتخصصين لدى مؤسسة عز بإنشاء محتوى كافة صفحات موقعك
                    سواء كان ذلك محتوى مكتوب أو مرئي ويعمل فريقنا لحظة بلحظة على تحسين وتطوير محتوى موقعك لان المحتوى هو ما يبحث
                    عنه الزائر دائما.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={content} alt="phone" />
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={launch} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة إطلاق الموقع</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يُصبح موقعك في هذه المرحلة جاهزا لإنطلاقة قوية لا تتراجع، حيث تتم مراجعة الموقع من جانبك وبعد الموافقة عليه
                    يتم إطلاق الموقع ليصبح متاحا لكافة المستخدمين المستهدفين لشركتك أو مشروعك.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>الدعم الفني والتحسين</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    إذا كنت تسأل لماذا عز للتحول الرقمي هي افضل شركة تصميم مواقع الكترونية في السعودية فيمكنني ان اطرح لك عددًا لا
                    بأس به من المميزات التي لن تجدها في الشركات او المؤسسات الاخرى وأحد أهم تلك المميزات هو الدعم الفني الإحترافي
                    الذي يقدم لك على مدار الاسبوع، فنحن في عز للتحول الرقمي نستمع دائما إلى شكواك وتعديلاتك ونتفهم أفكارك ونعمل
                    على تنفيذ طلباتك الهامة لتحسين مشروعك.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={support} alt="phone" />
                </div>
              </div>

              <h2 dir="RTL" className="title-service">
                <strong>الأسئلة الشائعة عن خدمة تصميم مواقع الكترونية </strong>
              </h2>
              <h5 className="mt-4 text-right">
                <strong>سؤال: هل يمكنني رؤية تصميم موقعك قبل إطلاقه؟</strong>
              </h5>
              <h5 className="mt-4 text-right">
                جواب: نعم، فبعد مرحلة تصميم موقعك يقوم فريقنا بمراسلتك لمراجعة التصميم ووضع تعديلاتك الخاصة والمناقشة حول كيفية
                تطوير الموقع من ناحية التصميم وسهولة الإستخدام.
              </h5>
              <h5 className="mt-4 text-right">
                <strong>سؤال: هل يمكن إعادة تصميم موقعي الذي تم إنشاء بواسطة شركة أخرى؟</strong>
              </h5>
              <h5 className="mt-4 text-right">
                جواب: نعم يمكننا، فنحن في عز للتحول الرقمي سنعمل على إعادة تصميم وتكوين عناصر موقعك الالكتروني من البداية للوصول
                إلى نتيجة رائعة وتصميم جديد مميزة وسريع.
              </h5>
              <h5 className="mt-4 text-right">
                <strong>سؤال: هل يمكنكم مساعدتي في كتابة محتوى موقعي؟</strong>
              </h5>
              <h5 className="mt-4 text-right">
                جواب: بالطبع يمكننا مساعدتك في كتابة وإنشاء محتوى موقعك، فنحن في عز للتحول الرقمي نمتلك صانعي محتوى مميزين جاهزين
                لمساعدتك في تطوير شامل لمحتوى موقع الإلكتروني.
              </h5>
              <hr className="m-5" />
            </div>
          </div>
        ) : (
          <div>
            <div className="container">
              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={question} alt="phone" />
                </div>
                <div className="col-lg-7 text-center mt-5">
                  <h3>
                    <strong>
                      Are you looking for a way to transform your thoughts and dreams into a concrete vision on the ground?
                    </strong>
                  </h3>
                  <h3 className="mt-5">
                    <strong> Do you want to design a new website for your project and don't know where to start? </strong>
                  </h3>
                </div>
              </div>
              <br />
              <br />
              <div className="text-center">
                <h4>
                  We at Ezz Foundation for Digital Transformation are pleased to help you achieve your goal, as we provide
                  distinctive web design services And professionally serves the main objective of the site and provide you with
                  the latest services and ideas that may help to create a strong website And professionally.
                </h4>
              </div>
              <br />
              <br />
              <div className="text-center">
                <h2 className="title-service">
                  <strong> Add the Magic Touch to Your Website </strong>
                </h2>
              </div>
            </div>
            <div className="container-fluid text-center web-text">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <img className="img-fluid" src={page} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-left">
                    <h5 className="mt-5">
                      Because we fully understand that your website is a very important player in your marketing strategy
                    </h5>
                    <h5 className="mt-5">
                      We at Ezz Foundation for Digital Transformation always strive to do our best to reach your site in order to
                      contain all the features That helps your success.
                    </h5>
                    <h5 className="mt-5">
                      We are fully aware that the website of your company or project is the focus and pillar of your activity on
                      the Internet
                    </h5>
                    <h5 className="my-5">
                      We also realize that where you will be able to reap more profits, God willing, and therefore we are working
                      hard on designing the site to come out as It matches the requirements of your business and forms a strong
                      basis for a successful investment.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center container">
              <h2 className="title-service">
                <strong>
                  We provide you with a strong foundation by designing a site that contains the three most important features that
                  any successful website may need from the start, namely:
                </strong>
              </h2>

              <ul className="steps">
                <li>
                  <div className="card">
                    <h2 className="number"> 1 </h2>
                    <div className="card-body">
                      <h5 className="card-title"> Ease, smoothness and speed of visitor browsing the site </h5>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <h2 className="number"> 2 </h2>
                    <div className="card-body">
                      <h5 className="card-title"> Responsive template or design for all devices </h5>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <h2 className="number"> 3 </h2>
                    <div className="card-body">
                      <h5 className="card-title"> Attractive design that leaves an imprint on the visitor's mind </h5>
                    </div>
                  </div>
                </li>
              </ul>

              <h2 className="title-service">
                <strong> Steps to design distinctive websites </strong>
              </h2>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={analysis} alt="phone" />
                </div>
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> The Discovery and Analysis phase of your project </strong>
                  </h3>
                  <p className="mt-5">
                    This stage is one of the most important stages of designing a website, and the Ezz Digital Transformation Team
                    is made up of a group of the best Website designers and developers study your project idea and your field of
                    business, competitors and work conditions, then after that, Ezz professionals For digital transformation by
                    fully visualizing how to designAnd develop your website to meet your business needs.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> Design and Creativity Stage </strong>
                  </h3>
                  <p className="mt-5">
                    Our designers are working at this stage to design an attractive and distinctive website that catches the eye
                    and the heart of everyone who browses it, as we work in Promise for digital transformation to create a website
                    design in simple and attractive colors that suit the nature of your project's work, and the specialized
                    engineers care We have to study all the details of the design so that the site {""}
                    <a
                      href="https://ar.wikipedia.org/wiki/%D9%82%D8%A7%D8%A8%D9%84%D9%8A%D8%A9_%D8%A7%D9%84%D8% A7% D8% B3% D8% AA% D8% AE% D8% AF% D8% A7% D9% 85"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {""}
                      easy to use{" "}
                    </a>
                    It is easy to access and its design is simple and easy on the eye and meets the needs of your site visitor.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={webDesign} alt="phone" />
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={programming} alt="phone" />
                </div>
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> Development and Programming Phase </strong>
                  </h3>
                  <p className="mt-5">
                    The stage of programming a website at Ezz is one of the most important stages of all, when the design that has
                    been made is transferred Very carefully, in the previous step, from a set of images and icons to a live
                    website that already works and that you can use it by any Browser, and the designers of the Ezz Foundation
                    work on developing and programming your website with the latest technologies and technologies used on The
                    launch to make your site fast, easy to use and free from technical errors, and this is what makes Ezz for
                    digital transformation the best institution Designing websites in the Kingdom.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> Your website content creation phase </strong>
                  </h3>
                  <p className="mt-5">
                    We have a bunch of the best content creators, and at Ezz Corporation, you can create your site's content,
                    whatever the language of your site, whether it is the language Arabic, English or other languages, the
                    specialists at Ezz create the content of all pages of your site Whether it is written or visual content, our
                    team works moment by moment to improve and develop the content of your site because the content is what is
                    looking Always visiting him.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={content} alt="phone" />
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={launch} alt="phone" />
                </div>
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> Site launch phase </strong>
                  </h3>
                  <p className="mt-5">
                    At this stage, your site is ready for a strong start, as the site is reviewed by you and after approval The
                    site is launched to be available to all targeted users of your company or project.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> Technical Support and Improvement </strong>
                  </h3>
                  <p className="mt-5">
                    If you are asking why Ezz for digital transformation is the best website design company in Saudi Arabia, I can
                    ask you a number that does not There are quite a few features that you will not find in other companies or
                    institutions, and one of the most important of these features is professional technical support Which is
                    presented to you throughout the week, we are in the glory of digital transformation, we always listen to your
                    complaints and adjustments, understand your ideas and act To fulfill your important requests to improve your
                    project.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={support} alt="phone" />
                </div>
              </div>

              <h2 className="title-service">
                <strong> Web Design Service FAQ </strong>
              </h2>
              <h5 className="mt-4 text-left">
                <strong> Question: Can I see your site's design before launching it? </strong>
              </h5>
              <h5 className="mt-4 text-left">
                Answer: Yes, after the stage of designing your site, our team will contact you to review the design, make your own
                modifications, and discuss how Website development in terms of design and ease of use.
              </h5>
              <h5 className="mt-4 text-left">
                <strong> Question: Can my website be redesigned by another company? </strong>
              </h5>
              <h5 className="mt-4 text-left">
                Answer: Yes, we can. We are in the glory of digital transformation. We will redesign and reconfigure the elements
                of your website from scratch To a wonderful result and a distinctive new design and fast.
              </h5>
              <h5 className="mt-4 text-left">
                <strong> Question: Can you help me write the content for my website? </strong>
              </h5>
              <h5 className="mt-4 text-left">
                Answer: Of course, we can help you in writing and creating the content of your site, as we are in the glory of
                digital transformation and have distinguished content makers ready To assist you in the comprehensive development
                of website content.
              </h5>
              <hr className="m-5" />
            </div>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="zoom-in">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WebDesignService;
