import React, { useState, useEffect } from "react";
import logo from "../Assets/Images/Logo.png";
import { Link } from "react-router-dom";
import firebase from "firebase";
import Fire from "../Api/Fire";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const [state, setState] = useState("py-3");
  const [user, setUser] = useState(null);
  const [recruitment, setRecruitment] = useState(false);
  const [isPhone, setisPhone] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { t, i18n } = useTranslation();

  const handleScroll = () => {
    window.scrollY > 20 ? setState("active") : setState("py-3");
  };

  const handleResize = () => {
    setisPhone(window.innerWidth <= 760);
  };

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged((user) => {
      if (!user) return;
      if (!user.emailVerified) return;
      Fire.getUser(user.uid).then((u) => setUser(u));
    });

    //get employment info
    Fire.getRecruitmentCondition().then((response) => setRecruitment(response));
    handleResize();

    //Change language
    i18n.changeLanguage(localStorage.getItem("language") || "ar");

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
      subscriber();
    };
  }, [i18n]);

  const changeLanguage = () => {
    localStorage.setItem("language", t("language"));
    i18n.changeLanguage(t("language"));
  };

  return (
    <header>
      <nav className={`navbar navbar-expand-lg fixed-top ${state}`}>
        <div className="container">
          <button
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler navbar-toggler-right"
          >
            <i className="fa fa-bars"></i>
          </button>

          {isPhone ? (
            <Link to="/" className="navbar-brand font-weight-bold ml-auto" data-aos="fade-down">
              <img className="brand" src={logo} alt="" />
            </Link>
          ) : null}

          <div id="navbarSupportedContent" className="collapse navbar-collapse" data-aos="fade-down">
            <ul className="navbar-nav mr-auto align-items-center">
              <li className="nav-item pr-1">
                <button className="signIn call" onClick={changeLanguage}>
                  {t("language") === "en" ? "English" : "العربية"}
                </button>
              </li>

              {user != null ? (
                <li className="nav-item d-flex mr-1 align-items-center flex-wrap signIn py-1">
                  <img className="rounded-circle profilePic mr-2" src={user.image} alt="profilePic" />
                  <Link to={user.type === "Admin" ? "/adminMain" : "/employeeMain"} className="text-light font-weight-bold">
                    {`${user.firstName} ${user.lastName}`}
                  </Link>
                  <i className="fa fa-sign-out signout pl-2" title="Sign Out" onClick={() => Fire.signOut()}></i>
                </li>
              ) : (
                <li className="nav-item pr-1">
                  <Link to="/login" className="nav-link">
                    <button className="signIn">
                      {t("login")}
                      <i className="fa fa-user-o ml-2" />
                    </button>
                  </Link>
                </li>
              )}

              <li className="nav-item pr-1">
                <button className="signIn" onClick={() => setShowForm(true)}>
                  {t("call")}
                  <i className="fa fa-heart ml-2" />
                </button>
              </li>

              {recruitment ? (
                <li className="nav-item pr-1">
                  <Link to="/recruitment" className="nav-link">
                    {t("join")}
                  </Link>
                </li>
              ) : null}

              <li className="nav-item">
                <Link to="/blogs" className="nav-link">
                  {t("blog")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  {t("about")}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/projects" className="nav-link">
                  {t("projects")}
                </a>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link">
                  {t("services")}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  {t("main")}
                </Link>
              </li>
            </ul>
          </div>

          {!isPhone ? (
            <Link to="/" className="navbar-brand font-weight-bold mr-auto" data-aos="fade-down">
              <img className="brand" src={logo} alt="" />
            </Link>
          ) : null}

          {showForm ? (
            <div className="contact-form bg-white rounded shadow">
              <ContactForm onClick={() => setShowForm((showForm) => !showForm)} />
            </div>
          ) : null}
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
