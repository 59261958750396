import React, { useState, useEffect } from "react";
import Fire from "../Api/Fire";
import ErrorDialog from "../components/errorDialog";
import SuccessDialog from "../components/successDialog";
import countries from "../util/countries";
import Searchable from "react-searchable-dropdown";
import { useTranslation } from "react-i18next";

const Recruitment = () => {
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    country: "",
    city: "",
    qualification: "الثانوية",
    resume: null,
    birthday: "",
    experience: "",
    major: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - أنضم الينا";
    window.scrollTo(0, 0);
  }, []);

  //Publish Announcement to team.
  const addEmployee = (event) => {
    setLoading((loading) => true);
    event.preventDefault();
    if (
      user.firstName.trim().length === 0 ||
      user.lastName.trim().length === 0 ||
      user.country.length === 0 ||
      user.city.length === 0 ||
      user.qualification.length === 0 ||
      user.birthday.length === 0 ||
      user.phone.length === 0 ||
      user.email.length === 0 ||
      user.major.length === 0 ||
      user.experience.length === 0 ||
      user.resume === null
    ) {
      setError((error) => t("fill"));
      setShowForm((showForm) => true);
      setLoading((loading) => false);
      window.scrollTo(window.innerWidth / 2, window.innerHeight / 2);
    } else {
      Fire.addRecruitment(user).then((response) => {
        if (response) {
          setShowFormSuccess((showFormSuccess) => true);
        } else {
          setError((error) => response);
          setShowForm((showForm) => true);
          window.scrollTo(window.screen / 2, window.screen / 2);
        }
        setLoading((loading) => false);
      });
    }
  };

  return (
    <main>
      <div className="container">
        <div className="row pt-5" data-aos="zoom-in">
          <div className="col-lg-12">
            <div>
              {t("language") === "en" ? (
                <div>
                  <h3 className="card-title text-center">أهلا بك في عز للتحول الرقمي</h3>
                  <div className="text-right mb-5">
                    <h5 className="card-title">
                      <b>: عن الوظيفة</b>
                    </h5>
                    <p>
                      الوظيفة تكون عن بعد. انت ستكون عضو في مؤسسة عز للتحول الرقمي وهي مؤسسة توفير خدماتها في بناء الأنظمة
                      البرمجية والمواقع الإلكترونية وتقديم خدمات الاستضافة والحماية، والاستشارات التقنية وتحليل المشاريع، بسواعد
                      وطنية احترافية للمساهمة في مواكبة العصر التقني الحديث وتأمين بنية أساسية ممتازة وتأهيل وتطوير الأيدي العاملة
                      الوطنية المتخصصة، بهدف بناء مجتمع رقمي آمن واحترافي
                    </p>
                    <p>
                      نحن نبحث لتوظيف أخصائي تسويق او من يرغب بالتعلم بالتدريب ليصبح واحدا منهم لينضم الى فريق عز للتحول الرقمي .
                      هذه الوظيفة لكل شخص طموح و جاد يبحث عن وظيفة و أن يكون لديه دخل اخر مع وظيفته او مع دراسته. باختصار جعل وقت
                      خاص و جزء من يومك للوظيفة. اذا عز للتحول الرقمي هي الوظيفة المثلئ لك باذن الله. ماعليك الا التقديم علئ
                      الوظيفة
                    </p>
                    <h5 className="card-title">
                      <b>: فكرة العمل</b>
                    </h5>
                    <h5>: (يعتمد حسب طموحك) ينقسم العمل بشكل أساسي الئ قسمين أساسيين</h5>
                    <p>
                      <b> القسم الاول :</b> هو تسويق خدمات المؤسسة الربح يكون فوري من الخدمة
                    </p>
                    <p>
                      <b> القسم الثاني :</b> استقطاب اعضاء جدد للعمل ببزنسس عز و تدريبهم هذا القسم للناس الطموحين جدا و أرباحه
                      عبارة عن نسبة شهرية
                    </p>
                    <p>و تزيد الأرباح حسب جهدك و جهد الفريق و شغل الفريق و عدده</p>
                    <p>بإمكانك اختيار الخيار الاول فقط او الثاني فقط او الخيارين معا وهو الأفضل</p>
                  </div>
                </div>
              ) : (
                <div>
                  <h3 className="card-title text-center"> Welcome to Ezz for digital transformation </h3>
                  <div className="text-left mb-5">
                    <h5 className="card-title">
                      <b>About Job :</b>
                    </h5>
                    <p>
                      The job is remote. You will be a member of the Ezz Foundation for Digital Transformation, which is an
                      organization providing services in building software systems And websites and providing hosting and
                      protection services, technical consulting and project analysis, with professional national rules To
                      contribute to keeping pace with the modern technical age, to secure excellent infrastructure, and to qualify
                      and develop the specialized national workforce, With the aim of building a safe and professional digital
                      society
                    </p>
                    <p>
                      We are looking to hire a marketing specialist or anyone who wants to learn by training to become one of them
                      to join the Ezz team for digital transformation. this is Job: Every ambitious and serious person is looking
                      for a job and to have another income with his job or with his studies. In short make a special time and Part
                      of your day for the job. If Ezz for digital transformation is the ideal job for you, God willing. You only
                      have to apply for the job
                    </p>
                    <h5 className="card-title">
                      <b>Business idea :</b>
                    </h5>
                    <h5>the work is mainly divided into two main parts (Depends on your ambition) :</h5>
                    <p>
                      <b> The first section: </b> is marketing the services of the enterprise. The profit is immediate from the
                      service.
                    </p>
                    <p>
                      <b> The second section: </b> Attracting new members to work in Business Ezz and training them. This section
                      is for very ambitious people. About a monthly rate.
                    </p>
                    <p> The profits increase according to your effort, team effort, team occupancy and number.</p>
                    <p> You can choose the first option only, or the second only, or both options, which is the best. </p>
                  </div>
                </div>
              )}

              <form className={t("language") === "en" ? "text-right" : "text-left"}>
                <div className="form-group">
                  <label htmlFor="firstName">{t("firstName")}</label>
                  <input
                    type="text"
                    value={user.firstName}
                    className="col-lg-12 bg-light text-right"
                    onChange={(event) => setUser({ ...user, firstName: event.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="middleName">{t("lastName")}</label>
                  <input
                    type="text"
                    value={user.lastName}
                    className="col-lg-12 bg-light text-right"
                    onChange={(event) => setUser({ ...user, lastName: event.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">{t("birthday")}</label>
                  <input
                    type="date"
                    value={user.birthday}
                    className="col-lg-12 bg-light text-right"
                    onChange={(event) => setUser({ ...user, birthday: event.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">{t("email")}</label>
                  <input
                    type="email"
                    value={user.email}
                    className="col-lg-12 bg-light text-right"
                    onChange={(event) => setUser({ ...user, email: event.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">{t("phone")}</label>
                  <input
                    type="number"
                    value={user.phone}
                    className="col-lg-12 bg-light text-right"
                    onChange={(event) => setUser({ ...user, phone: event.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="country">{t("country")}</label>
                  <div className="text-center mt-3">
                    <Searchable
                      value=""
                      placeholder={t("choose-country")}
                      notFoundText={t("no-country")}
                      options={countries.map((country) => {
                        return { label: country.name, value: country.name };
                      })}
                      onSelect={(option) => setUser({ ...user, country: option.value })}
                      listMaxHeight={200}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="city">{t("city")}</label>
                  <input
                    type="text"
                    value={user.city}
                    className="col-lg-12 bg-light text-right"
                    onChange={(event) => setUser({ ...user, city: event.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="qualification">{t("qualification")}</label>
                  <select
                    className="col-lg-12 bg-light text-right"
                    id="qualification"
                    name="qualification"
                    onChange={(event) => setUser({ ...user, qualification: event.target.value })}
                    required
                  >
                    <option value="الثانوية">الثانوية</option>
                    <option value="بكالوريوس">بكالوريوس</option>
                    <option value="ماجستير">ماجستير</option>
                    <option value="دكتوراة">دكتوراة</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="experience">{t("experience")}</label>
                  <input
                    type="text"
                    value={user.experience}
                    className="col-lg-12 bg-light text-right"
                    onChange={(event) => setUser({ ...user, experience: event.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="major">{t("major")}</label>
                  <input
                    type="text"
                    value={user.major}
                    className="col-lg-12 bg-light text-right"
                    onChange={(event) => setUser({ ...user, major: event.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="resume"> {t("resume")} </label>
                  <input
                    type="file"
                    className="col-lg-12 bg-light text-right"
                    name="resume"
                    id="resume"
                    onChange={(event) => setUser({ ...user, resume: event.target.files[0] })}
                    required
                  />
                </div>
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border text-dark" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="form-group m-0">
                    <button
                      type="button"
                      className="btn btn-warning btn-block text-light"
                      onClick={(event) => addEmployee(event)}
                    >
                      {t("confirm")}
                    </button>
                  </div>
                )}
              </form>
            </div>
            {showForm ? (
              <div className="contact-form bg-white rounded shadow">
                <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
              </div>
            ) : null}
            {showFormSuccess ? (
              <div className="contact-form bg-white rounded shadow">
                <SuccessDialog success={t("send-success")} onClick={() => setShowFormSuccess((showForm) => false)} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Recruitment;
