import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - عن المؤسسة";
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex text-center align-items-center">
              <div data-aos="zoom-in">
                <h1 className="p-5 text-warning font-weight-bold">{t("who-are-we")}</h1>
                <h4 className="text-secondary">{t("who-are-we-text")}</h4>
                <h1 className="p-5 text-warning font-weight-bold">{t("goals")}</h1>
                <h4 className="text-secondary" dir={t("language") === "en" ? "rtl" : "ltr"}>
                  {t("goals-text")}
                </h4>
                <h1 className="p-5 text-warning font-weight-bold">{t("value")}</h1>
                <h4 className="text-secondary" dir={t("language") === "en" ? "rtl" : "ltr"}>
                  {t("value-text")}
                </h4>
                <ul className="list">
                  <li> {t("value-list.first")} </li>
                  <li> {t("value-list.second")} </li>
                  <li> {t("value-list.third")} </li>
                  <li> {t("value-list.forth")} </li>
                  <li> {t("value-list.fifth")}</li>
                </ul>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default About;
