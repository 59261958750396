import React, { useEffect, useState } from "react";
import Fire from "../Api/Fire";
import { Link, useHistory } from "react-router-dom";
import ErrorDialog from "../components/errorDialog";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState("خطأ");
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - تسجيل الدخول";
    window.scrollTo(0, 0);
  }, []);

  //Login
  const login = async (event) => {
    setLoading((loading) => true);
    event.preventDefault();
    const userType = await Fire.signInWithEmail(email, password);
    setLoading((loading) => false);
    if (userType === "Admin") return history.push("/adminMain");
    if (userType === "Employee") return history.push("/employeeMain");
    setError((error) => userType);
    setShowForm((showForm) => true);
  };

  return (
    <main>
      <div className="container">
        <div className="row pt-5" data-aos="zoom-in">
          <div className="col-lg-12">
            <div className="card-wrapper">
              <div className="card-body">
                <h4 className="card-title text-center">{t("login")}</h4>
                <form className="text-right">
                  <div className="form-group">
                    <label htmlFor="email">{t("email")}</label>
                    <input
                      type="text"
                      value={email}
                      className="col-lg-12 bg-light text-right"
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">
                      {t("password")}
                      <Link to="/Reset" className="pr-2 float-left">
                        {t("forgotpass")}
                      </Link>
                    </label>
                    <input
                      type="password"
                      value={password}
                      className="col-lg-12 bg-light text-right"
                      onChange={(event) => setPassword(event.target.value)}
                      required
                    />
                  </div>

                  {loading ? (
                    <div className="text-center">
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group m-0">
                      <button
                        type="button"
                        className="btn btn-warning btn-block text-light"
                        onClick={(event) => login(event, email, password)}
                      >
                        {t("login")}
                      </button>
                    </div>
                  )}
                </form>
              </div>
              {showForm ? (
                <div className="contact-form bg-white rounded shadow">
                  <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
