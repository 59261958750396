import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ServiceCard = ({ route, title, text, icon }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={route}
      className={`card p-3 ${t("language") === "en" ? "text-right align-items-end" : "text-left align-items-start"}`}
    >
      <div className="circle"></div>
      <img src={icon} alt={title} />
      <div className="card-body">
        <h5 className="card-title text-secondary">{title}</h5>
        <p dir={t("language") === "en" ? "rtl" : "ltr"} className="text-secondary">
          {text}
        </p>
      </div>
    </Link>
  );
};

export default ServiceCard;
