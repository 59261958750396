import React, { useState, useEffect, useCallback } from "react";
import Fire from "../../Api/Fire";
import ErrorDialog from "../../components/errorDialog";
import SuccessDialog from "../../components/successDialog";
import Collapsible from "react-collapsible";
import { useTranslation } from "react-i18next";

const RecruitmentsList = () => {
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [recruitments, setRecruitments] = useState([]);
  const { t } = useTranslation();

  //Get List of Blogs
  const getAllRecruitment = useCallback(async () => {
    const recruitments = await Fire.getRecruitments();
    if (recruitments === null) {
      setError((error) => t("error"));
      setShowForm((showForm) => true);
    } else {
      setRecruitments(recruitments);
    }
    setLoading((loading) => false);
  }, [t]);

  useEffect(() => {
    document.title = "عز للتحول الرقمي - المتقدمين على الوظيفة";
    window.scrollTo(0, 0);
    getAllRecruitment();
  }, [getAllRecruitment]);

  //Delete Blog
  const deleteRecruitment = async (id) => {
    const response = await Fire.deleteRecruitment(id);
    if (response) {
      setShowFormSuccess((showFormSuccess) => true);
    } else {
      setError((error) => response);
      setShowForm((showForm) => true);
    }
    setLoading((loading) => false);
    window.location.reload();
  };

  return (
    <div className="container rounded bg-white" data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex text-center align-items-center">
          <div className="col-lg-12 py-4 text-center">
            <h4>
              <b>{t("recruitments")}</b>
            </h4>

            {loading ? (
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : recruitments.length === 0 ? (
              <h5 className="py-2">{t("no-recruitments")}</h5>
            ) : (
              <ul className="list-group text-left">
                {recruitments.map((recruitment, index) => (
                  <li key={index} className="list-group-item list-group-item-action">
                    <Collapsible
                      triggerStyle={{ cursor: "pointer" }}
                      trigger={[
                        `${recruitment.firstName} ${recruitment.lastName} • ${recruitment.major} • ${
                          recruitment.createdAt.toDate().getTime() === new Date().getTime() ? "NEW" : ""
                        }`,
                        <i className="fa fa-chevron-down pl-2" />,
                      ]}
                    >
                      <div className="text-center">
                        <button class="btn" onClick={() => deleteRecruitment(recruitment.uid)}>
                          <i className="fa fa-trash delete" />
                        </button>
                        <h2 className="p-2 font-weight-bold">{t("firstName")}</h2>
                        <h2 className="text-primary">{recruitment != null ? recruitment.firstName : "?????"}</h2>
                        <h2 className="p-2 font-weight-bold">{t("lastName")}</h2>
                        <h2 className="text-primary">{recruitment != null ? recruitment.lastName : "?????"}</h2>
                        <h2 className="p-2 font-weight-bold">{t("birthday")}</h2>
                        <h2 className="text-primary">{recruitment != null ? recruitment.birthday : "?????"}</h2>
                        <h2 className="p-2 font-weight-bold">{t("email")}</h2>
                        <h2 className="text-primary">{recruitment != null ? recruitment.email : "?????"}</h2>
                        <h2 className="p-2 font-weight-bold">{t("phone")}</h2>
                        <h2 className="text-primary">{recruitment != null ? recruitment.phone : "?????"}</h2>
                        <h2 className="p-2 font-weight-bold">{t("address")}</h2>
                        <h2 className="text-primary">
                          {recruitment != null ? `${recruitment.country}, ${recruitment.city}` : "?????"}
                        </h2>
                        <h2 className="p-2 font-weight-bold">المؤهل</h2>
                        <h2 className="text-primary">{recruitment != null ? recruitment.qualification : "?????"}</h2>
                        <h2 className="p-2 font-weight-bold">الخبرة</h2>
                        <h2 className="text-primary">{recruitment != null ? recruitment.experience : "?????"}</h2>
                        <h2 className="p-2 font-weight-bold">التخصص</h2>
                        <h2 className="text-primary">{recruitment != null ? recruitment.major : "?????"}</h2>
                        <h2 className="p-2 font-weight-bold">السيرة الذاتية</h2>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          className="text-primary"
                          href={recruitment != null ? recruitment.resume : "?????"}
                        >
                          {recruitment != null ? recruitment.resume : "?????"}
                        </a>
                      </div>
                    </Collapsible>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {showForm ? (
            <div className="contact-form bg-white rounded shadow">
              <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
            </div>
          ) : null}
          {showFormSuccess ? (
            <div className="contact-form bg-white rounded shadow">
              <SuccessDialog success={t("delete-success")} onClick={() => setShowFormSuccess((showForm) => false)} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RecruitmentsList;
