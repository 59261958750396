import React, { useState, useEffect } from "react";
import logo from "../Assets/Images/Logo.svg";
import $ from "jquery";
import { Link } from "react-router-dom";
import ContactForm from "./contactform";
import { goToWhatsApp } from "../util/methods";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [showButton, setShowButton] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();

  const handleScroll = () => {
    window.scrollY > 200 ? setShowButton(true) : setShowButton(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  //Go to top
  const onclick = () => {
    $("html, body").animate({ scrollTop: 0 }, 500);
  };

  return (
    <footer className="bg-light text-center text-lg-start">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-3 col-md-12 mb-4 mb-md-0">
            <Link to="/" className="footer-logo">
              <img className="img-fluid" src={logo} alt="" />
            </Link>
            <h4 className="text-secondary">{t("footer-success")}</h4>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">{t("location")}</h5>

            <ul className="list-unstyled mb-0">
              <li>
                <p className="text-secondary">{t("address")}</p>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">{t("links")}</h5>

            <ul className="list-unstyled">
              <li>
                <Link to="/policy" className="text-secondary">
                  <p> {t("privacypolicy")}</p>
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-secondary">
                  <p> {t("about")}</p>
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">{t("contact-us")}</h5>
            <ul className="social-network social-circle">
              <li>
                <a target="_blank" rel="noreferrer" href="https://twitter.com/ezz_dt" className="icoTwitter icon" title="Twitter">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://instagram.com/ezz.dt?igshid=1dduupkcp3tz0"
                  className="icoInstagram icon"
                  title="Instagram"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/az.digtal"
                  className="icoFacebook icon"
                  title="Facebook"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/%D8%B9%D8%B2-%D9%84%D9%84%D8%AA%D8%AD%D9%88%D9%84-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A/"
                  className="icoLinkedin icon"
                  title="Linkedin"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <div className="icon icoEmail">
                  <i className="fa fa-envelope" onClick={() => setShowForm((showForm) => !showForm)}></i>
                </div>
              </li>
              <li>
                <div className="icon icoWhatsapp">
                  <i className="fa fa-whatsapp" onClick={goToWhatsApp}></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://api.whatsapp.com/message/SFSFICINXKYEJ1"
        className="side-contact-whats"
        title="whatsapp"
      >
        <i className="fa fa-whatsapp" />
      </a>

      <a target="_blank" rel="noreferrer" href="tel:+966559798839" className="side-contact-phone" title="whatsapp">
        <i className="fa fa-phone" />
      </a>

      <div className="text-center p-3">{t("copyright")}</div>

      {showButton ? (
        <div id="back-to-top" className="back-to-top">
          <button onClick={onclick} className="btn btn-dark" title="Back to Top" style={{ display: "block" }}>
            <i className="fa fa-angle-up"></i>
          </button>
        </div>
      ) : null}

      {showForm ? (
        <div className="contact-form bg-white rounded shadow">
          <ContactForm onClick={() => setShowForm((showForm) => !showForm)} />
        </div>
      ) : null}
    </footer>
  );
};

export default Footer;
