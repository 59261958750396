import React, { useState, useEffect } from "react";
import Fire from "../../Api/Fire";
import ErrorDialog from "../../components/errorDialog";
import SuccessDialog from "../../components/successDialog";
import { useTranslation } from "react-i18next";

const AddVacationRequest = () => {
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState({
    title: "",
    message: "",
    from: "",
    to: "",
    userId: "",
    userName: "",
    userDepartment: "",
    state: "في الإنتظار",
  });
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - إضافة طلب";
    //Get User
    (async () => {
      const user = await Fire.getUser(Fire.uid);
      setRequest({
        ...request,
        userId: user.uid,
        userName: `${user.firstName} ${user.lastName}`,
        userDepartment: user.department,
      });
    })();
  }, [request]);

  //Add request
  const addRequest = async (event) => {
    setLoading((loading) => true);
    event.preventDefault();
    if (
      request.title.trim().length === 0 ||
      request.message.trim().length === 0 ||
      request.from.trim().length === 0 ||
      request.to.trim().length === 0 ||
      request.userId.trim().length === 0 ||
      request.userName.trim().length === 0 ||
      request.userDepartment.trim().length === 0
    ) {
      setError((error) => "الرجاء ملئ جميع الحقول");
      setShowForm((showForm) => true);
      setLoading((loading) => false);
    } else {
      const response = await Fire.addVacationRequest(request);
      if (response) {
        setShowFormSuccess((showFormSuccess) => true);
      } else {
        setError((error) => response);
        setShowForm((showForm) => true);
      }
      setLoading((loading) => false);
    }
  };

  return (
    <div className="container bg-white rounded" data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 text-center p-4">
          <div>
            <h2>
              <b>{t("add-vacation-requests")}</b>
            </h2>
            <form className={t("language") === "en" ? "text-right" : "text-left"}>
              <div className="form-group">
                <label htmlFor="title">{t("title")}</label>
                <input
                  type="text"
                  value={request.title}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setRequest({ ...request, title: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="from">{t("date-from")}</label>
                <input
                  type="date"
                  value={request.from}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setRequest({ ...request, from: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="to">{t("date-to")}</label>
                <input
                  type="date"
                  value={request.to}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setRequest({ ...request, to: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">{t("message")}</label>
                <textarea
                  type="text"
                  placeholder={t("message-placeholder")}
                  rows="5"
                  value={request.message}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setRequest({ ...request, message: event.target.value })}
                  required
                />
              </div>
              {loading ? (
                <div className="text-center">
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="form-group m-0">
                  <button type="button" className="btn btn-warning btn-block text-light" onClick={(event) => addRequest(event)}>
                    {t("add")}
                  </button>
                </div>
              )}
            </form>
          </div>
          {showForm ? (
            <div className="contact-form bg-white rounded shadow">
              <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
            </div>
          ) : null}
          {showFormSuccess ? (
            <div className="contact-form bg-white rounded shadow">
              <SuccessDialog success={t("add-success")} onClick={() => setShowFormSuccess((showForm) => false)} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddVacationRequest;
