import React from "react";
import { goToWhatsApp } from "../util/methods";
import { useTranslation } from "react-i18next";

const ContactForm = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="row" data-aos="zoom-in">
      <div className="col-lg-12 pt-5 pb-5 text-center align-items-center">
        {onClick != null ? (
          <div className="icon icoEmail">
            <i className="fa fa-close icon icoEmail" onClick={onClick}></i>
          </div>
        ) : null}
        {onClick == null ? (
          <div className="text-center">
            <h1 className="p-5 text-warning font-weight-bold">{t("contact-us")}</h1>
          </div>
        ) : null}
        <form action="https://formspree.io/f/mwkwzjyd" method="POST" className="col-lg-12 pl-0">
          <label className="col-lg-12 mt-4 text-warning">{t("email")}</label>
          <input className="col-lg-10 m-2 bg-light text-right" name="_replyto" placeholder={t("email-placeholder")} />
          <label className="col-lg-12 mt-4 text-warning">{t("message")}</label>
          <textarea
            className="col-lg-10 m-2 bg-light text-right p-2"
            name="message"
            rows="5"
            placeholder={t("message-placeholder")}
          />
          <button className="col-lg-10 m-2 mb-3" type="submit" value="Send">
            {t("send")}
            <span className="ml-2">
              <i className="fa fa-arrow-right"> </i>
            </span>
          </button>
          <button className="col-lg-10 m-2 mb-3 bg-success" onClick={goToWhatsApp} type="button">
            {t("whats-contact")}
            <span className="ml-2">
              <i className="fa fa-whatsapp"> </i>
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
