import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import animate from "../Assets/Images/webDesign.svg";
import business from "../Assets/Images/business.svg";
import design from "../Assets/Images/logo_design.svg";
import identity from "../Assets/Images/identity.svg";
import analysis from "../Assets/Images/analysis.svg";

const BusinessDesignService = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `عز للتحول الرقمي - ${t("market-identity")}`;
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 text-center" data-aos="zoom-in-down">
              <h1 className="p-5 text-warning font-weight-bold">{t("market-identity")}</h1>
            </div>
          </div>
          {t("language") === "en" ? (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={business} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5">
                  <h4 dir="RTL">
                    اصبح بلا شك أمرًا لا غنى عنه ويجعل لديك بصمة خاصة وفريدة لشركتك أو مؤسستك من أهم الأمور التي يمكن أن تسهم في
                    نجاحك وجعلك في المقام الأول دائمًا.
                  </h4>
                  <h4 dir="RTL" className="mt-3">
                    فمن بين ملايين العلامات التجارية المنتشرة، لا بد أن تمتلك طابعًا خاصًا يميزك عن الآخرين. هذا الطابع يسمي تصميم
                    الهوية التجارية "Brand Identity".
                  </h4>
                  <h4 dir="RTL" className="mt-3">
                    تحتاج ان تكون هوية شركتك ومؤسستك متميزة بين منافسينك لانها لها التأثير علي قرارات عملائك وكل ما كانت{" "}
                    <a
                      href="https://ar.wikipedia.org/wiki/%D9%87%D9%88%D9%8A%D8%A9_%D8%A7%D9%84%D8%B4%D8%B1%D9%83%D8%A9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      هويتك التجارية{" "}
                    </a>
                    معبرة عن نشاطك وتوفر تجربة استخدام جيدة كلما عزز ذلك الوعي بعلامتك التجارية.
                  </h4>
                  <h4 dir="RTL" className="mt-3">
                    ولذلك نمتلك في عز للتحول الرقمي مصممين جرافيك محترفين علي أعلي مستوي من الخبرة لمساعدتك في تصميم هوية تجارية
                    احترافية بأفضل الاسعار تعكس نشاطك التجاري واهدافه.
                  </h4>
                </div>
              </div>
              <br />
              <br />

              <div className="text-center">
                <h2 dir="RTL" className="title-service">
                  <strong>ما هي الهوية التجارية؟</strong>
                </h2>
                <h4 dir="RTL" className="mt-3">
                  الهوية التجارية هي الواجهة المرئية لمشروعك التي تصل بها فكرة معينة داخل عقل عملائك المحتملين، الهوية التجارية هي
                  الشعار او اللوجو الخاص بك
                  <a
                    href="https://alyomhost.com/%d8%ae%d8%af%d9%85%d8%a7%d8%aa%d9%86%d8%a7/%d8%aa%d8%b5%d9%85%d9%8a%d9%85-%d9%85%d9%88%d8%a7%d9%82%d8%b9-%d8%a7%d9%84%d9%83%d8%aa%d8%b1%d9%88%d9%86%d9%8a%d8%a9/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    وتصميم موقعك{" "}
                  </a>
                  والتطبيق الخاص بك وتصميم اللافتات الدعائية سواء كانت الكترونية أو لافتات إعلانية عادية في الشوارع والأماكن
                  العامة.
                </h4>
                <h4 dir="RTL" className="mt-3">
                  ويتم تصميم الهوية التجارية بناء على دراسات متخصصة، فعملية إختيار الألوان لا تأتي بشكل عشوائي بل عن طريق إختيار
                  تنسيق الوان معين يؤثر بشكل إيجابي على عقل عميلك المستهدف.
                </h4>

                <h2 dir="RTL" className="title-service">
                  <strong>أهمية تصميم هوية تجارية لمشروعك</strong>
                </h2>
                <h4 dir="RTL" className="mt-3">
                  قد تسأل لماذا يجب علي تصميم هوية تجارية لمشروعي الخاص وماهي هي أهمية تصميم الهوية التجارية من الأساس، إن إجابة
                  مثل هذا السؤال متعددة ولكن إختصار فالهوية التجارية تساعد مشروعك الخاص في النمو وفي انتشارة بشكل أفضل كما تساهم
                  الهوية التجارية المميزة في تحسين صورة شركتك أو مشروعك في عقل عملائك وجعلهم يتذكروك، مثلما نتذكر جميعا شعارات بعض
                  الماركات العالمية المميزة مثل اديداس ونايك وستاربكس وغيرها من الماركات.
                </h4>

                <h2 dir="RTL" className="title-service">
                  <strong>مميزات تصميم هوية تجارية لمشروعك</strong>
                </h2>
                <ul className="steps">
                  <li>
                    <div className="card">
                      <h2 className="number">1</h2>
                      <div className="card-body">
                        <h5 className="card-title">تجعل عملائك المحتملين يتذكرون شركتك ويميزها من بين غيرها</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">2</h2>
                      <div className="card-body">
                        <h5 className="card-title">تزيد الهوية التجارية ولاء عملائك لشركتك</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">3</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميم هوية تجارية مميزة يزيد من الثقة والمصداقية الخاصة بشركتك</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">4</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميم هوية تجارية مميزة يجذب إنتباه المزيد من العملاء لشركتك</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">5</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميم هوية تجارية رائعة يساعدك على تحقيق أهداف التسويق الخاصة بك</h5>
                      </div>
                    </div>
                  </li>
                </ul>

                <h2 dir="RTL" className="title-service">
                  <strong>مكونات الهوية التجارية</strong>
                </h2>
                <h3 dir="RTL" className="mb-4">
                  للهوية التجارية مكونات كثيرة وهي كالتالي:
                </h3>
                <ul className="steps">
                  <li>
                    <div className="card">
                      <h2 className="number">1</h2>
                      <div className="card-body">
                        <h5 className="card-title">الشعار او اللوجو</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">2</h2>
                      <div className="card-body">
                        <h5 className="card-title">تنسيق الالوان</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">3</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميم الفيديو الخاص بك</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">4</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميم الموشن جرافيك</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">5</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميم المنتجات الخاصة بك</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">6</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميم موقعك الإلكتروني</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">7</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميم تطبيق الجوال الخاص بمشروعك</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">8</h2>
                      <div className="card-body">
                        <h5 className="card-title">تصميمات اللافتات على متجرك او اللافتات الإعلانية</h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-center">
                <h2 dir="RTL" className="title-service">
                  <strong>خطوات تصميم هوية تجارية لمشروعك من مؤسسة عز للتحول الرقمي</strong>
                </h2>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={analysis} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة دراسة مشروعك وتحليل المنافسين</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يعمل خبراء التصميم لدينا على دراسة مجال مشروعك وعمل تحليل مُفصل لكافة المنافسين للوقوف على الطريقة المُثلي
                    لتصميم الهوية التجارية لشركتك، يضع مصممي الهويات التجارية لدينا في اعتبارهم إختيار ألوان مميزة لهويتك التجارية
                    حيث تساهم الألوان في تذكر عملائك المحتملين لهويتك التجارية.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة إختيار العناصر الأساسية لتصميم الهوية التجارية</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يقوم فريق عمل مؤسسة عز في هذه المرحلة على تحديد العناصرالأساسية لتصميم هويتك التجارية وهذه العناصر هي الألوان
                    المستخدمة، الخطوط المستخدمة في التصميم، تناسق الألوان، الأشكال والرسومات المستخدمة في تصميم هويتك التجارية.
                  </p>
                  <p dir="RTL" className="mt-5">
                    حيث يتم إستخدام ألوان التصميم بعناية فائقة اعتمادا على أساليب علمية حديثة، حيث يؤثر كل لون من الألوان الأساسية
                    بشكل ما على نفسية وعقل عميلك المحتمل، لذلك يتم اختيار تلك الألوان بعناية.
                  </p>
                  <p dir="RTL" className="mt-5">
                    كما أن الألوان مهمة جدًا فإن عملية إختيار الخطوط والأشكال والرسومات هي عملية غاية في الأهمية فيتم اختيار خطوط
                    مميزة غير مستخدمة سابقا مع رسم رسومات وأشكال رائعة تتماشى مع باقي العناصر الأساسية لهويتك التجارية.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={identity} alt="phone" />
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={design} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة تصميم شعار لمشروعك</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يعمل فريق المصممين المحترفين على عقد جلسات مطولة لابتكار أفكار إبداعية لشعار مشروعك التجاري، ويجب ان تكون فكرة
                    الشعار مرتبطة بمشروعك وبظروف العمل الخاصة بك وأن يصل الشعار الخاص بك إلى عقل عميلك المحتمل بسهولة وهو ما يعمل
                    فريق عز للتحول الرقمي على تحقيقه.
                  </p>
                  <p dir="RTL" className="mt-5">
                    بعد ذلك يقوم فريق العمل بتنفيذ أفضل الأفكار المقترحة لتصميم شعار مميزة يساهم في زيادة ثقة عملائك بمشروعك.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>مرحلة تصميم الأشكال والرسوم والعناصر المتحركة</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يبدأ فريق العمل لدينا في رسم الأشكال والعناصر المستخدمة في كافة التصميمات الخاصة بهويتك التجارية من لافتات
                    إعلانية ودعائية وورقيات ومطبوعات وغيرها، كما يقوم فريق العمل لدينا بتصميم الأشكال والرسوم المتحركة الخاصة
                    بعناصر هويتك التجارية في حال استخدامك لها في تصميم فيديو موشن جرافيك او تصميم فيديو دعائي لمشروعك.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={animate} alt="phone" />
                </div>
              </div>
              <h2 dir="RTL" className="title-service text-center">
                <strong>الأسئلة الشائعة عن خدمة تصميم هوية تجارية لمشروعك</strong>
              </h2>
              <h5 className="mt-4 text-right">
                <strong>سؤال: هل يساعد تصميم الهوية التجارية مشروعي الصغير؟</strong>
              </h5>
              <h5 className="mt-4 text-right">
                جواب: نعم، بالطبع يساعد تصميم هوية تجارية مشروعك الناشئ حيث يزيد من ثقة ومصداقية مشروعك لدى عملائك المحتملين كما
                يساعدك على تحقيق أهدافك التسويقية وزيادة الوعي عن مشروعك.
              </h5>
              <h5 className="mt-4 text-right">
                <strong>سؤال: هل يتم يمكن إعادة تصميم شعار قديم لمشروعي؟</strong>
              </h5>
              <h5 className="mt-4 text-right">
                جواب: نعم، يمكننا بالطبع إعادة تصميم وتحسين شعار شركتك القديم مع تصميم هوية تجارية متكاملة تساعدك على زيادة الوعي
                حول مشروعك.
              </h5>
              <h5 className="mt-4 text-right">
                <strong>سؤال: هل يمكنك تصميم الشعار الخاص بي باللغة العربية والإنجليزية؟</strong>
              </h5>
              <h5 className="mt-4 text-right">جواب: نعم، يمكننا تصميم شعارك الخاص بمختلف اللغات التي تريدها.</h5>
              <hr className="m-5" />
            </div>
          ) : (
            <div>
              <div className="container">
                <div className="row align-items-center my-5">
                  <div className="col-lg-5">
                    <img className="img-fluid" src={business} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-left mt-5">
                    <h4>
                      It has undoubtedly become an indispensable thing and makes you have a special and unique imprint of your
                      company or organization of the most important things that can contribute to Your success has always made you
                      first.
                    </h4>
                    <h4 className="mt-3">
                      Among the millions of brands in existence, you are bound to have a unique personality that sets you apart
                      from others. This character is called Brand Identity design.
                    </h4>
                    <h4 className="mt-3">
                      You need the identity of your company and your organization to be distinguished among your competitors
                      because it has an influence on the decisions of your customers and whatever they are {""}
                      <a
                        href="https://ar.wikipedia.org/wiki/%D9%87%D9%88%D9%8A%D8%A9_%D8%A7%D9%84%D8%B4%D8%B1%D9%83%D8%A9"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {""}
                        Your business identity {""}
                      </a>
                      It expresses your business and provides a good user experience the more it enhances your brand awareness.
                    </h4>
                    <h4 className="mt-3">
                      Therefore, at Ezz Digital Transformation, we have professional graphic designers at the highest level of
                      experience to help you design a business identity Professionalism at the best prices that reflects your
                      business and its goals.
                    </h4>
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div className="text-center">
                <h2 className="title-service">
                  <strong> What is a business identity? </strong>
                </h2>
                <h4 className="mt-3">
                  A business identity is the visual interface to your project that connects a certain idea within the mind of your
                  potential customers, the business identity is Your logo or logo
                  <a
                    href="https://alyomhost.com/%d8%ae%d8%af%d9%85%d8%a7%d8%aa%d9%86%d8%a7/%d8%aa%d8%b5%d9) % 85% d9% 8a% d9% 85-% d9% 85% d9% 88% d8% a7% d9% 82% d8% b9-% d8% a7% d9% 84% d9% 83% d8% aa% d8 % b1% d9% 88% d9% 86% d9% 8a% d8% a9 / "
                    target="_ blank"
                    rel="noreferrer"
                  >
                    {""}
                    And your site design {""}
                  </a>
                  And your application and design of advertising banners, whether they are electronic or ordinary advertising
                  banners in the streets and places the public.
                </h4>
                <h4 className="mt-3">
                  The design of the commercial identity is based on specialized studies. The process of choosing colors does not
                  come randomly, but rather through selection A specific color coordination positively affects the mind of your
                  target customer.
                </h4>

                <h2 className="title-service">
                  <strong> The importance of designing a business identity for your project </strong>
                </h2>
                <h4 className="mt-3">
                  You may ask why I should design a business identity for my own project and what is the importance of designing a
                  business identity in the first place, if answer Such a question is multiple, but briefly, the business identity
                  helps your own enterprise to grow and spread better as well as contribute Distinguished commercial identity in
                  improving the image of your company or project in the mind of your customers and making them remember you, just
                  as we all remember the logos of some Distinguished international brands such as Adidas, Nike, Starbucks and
                  other brands.
                </h4>

                <h2 className="title-service">
                  <strong> Business Identity Design Features for Your Project </strong>
                </h2>
                <ul className="steps">
                  <li>
                    <div className="card">
                      <h2 className="number"> 1 </h2>
                      <div className="card-body">
                        <h5 className="card-title">
                          {" "}
                          Make your potential clients remember your company and distinguish it among others{" "}
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 2 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Business identity increases your customers' loyalty to your company </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 3 </h2>
                      <div className="card-body">
                        <h5 className="card-title">
                          {" "}
                          Designing a distinctive brand identity increases your trust and credibilityYour weight{" "}
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 4 </h2>
                      <div className="card-body">
                        <h5 className="card-title">
                          {" "}
                          Designing a distinctive brand identity that attracts more customers' attention to your company{" "}
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 5 </h2>
                      <div className="card-body">
                        <h5 className="card-title">
                          {" "}
                          Design a great business identity that helps you achieve your marketing goals{" "}
                        </h5>
                      </div>
                    </div>
                  </li>
                </ul>

                <h2 className="title-service">
                  <strong> Business Identity Components </strong>
                </h2>
                <h3 className="mb-4">A business identity has many components, as follows:</h3>
                <ul className="steps">
                  <li>
                    <div className="card">
                      <h2 className="number"> 1 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Logo or Logo </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 2 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Color format </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 3 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Design your own video </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 4 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Motion Graphics </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 5 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Design your own products </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 6 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Your website design </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 7 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Mobile app design for your project </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 8 </h2>
                      <div className="card-body">
                        <h5 className="card-title"> Signage designs for your store or banner ads </h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-center">
                <h2 className="title-service">
                  <strong>
                    {" "}
                    Steps to design a commercial identity for your project from Ezz Foundation for Digital Transformation{" "}
                  </strong>
                </h2>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={analysis} alt="phone" />
                </div>
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> The stage of studying your project and analyzing the competitors </strong>
                  </h3>
                  <p className="mt-5">
                    Our design experts study the field of your project and make a detailed analysis for all competitors to find
                    the best way To design your company's business identity, our brand identity designers consider choosing
                    distinctive colors for your business identity The colors help your potential customers remember your business
                    identity.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> The stage of selecting the basic elements of the business identity design </strong>
                  </h3>
                  <p className="mt-5">
                    The Ezz Foundation team at this stage determines the basic elements for designing your brand identity, and
                    these elements are colors Used, fonts used in the design, color consistency, shapes and graphics used in
                    designing your brand identity.
                  </p>
                  <p className="mt-5">
                    Where design colors are used with great care based on modern scientific methods, as each of the primary colors
                    affects Somehow on the psyche and mind of your potential customer, these are carefully chosen colors.
                  </p>
                  <p className="mt-5">
                    As colors are very important, the process of choosing lines, shapes and graphics is a very important process
                    in choosing fonts Distinctive previously unused with drawing wonderful graphics and shapes in line with the
                    rest of the basic elements of your brand identity.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={identity} alt="phone" />
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={design} alt="phone" />
                </div>
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> Logo design phase for your project </strong>
                  </h3>
                  <p className="mt-5">
                    The team of professional designers works to hold lengthy sessions to create creative ideas for your business
                    logo, and it must be an idea The logo relates to your project, your business conditions, and your logo easily
                    gets into the mind of your potential customer and that's what works Ezz's digital transformation team achieves
                    it.
                  </p>
                  <p className="mt-5">
                    After that, the work team implements the best proposed ideas for designing a distinctive logo that contributes
                    to increasing the confidence of your customers in your project.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left mt-5">
                  <h3>
                    <strong> Design phase of shapes, graphics and animations </strong>
                  </h3>
                  <p className="mt-5">
                    Our team begins drawing the shapes and elements used in all your branding designs from banners Advertising,
                    promotional, papers, prints and more. Our team also designs special shapes and animations With the elements of
                    your brand identity if you use them in designing a motion graphic video or designing a promotional video for
                    your project.
                  </p>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={animate} alt="phone" />
                </div>
              </div>
              <h2 className="title-service text-center">
                <strong> Frequently Asked Questions about a Business Identity Design Service for Your Project </strong>
              </h2>
              <h5 className="mt-4 text-left">
                <strong> Question: Does business identity design help my small business? </strong>
              </h5>
              <h5 className="mt-4 text-left">
                Answer: Yes, of course designing a business identity helps your startup as it increases the confidence and
                credibility of your project with your potential clients as well. It helps you achieve your marketing goals and
                increase awareness of your project.
              </h5>
              <h5 className="mt-4 text-left">
                <strong> Question: Can an old logo for my project be re-designed? </strong>
              </h5>
              <h5 className="mt-4 text-left">
                Answer: Yes, of course we can redesign and improve your old company logo with an integrated brand identity design
                that helps you increase awareness About your project.
              </h5>
              <h5 className="mt-4 text-left">
                <strong> Question: Can you design my logo in Arabic and English? </strong>
              </h5>
              <h5 className="mt-4 text-left"> Answer: Yes, we can design your own logo in various languages ​​that you want. </h5>
              <hr className="m-5" />
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="zoom-in">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BusinessDesignService;
