import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import phone from "../Assets/Images/phone.svg";
import plan from "../Assets/Images/plan.svg";
import wireframe from "../Assets/Images/wireframe.svg";
import review from "../Assets/Images/review.svg";
import programming from "../Assets/Images/programming.svg";
import mobileDesign from "../Assets/Images/mobile_design.svg";
import deploy from "../Assets/Images/deploy.svg";
import test from "../Assets/Images/test.svg";
import android from "../Assets/Images/androids.svg";
import ios from "../Assets/Images/ios.svg";
import { Link } from "react-router-dom";

const AppDesignService = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `عز للتحول الرقمي - ${t("create-mobile")}`;
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 text-center" data-aos="zoom-in-down">
              <h1 className="p-5 text-warning font-weight-bold">{t("create-mobile")}</h1>
            </div>
          </div>
          {t("language") === "en" ? (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={phone} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5">
                  <h3 dir="RTL">
                    <strong>
                      فريق عمل منصة عز للتحول الرقمي الذي يمتلك خبرة كبيرة في مجال تصميم تطبيقات الجوال لكل من نظامي التشغيل
                      الأندرويد أو الـ IOS قادر على مساعدتك على تحويل رؤيتك الى واقع ملموس يكون بداية لنجاح مبهر.
                    </strong>
                  </h3>
                </div>
              </div>
              <div className="text-center">
                <h2 dir="RTL" className="title-service">
                  <strong>مراحل برمجة و تصميم تطبيقات الجوال من شركة عز للتحول الرقمي</strong>
                </h2>
                <h2>
                  <strong>تصميم تطبيقات الجوال</strong>
                </h2>
                <h5 dir="RTL" className="mt-5">
                  إن مشروع إنشاء تطبيق جوال احترافي ومميز لشركتك او مؤسستك في مؤسسة عز للتحول الرقمي يتم بأقصى درجات الاحترافية
                  الممكنة.
                </h5>
                <h5 dir="RTL">
                  حيث يقوم فريق عمل عز بوضع أساسيات واضحة وتخطيط مُحكم لطريقة تصميم و برمجة تطبيق الجوال الخاص بك وهي:
                </h5>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={plan} alt="phone" />
                </div>
                <div className="col-lg-7 text-right">
                  <h3 dir="RTL">
                    <strong>مرحلة التخطيط والتحليل لتصميم تطبيقات الجوال</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    تعتبر هذه المرحلة من اهم خطوات تصميم تطبيق للجوال ناجح ومميز، حيث يقوم المتخصصون لدينا بدراسة مجال العمل الخاص
                    بالتطبيق والبدء في وضع الأساسيات اللازمة لتنفيذ التصميم ودراسة توقعات العميل المستخدم لهذا التطبيق مع مراعاة
                    تقديم تصميم يحاكي أفضل تجربة استخدام ممكنة لعملائك.
                  </p>
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-right">
                  <h3 dir="RTL">
                    <strong>مرحلة وضع الشكل المبدئي لتصميم تطبيق الجوال وعمل الـ Wireframe</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يقوم فريق العمل في هذه المرحلة بوضع او تصور مرئي للتطبيق الخاص بمؤسستك، حيث تعتبر خطوة إنشاءwireframe للتطبيق
                    الخاص بك خطوة هامة ولا غني عنها، وفي هذه المرحلة تنتقل كل الأفكار من الورق وعقول المصممين المحترفين لدينا إلى
                    تصميم مرئي وواضح.
                  </p>
                </div>
                <div className="col-lg-5 p-5 order-first order-lg-last">
                  <img className="img-fluid" src={wireframe} alt="phone" />
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={review} alt="phone" />
                </div>
                <div className="col-lg-7 text-right">
                  <h3 dir="RTL">
                    <strong>مرحلة مراجعة العميل لتصميم تطبيق الجوال</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    على الرغم من إمتلاكنا لفريق من المتخصصين والخبراء في مجال تصميم تطبيقات الجوال إلى أن رأي العميل هو أهم شيء
                    بالنسبة لنا، فالعميل المتمثل في مالك التطبيق في هذه المرحلة يتم تزويده بتصميم مبدأي لتطبيق الجوال ويقوم العميل
                    بمراجعته بدقة.
                  </p>
                  <p dir="RTL" className="mt-3">
                    ويحرص فريق عز للتحول الرقمي على مراجعة وتنفيذ كافة اقتراحات وتعديلات العملاء بما يتناسب مع التصميم، حيث أن في
                    عز للتحول الرقمي رأي العميل يعني لنا الكثير وما يهمنا دائما وأبدًا هو إرضاء العملاء.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-right">
                  <h3 dir="RTL">
                    <strong> مرحلة تصميم واجهة الاستخدام وتصميم تجربة الاستخدام</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يقوم المتخصصون لدينا بعمل التصميم النهائي لتطبيق الجوال الخاص بمؤسستك، ويعمل فريق المصممين لدينا على مراعاة
                    تناسق الألوان وتماشيها مع الوان الشعار والهوية الخاصة بمؤسستك.
                  </p>
                  <p dir="RTL" className="mt-3">
                    زيادة على ذلك يهتم فريقنا بتصميم عناصر تطبيق بسيطة وجميلة وسهلة في نفس الوقت مع وجود الوان هادئة مريحة للعين
                    وتؤثر إيجابا على المستخدمين.
                  </p>
                  <p dir="RTL" className="mt-3">
                    كذلك يقوم فريق العمل في هذه المرحلة بعمل العديد من الاختبارات على الوان التصميم وسهولة استخدامه وحجم العناصر
                    والرسومات المستخدمة.
                  </p>
                  <p dir="RTL" className="mt-3">
                    كما يقوم مصممي تجربة الإستخدام لدينا بمحاكاة كافة العوائق التي يمكنها ان تمنع أي مستهلك عن التفاعل مع التطبيق،
                    على وجه الخصوص يعمل المتخصصون على جعل التطبيق الخاص بك سهل الإستخدام من كل كل الأشخاص فكلما كان التطبيق بسيط
                    ومتناسق كان أسهل في إستخدامه.
                  </p>
                </div>
                <div className="col-lg-5 p-5 order-first order-lg-last">
                  <img className="img-fluid" src={mobileDesign} alt="phone" />
                </div>
              </div>

              <div className="text-center mt-5">
                <h2>
                  <strong>برمجة تطبيقات الجوال</strong>
                </h2>
                <h5 dir="RTL" className="mt-5">
                  تعتبر مرحلة برمجة تطبيق الجوال الخاص بك من أهم المراحل حيث يتم تحويل التصميم الذي تم إنشاؤه من قبل المصممين إلى
                  نموذج حي يعمل بالفعل، ويهتم المطورين ومهندسي البرمجيات لدينا في هذه المرحلة في تحويل التصميم المرئي إلى تطبيق
                  يعمل بالفعل بدون أي مشكلات عبر الخطوات التالية:
                </h5>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={programming} alt="phone" />
                </div>
                <div className="col-lg-7 text-right">
                  <h3 dir="RTL">
                    <strong>مرحلة برمجة تطبيق الجوال</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    يبدأ المطورين المحترفين لدينا بكتابة الشفرات والأكواد البرمجية الخاصة بالتطبيق لتحويلة من تصميم لا يمكن
                    التفاعل معه إلى تطبيق حي وفعال يعمل 100% ويقوم المطورين بملء التطبيق الخاص بك بالمحتوى لمعاينة كافة الجوانب
                    الخاصة بأداء التطبيق وحل كافة المشكلات.
                  </p>
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-right">
                  <h3 dir="RTL">
                    <strong>مرحلة إختبار التطبيق</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    تكمن أهمية هذه المرحلة في إكتشاف كافة الأخطاء والمشكلات التي قد تحدث في التطبيق مستقبلا، ويعمل متخصصونا على
                    تجربة كل جزء مهما كان صغيرا في التطبيق وإكتشاف الأخطاء والعناصر التي توجد بها مشاكل وإصلاحها لكي تحصل تطبيق
                    للجوال خالي من الأخطاء وسريع ومميز.
                  </p>
                </div>
                <div className="col-lg-5 p-5 order-first order-lg-last">
                  <img className="img-fluid" src={test} alt="phone" />
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={deploy} alt="phone" />
                </div>
                <div className="col-lg-7 text-right">
                  <h3 dir="RTL">
                    <strong>مرحلة تسليم التطبيق للعميل</strong>
                  </h3>
                  <p dir="RTL" className="mt-5">
                    خطوات هامة وواضحة وصلت بنا إلى هذه المرحلة وهي تسليم التطبيق يعمل بدون أي أخطاء للعميل فبعد رحلة هامة مع تصميم
                    تطبيق الجوال الخاص بك ثم مرورا بمرحلة برمجة تطبيقات الجوال ومراجعة أخطائها وإختبارها الان بعد كل هذه الخطوات
                    يتم تسليم التطبيق لك بدون اي مشكلات.
                  </p>
                  <p dir="RTL" className="mt-3">
                    كما تقدم لك مؤسسة عز فترة دعم فني تختلف حسب باقة التطبيق وفي هذه الفترة بتقديم الدعم اللازم حال وجود اي مشكلات
                    في التطبيق الخاص بك.
                  </p>
                </div>
              </div>

              <div className="text-center mt-5">
                <h4 dir="RTL" className="mt-5">
                  كانت هذه أهم الخطوات والمراحل لتصميم وبرمجة تطبيق جوال إحترافي من مؤسسة عز للتحول الرقمي.
                </h4>
              </div>

              <div className="text-center">
                <h2 dir="RTL" className="title-service">
                  <strong>تصميم تطبيقات أندوريد</strong>
                </h2>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={android} alt="phone" />
                </div>
                <div className="col-lg-7 text-right">
                  <h5 dir="RTL" className="mt-5">
                    نظام الأندرويد هو اسرع أنظمة التشغيل انتشارا في العالم واكثرها انتشارا ايضا في الوقت الراهن حيث يعمل به أكثر
                    من 2.5 مليار جهاز على مستوى العالم، بل وتعمل أكثر من 70% من إجمالي أجهزة الجوال في العالم بنظام الأندرويد ما
                    يؤكد أهمية توفر تطبيق أندرويد تعمل على تسويق عملك من خلاله.
                  </h5>
                  <h5 dir="RTL" className="mt-3">
                    التحديثات والمميزات التي دائما ما يقدمها القائمون على نظام التشغيل الأندرويد تجعل من عملية تصميم تطبيق أندرويد
                    للجوال خيارا أكثر مرونة لكونها تتلائم بشكل اكبر مع احتياجات المستهلك.
                  </h5>
                  <h5 dir="RTL" className="mt-3">
                    مؤسسة عز للتحول الرقمي لما لها من باع طويل في مجال تطوير وتصميم تطبيقات الأندرويد للجوال بالمملكة العربية
                    السعودية ستتمكن من تصميم تطبيق اندرويد ملائم لمتطلبات واحتياجات عملك لإنتشار وتوسع أكبر.
                  </h5>
                  <h5 dir="RTL" className="mt-3">
                    حيث نمتلك فريق عمل من مطوري تطبيقات الأندرويد ذوي خبرة كبيرة قادرون على تحليل متطلبات عملك للوصول الى أفضل
                    الحلول والخروج بتطبيق أندرويد يكون سببا في مزيد من النجاح.
                  </h5>
                </div>
              </div>

              <div className="text-center">
                <h2 dir="RTL" className="title-service">
                  <strong>تصميم تطبيقات للايفون</strong>
                </h2>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={ios} alt="phone" />
                </div>
                <div className="col-lg-7 text-right">
                  <h5 dir="RTL" className="mt-5">
                    قادرون على تصميم وتطوير تطبيق آيفون يتناسب مع اهدافكم سواء كانت تجارة إلكترونية بشكل بحت أو ليكون من ضمن وسائل
                    الترفيه كالألعاب أو حتى تطبيق للتواصل الإجتماعي أو التسوق أو التنقل وغيرها الكثير.
                  </h5>
                  <h5 dir="RTL" className="mt-3">
                    نظرا للتزايد المستمر لمستخدمي جوالات وأجهزة آيفون التي تعمل بنظام التشغيل IOS يمكن للشركات والافراد استغلال
                    هذا النمو ليكون وسيلة لها من الفاعلية بمكان للوصول الى عملائهم.
                  </h5>
                  <h5 dir="RTL" className="mt-3">
                    نعمل على تصميم وتطوير وتسويق تطبيقات الـ IOS لمختلف أجهزة شركة آبل، وسيسعى فريق عملنا الدؤوب على توفير حلول
                    فعالة تتناسب مع احتياجات عملكم.
                  </h5>
                  <h5 dir="RTL" className="mt-3">
                    حيث نمتلك فريق عمل من مطوري تطبيقات الأندرويد ذوي خبرة كبيرة قادرون على تحليل متطلبات عملك للوصول الى أفضل
                    الحلول والخروج بتطبيق أندرويد يكون سببا في مزيد من النجاح.
                  </h5>
                </div>
              </div>

              <div className="text-center">
                <h2 dir="RTL" className="title-service mb-5">
                  <strong>وايضا من ضمن خدمتنا ايضا</strong>
                </h2>
                <h5>
                  <Link to="/services/webDesignService" dir="RTL" className="mt-2">
                    تصميم موقع الكتروني
                  </Link>
                </h5>
                <h5>
                  <Link to="/services/ecommerceService" dir="RTL" className="mt-2">
                    المتاجر الإلكترونية
                  </Link>
                </h5>
                <h5>
                  <Link to="/services/consultService" dir="RTL" className="mt-2">
                    الاستشارات
                  </Link>
                </h5>
              </div>

              <div className="text-center">
                <h2 dir="RTL" className="title-service">
                  <strong>الأسئلة الشائعة بخصوص تصميم تطبيق الجوال:</strong>
                </h2>
                <h5 className="mt-4 text-right">
                  <strong>سؤال: كيف يتم تصميم تطبيق الجوال الخاص بي؟</strong>
                </h5>
                <h5 className="mt-4 text-right">
                  جواب: يعمل المتخصصين لدينا على تصميم وبرمجة تطبيق الجوال الخاص بشركتك أو مؤسستك عبر إستخدام أحدث التقنيات
                  العالمية، لتحصل بالنهاية على تطبيق سريع ذو تصميم جذاب والوان متناسقة يثير إعجاب وحماسة عملائك المحتملين.
                </h5>
                <h5 className="mt-4 text-right">
                  <strong>سؤال: ماهي تكلفة تصميم تطبيق للجوال؟</strong>
                </h5>
                <h5 className="mt-4 text-right">
                  جواب: تختلف تكلفة تصميم تطبيق للجوال من شركة إلى أخري ويختلف السعر حسب الباقة التي تقوم بتحديدها والمميزات التي
                  تود إضافتها إلى التطبيق الخاص بك، ولكن تقدم لك مؤسسة عز للتحول الرقمي خدمة تصميم وبرمجة تطبيقات الجوال بأسعار
                  مميزة ومناسبة للجميع.
                </h5>
              </div>
              <hr className="m-5" />
            </div>
          ) : (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={phone} alt="phone" />
                </div>
                <div className="col-lg-7 text-left mt-5">
                  <h3 dir="ltr">
                    <strong>
                      The Ezz platform team for digital transformation who has extensive experience in designing mobile
                      applications for both operating systems Android or IOS is able to help you turn your vision into a tangible
                      reality that will be the beginning of an impressive success.
                    </strong>
                  </h3>
                </div>
              </div>
              <div className="text-center">
                <h2 dir="ltr" className="title-service">
                  <strong>
                    stages of programming and designing mobile applications from Ezz Company for Digital Transformation
                  </strong>
                </h2>
                <h2>
                  <strong> Mobile App Design </strong>
                </h2>
                <h5 dir="ltr" className="mt-5">
                  The project to create a professional and distinctive mobile application for your company or organization in the
                  Ezz Foundation for Digital Transformation is done with the utmost professionalism Possible.
                </h5>
                <h5 dir="ltr">
                  Where the Ezz team develops clear basics and a solid planning of how to design and program your mobile
                  application, which are:
                </h5>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={plan} alt="phone" />
                </div>
                <div className="col-lg-7 text-left">
                  <h3 dir="ltr">
                    <strong> The planning and analysis phase of mobile app design </strong>
                  </h3>
                  <p dir="ltr" className="mt-5">
                    This stage is considered one of the most important steps in designing a successful and distinctive mobile
                    application, as our specialists study your field of work Application and start laying down the necessary
                    basics for the implementation of the design and studying the expectations of the client user for this
                    application, taking into account Delivering a design that simulates the best possible user experience for your
                    customers.
                  </p>
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left">
                  <h3 dir="ltr">
                    <strong> Stage of initializing the design of the mobile application and making the Wireframe </strong>
                  </h3>
                  <p dir="ltr" className="mt-5">
                    The work team at this stage develops or visualizes the application of your organization, as it is considered a
                    step to create a wireframe for the application Your is an important and indispensable step, and at this stage
                    all ideas are transmitted from paper and the minds of our professional designers to Visible and clear design.
                  </p>
                </div>
                <div className="col-lg-5 p-5 order-first order-lg-last">
                  <img className="img-fluid" src={wireframe} alt="phone" />
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={review} alt="phone" />
                </div>
                <div className="col-lg-7 text-left">
                  <h3 dir="ltr">
                    <strong> Customer review phase of mobile app design </strong>
                  </h3>
                  <p dir="ltr" className="mt-5">
                    Although we have a team of specialists and experts in the field of mobile application design, the customer’s
                    opinion is the most important thing For us, the customer who is the owner of the application at this stage is
                    provided with a prototype design of the mobile application and the customer is done Review it carefully.
                  </p>
                  <p dir="ltr" className="mt-3">
                    The Ezz Digital Transformation Team is keen to review and implement all customer suggestions and modifications
                    commensurate with the design Ezz for digital transformation, customer opinion means a lot to us, and what we
                    care about always and forever is customer satisfaction.
                  </p>
                </div>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left">
                  <h3 dir="ltr">
                    <strong> The UI and UX design phase </strong>
                  </h3>
                  <p dir="ltr" className="mt-5">
                    Our professionals do the final design for your organization's mobile app, and our team of designers work to
                    keep you in mind The consistency of colors and their consistency with the colors of the logo and the identity
                    of your organization.
                  </p>
                  <p dir="ltr" className="mt-3">
                    Moreover, our team cares about designing simple, beautiful and easy application elements with calm colors that
                    are comfortable on the eyes And positively affect users.
                  </p>
                  <p dir="ltr" className="mt-3">
                    The work team at this stage also conducts many tests on the design colors, ease of use and size of elements
                    And graphics used.
                  </p>
                  <p dir="ltr" className="mt-3">
                    Our user experience designers simulate all the obstacles that can prevent any consumer from interacting with
                    the app. In particular, the specialists work to make your application easy to use by all people, the more
                    simple the application is Harmonic was easier to use.
                  </p>
                </div>
                <div className="col-lg-5 p-5 order-first order-lg-last">
                  <img className="img-fluid" src={mobileDesign} alt="phone" />
                </div>
              </div>

              <div className="text-center mt-5">
                <h2>
                  <strong> Mobile Application Programming </strong>
                </h2>
                <h5 dir="ltr" className="mt-5">
                  One of the most important stages is the programming phase of your mobile application as the design created by
                  the designers is converted into A live model is already operational, and our developers and software engineers
                  are at this point interested in converting a visual design into an application It actually works without any
                  problems via the following steps:
                </h5>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={programming} alt="phone" />
                </div>
                <div className="col-lg-7 text-left">
                  <h3 dir="ltr">
                    <strong> Mobile Application Programming Phase </strong>
                  </h3>
                  <p dir="ltr" className="mt-5">
                    Our professional developers start writing application code and code for a transformation of a design that
                    cannot Interact with it into a live and efficient app that works 100% and developers populate your app with
                    content to preview all aspects For application performance and solving all problems.
                  </p>
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left">
                  <h3 dir="ltr">
                    <strong> Application testing phase </strong>
                  </h3>
                  <p dir="ltr" className="mt-5">
                    The importance of this stage lies in discovering all errors and problems that may occur in the application in
                    the future, and our specialists are working on Experimenting every part, no matter how small, in the
                    application, and discovering errors and problematic elements, in order to get an application For mobile,
                    error-free, fast and distinctive.
                  </p>
                </div>
                <div className="col-lg-5 p-5 order-first order-lg-last">
                  <img className="img-fluid" src={test} alt="phone" />
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={deploy} alt="phone" />
                </div>
                <div className="col-lg-7 text-left">
                  <h3 dir="ltr">
                    <strong> Application Handover to Customer </strong>
                  </h3>
                  <p dir="ltr" className="mt-5">
                    Important and clear steps that got us to this stage, which is the delivery of the application works without
                    any errors to the customer, after an important journey with design Your mobile application, then through the
                    mobile application programming phase, reviewing its mistakes and testing them now after all these steps The
                    application is delivered to you without any problems.
                  </p>
                  <p dir="ltr" className="mt-3">
                    Ezz Foundation also offers you a technical support period that varies according to the application package and
                    in this period by providing the necessary support in the event of any problems In your application.
                  </p>
                </div>
              </div>

              <div className="text-center mt-5">
                <h4 dir="ltr" className="mt-5">
                  These were the most important steps and stages for designing and programming a professional mobile app from Ezz
                  Foundation for Digital Transformation.
                </h4>
              </div>

              <div className="text-center">
                <h2 dir="ltr" className="title-service">
                  <strong> Design Android Applications </strong>
                </h2>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={android} alt="phone" />
                </div>
                <div className="col-lg-7 text-left">
                  <h5 dir="ltr" className="mt-5">
                    The Android system is the fastest growing operating system in the world and the most widespread also at the
                    present time, where it works more Of the 2.5 billion devices in the world, but more than 70% of the total
                    mobile devices in the world are now operating systemDruid what Emphasizes the importance of having an Android
                    application that you are working on marketing your business through.
                  </h5>
                  <h5 dir="ltr" className="mt-3">
                    The updates and features that are always provided by the administrators of the Android operating system make
                    the process of designing an Android application Mobile has a more flexible option because it is more
                    compatible with the needs of the consumer.
                  </h5>
                  <h5 dir="ltr" className="mt-3">
                    Ezz Foundation for Digital Transformation because of its long history in the field of developing and designing
                    Android mobile applications in the Kingdom of Saudi Arabia Saudi Arabia will be able to design an Android
                    application appropriate to the requirements and needs of your business for greater spread and expansion.
                  </h5>
                  <h5 dir="ltr" className="mt-3">
                    Where we have a team of highly experienced Android application developers who are able to analyze your
                    business requirements to get better Solutions and the exit of the Android application will be a reason for
                    more success.
                  </h5>
                </div>
              </div>

              <div className="text-center">
                <h2 dir="ltr" className="title-service">
                  <strong> Design apps for iPhone </strong>
                </h2>
              </div>

              <div className="row align-items-center my-5">
                <div className="col-lg-5 p-5">
                  <img className="img-fluid" src={ios} alt="phone" />
                </div>
                <div className="col-lg-7 text-left">
                  <h5 dir="ltr" className="mt-5">
                    We are able to design and develop an iPhone application that suits your goals, whether it is purely e-commerce
                    or to be among the means Entertainment such as games or even an app for socializing, shopping, transportation,
                    and many more.
                  </h5>
                  <h5 dir="ltr" className="mt-3">
                    Due to the continuous increase of users of mobiles and iPhones that run on the operating system of IOS,
                    companies and individuals can take advantage of This growth is to be an effective way to reach their
                    customers.
                  </h5>
                  <h5 dir="ltr" className="mt-3">
                    We are working on designing, developing and marketing IOS applications for various Apple devices, and our
                    tireless work team will strive to provide solutions Effectively tailored to your business needs.
                  </h5>
                  <h5 dir="ltr" className="mt-3">
                    Where we have a team of highly experienced Android application developers who are able to analyze your
                    business requirements to get better Solutions and the exit of the Android application will be a reason for
                    more success.
                  </h5>
                </div>
              </div>

              <div className="text-center">
                <h2 dir="ltr" className="title-service mb-5">
                  <strong> It is also within our service </strong>
                </h2>
                <h5>
                  <Link to="/services/webDesignService" dir="ltr" className="mt-2">
                    Website design
                  </Link>
                </h5>
                <h5>
                  <Link to="/services/ecommerceService" dir="ltr" className="mt-2">
                    Electronic stores
                  </Link>
                </h5>
                <h5>
                  <Link to="/services/consultService" dir="ltr" className="mt-2">
                    Consulting
                  </Link>
                </h5>
              </div>

              <div className="text-center">
                <h2 dir="ltr" className="title-service">
                  <strong> Mobile app design frequently asked questions: </strong>
                </h2>
                <h5 className="mt-4 text-left">
                  <strong> Question: How is my mobile app designed? </strong>
                </h5>
                <h5 className="mt-4 text-left">
                  Answer: Our specialists work on designing and programming the mobile application for your company or
                  organization using the latest technology Worldwide, to get in the end a quick application with an attractive
                  design and consistent colors that impress and excite your potential customers.
                </h5>
                <h5 className="mt-4 text-left">
                  <strong> Question: How much does a mobile app cost? </strong>
                </h5>
                <h5 className="mt-4 text-left">
                  Answer: The cost of designing a mobile app varies from one company to another, and the price varies depending on
                  the package that you select and the features that you specify. You would like to add it to your application, but
                  the Ezz Foundation for Digital Transformation provides you with a service for designing and programming mobile
                  applications at prices Distinctive and suitable for everyone.
                </h5>
              </div>
              <hr className="m-5" />
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="zoom-in">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AppDesignService;
