import React, { useEffect } from "react";
import "./App.css";
import NavBar from "./components/navbar";
import Footer from "./components/footer";
import LandingPage from "./Pages/LandingPage";
import About from "./Pages/About";
import Projects from "./Pages/Projects";
import Services from "./Pages/Services";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import BlogsList from "./Pages/BlogsList";
import Login from "./Pages/Login";
import AdminMain from "./Pages/Admin/AdminMain";
import EmployeeMain from "./Pages/Employee/EmployeeMain";
import Recruitment from "./Pages/Recruitment";
import ResetPassword from "./Pages/ResetPassword";
import AppDesignService from "./Pages/AppDesignService";
import WebDesignService from "./Pages/WebDesignService";
import PlatformDesignService from "./Pages/PlatformDesignService";
import MaintainWebAppService from "./Pages/MaintainWebAppService";
import ProjectStudyService from "./Pages/ProjectStudyService";
import BusinessDesignService from "./Pages/BusinessDesignService";
import ConsultService from "./Pages/ConsultService";
import EcommerceService from "./Pages/EcommerceService";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Switch>
          <Route exact path="/" component={LandingPage}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/projects" component={Projects}></Route>
          <Route exact path="/services" component={Services}></Route>
          <Route exact path="/services/appDesignService" component={AppDesignService}></Route>
          <Route exact path="/services/webDesignService" component={WebDesignService}></Route>
          <Route exact path="/services/platformDesignService" component={PlatformDesignService}></Route>
          <Route exact path="/services/maintainWebAppService" component={MaintainWebAppService}></Route>
          <Route exact path="/services/businessDesignService" component={BusinessDesignService}></Route>
          <Route exact path="/services/projectStudyService" component={ProjectStudyService}></Route>
          <Route exact path="/services/consultService" component={ConsultService}></Route>
          <Route exact path="/services/ecommerceService" component={EcommerceService}></Route>
          <Route exact path="/policy" component={PrivacyPolicy}></Route>
          <Route exact path="/blogs" component={() => <BlogsList type={2} />}></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/recruitment" component={Recruitment}></Route>
          <Route exact path="/adminMain" component={AdminMain}></Route>
          <Route exact path="/employeeMain" component={EmployeeMain}></Route>
          <Route exact path="/reset" component={ResetPassword}></Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
