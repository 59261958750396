import React, { useState, useEffect } from "react";
import Fire from "../../Api/Fire";
import { useTranslation } from "react-i18next";

const EmployeeDetails = () => {
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - الملف الشخصي";
    //Get User
    Fire.getUser(Fire.uid).then((user) => setUser(user));
  }, []);

  return (
    <div className="container bg-white rounded" data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 text-center p-4">
          <img className="rounded-circle picture p-2" src={user != null ? user.image : "?????"} alt="pic" />
          <h2 className="p-2 font-weight-bold">{t("firstName")}</h2>
          <h2 className="text-primary">{user != null ? user.firstName : "?????"}</h2>
          <h2 className="p-2 font-weight-bold">{t("middleName")}</h2>
          <h2 className="text-primary">{user != null ? user.middleName : "?????"}</h2>
          <h2 className="p-2 font-weight-bold">{t("lastName")}</h2>
          <h2 className="text-primary">{user != null ? user.lastName : "?????"}</h2>
          <h2 className="p-2 font-weight-bold">{t("department")}</h2>
          <h2 className="text-primary">{user != null ? user.department : "?????"}</h2>
          <h2 className="p-2 font-weight-bold">{t("email")}</h2>
          <h2 className="text-primary">{user != null ? user.email : "?????"}</h2>
          <h2 className="p-2 font-weight-bold">{t("phone")}</h2>
          <h2 className="text-primary">{user != null ? user.phone : "?????"}</h2>
          <h2 className="p-2 font-weight-bold">{t("coupon")}</h2>
          <h2 className="text-primary">{user != null ? user.coupon : "?????"}</h2>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
