import React, { useState, useEffect } from "react";
import Fire from "../../Api/Fire";
import ErrorDialog from "../../components/errorDialog";
import SuccessDialog from "../../components/successDialog";
import SearchField from "react-search-field";
import { useTranslation } from "react-i18next";

const EmployeeList = () => {
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [employeesFullData, setEmolpyeesFullData] = useState([]);
  const [employees, setEmolpyees] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - قائمة الموظفين";

    //Get List of Employees
    (async () => {
      const users = await Fire.getAllUsers(3);
      if (typeof users === "string") {
        setError((error) => users);
        setShowForm((showForm) => true);
      } else {
        setEmolpyees(users);
        setEmolpyeesFullData(users);
      }
      setLoading((loading) => false);
    })();
  }, []);

  //Delete Employee
  const deleteEmployee = async (user) => {
    const response = await Fire.deleteUser(user);
    if (response) {
      setShowFormSuccess((showFormSuccess) => true);
    } else {
      setError((error) => response);
      setShowForm((showForm) => true);
    }
    setLoading((loading) => false);
  };

  const filter = (value) => {
    if (value.trim().length === 0) return setEmolpyees((employees) => employeesFullData);
    setEmolpyees((employees) =>
      employeesFullData.filter((employee) => {
        return Number(employee.coupon) === Number(value);
      })
    );
  };

  return (
    <div className="container bg-white rounded" data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex text-center align-items-center">
          <div className="col-lg-12 py-4 text-center">
            <h4>
              <b>{t("employees")}</b>
            </h4>
            <SearchField placeholder={t("search")} onSearchClick={filter} classNames="m-2 w-75" />
            {loading ? (
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : employees.length === 0 ? (
              <h5 className="py-2">{t("no-employee")}</h5>
            ) : (
              <ul className="list-group text-left">
                {employees.map((employee, index) => (
                  <li key={index} className="list-group-item list-group-item-action">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center flex-wrap">
                        <img className="rounded-circle employeePic mr-2" src={employee.image} alt="profilePic" />
                        <div>
                          <h5>
                            <b>{`${employee.firstName} ${employee.middleName} ${employee.lastName}`}</b>
                          </h5>
                          <h6>{`${employee.department} • ${employee.phone} • ${employee.coupon}`}</h6>
                        </div>
                      </div>
                      <button class="btn" onClick={() => deleteEmployee(employee)}>
                        <i className="fa fa-trash delete" aria-label="حذف" aria-hidden="true" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {showForm ? (
            <div className="contact-form bg-white rounded shadow">
              <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
            </div>
          ) : null}
          {showFormSuccess ? (
            <div className="contact-form bg-white rounded shadow">
              <SuccessDialog success={t("delete-success")} onClick={() => setShowFormSuccess((showForm) => false)} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;
