import React, { useState, useEffect } from "react";
import Fire from "../../Api/Fire";
import ErrorDialog from "../../components/errorDialog";
import SuccessDialog from "../../components/successDialog";
import { useTranslation } from "react-i18next";
var randomize = require("randomatic");

const AddEmployee = () => {
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    department: "Management",
    phone: "",
    image: null,
    email: "",
    password: "",
    coupon: parseInt(randomize("0", 4)),
    type: "Employee",
  });
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - إضافة موظف";
  }, []);

  //Publish Announcement to team.
  const addEmployee = async (event, user) => {
    setLoading((loading) => true);
    event.preventDefault();
    if (
      user.firstName.trim().length === 0 ||
      user.middleName.trim().length === 0 ||
      user.lastName.trim().length === 0 ||
      user.department.trim().length === 0 ||
      user.phone.trim().length === 0 ||
      user.email.trim().length === 0 ||
      user.password.trim().length === 0 ||
      user.image === null
    ) {
      setError((error) => t("fill"));
      setShowForm((showForm) => true);
      setLoading((loading) => false);
    } else {
      const response = await Fire.signUpWithEmail(user.email, user.password, user);
      if (response === true) {
        setShowFormSuccess((showFormSuccess) => true);
      } else {
        setError((error) => response);
        setShowForm((showForm) => true);
      }
      setLoading((loading) => false);
    }
  };

  return (
    <div className="container bg-white rounded" data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 p-3">
          <div>
            <h4 className="card-title text-center">{t("add-employee")}</h4>
            <form className={`${t("language") === "en" ? "text-right" : "text-left"}`}>
              <div className="form-group">
                <label htmlFor="firstName">{t("firstName")}</label>
                <input
                  type="text"
                  value={user.firstName}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setUser({ ...user, firstName: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="middleName">{t("middleName")}</label>
                <input
                  type="text"
                  value={user.middleName}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setUser({ ...user, middleName: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">{t("lastName")}</label>
                <input
                  type="text"
                  value={user.lastName}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setUser({ ...user, lastName: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t("email")}</label>
                <input
                  type="email"
                  value={user.email}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setUser({ ...user, email: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">{t("phone")}</label>
                <input
                  type="number"
                  value={user.phone}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setUser({ ...user, phone: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="department">{t("department")}</label>
                <select
                  className="col-lg-12 bg-light text-right"
                  id="department"
                  name="department"
                  onChange={(event) => setUser({ ...user, department: event.target.value })}
                  required
                >
                  <option value="Management">Management</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Designer">Designer</option>
                  <option value="Technical Consultant">Technical Consultant</option>
                </select>
              </div>
              {user.department === "Marketing" ? (
                <div className="form-group">
                  <label htmlFor="phone">{t("coupon")}</label>
                  <input disabled type="text" value={user.coupon} className="col-lg-12 bg-light text-right" required />
                </div>
              ) : null}

              <div className="form-group">
                <label htmlFor="password">{t("password")}</label>
                <input
                  type="password"
                  value={user.password}
                  className="col-lg-12 bg-light text-right"
                  onChange={(event) => setUser({ ...user, password: event.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="profileImage">{t("profilepic")}</label>
                <input
                  type="file"
                  className="col-lg-12 bg-light text-right"
                  name="profileImage"
                  id="profileImage"
                  onChange={(event) => setUser({ ...user, image: event.target.files[0] })}
                  required
                />
              </div>

              {loading ? (
                <div className="text-center">
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="form-group m-0">
                  <button
                    type="button"
                    className="btn btn-warning btn-block text-light"
                    onClick={(event) => addEmployee(event, user)}
                  >
                    {t("add")}
                  </button>
                </div>
              )}
            </form>
          </div>
          {showForm ? (
            <div className="contact-form bg-white rounded shadow">
              <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
            </div>
          ) : null}
          {showFormSuccess ? (
            <div className="contact-form bg-white rounded shadow">
              <SuccessDialog success={t("add-success")} onClick={() => setShowFormSuccess((showForm) => false)} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
