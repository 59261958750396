import React, { useState, useEffect, useCallback } from "react";
import Fire from "../Api/Fire";
import ErrorDialog from "../components/errorDialog";
import SuccessDialog from "../components/successDialog";
import Collapsible from "react-collapsible";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";

const BlogsList = ({ type }) => {
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const { t } = useTranslation();

  //Get List of Blogs
  const getAllBlogs = useCallback(async () => {
    const blogs = await Fire.getAllBlogs(type === 1 ? Fire.uid : null);
    if (blogs === null) {
      setError((error) => t("error"));
      setShowForm((showForm) => true);
    } else {
      setBlogs(blogs);
    }
    setLoading((loading) => false);
  }, [t, type]);

  useEffect(() => {
    document.title = "عز للتحول الرقمي - المدونات";
    window.scrollTo(0, 0);
    getAllBlogs();
  }, [getAllBlogs]);

  //Delete Blog
  const deleteBlog = async (id) => {
    const response = await Fire.deleteBlog(id);
    if (response) {
      setShowFormSuccess((showFormSuccess) => true);
    } else {
      setError((error) => response);
      setShowForm((showForm) => true);
    }
    setLoading((loading) => false);
    window.location.reload();
  };

  return (
    <div className={`container rounded ${type === 2 ? "pt-5" : "bg-white"}`} data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex text-center align-items-center">
          <div className="col-lg-12 py-4 text-center">
            {type === 1 ? (
              <h4>
                <b>{t("your-blogs")}</b>
              </h4>
            ) : (
              <h1 className="p-5 text-warning font-weight-bold">{t("blogs")}</h1>
            )}

            {loading ? (
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : blogs.length === 0 ? (
              <h5 className="py-2">{t("no-blogs")}</h5>
            ) : (
              <ul className="list-group text-left">
                {blogs.map((blog, index) => (
                  <li key={index} className={`list-group-item ${type === 2 ? " mt-2" : "list-group-item-action"}`}>
                    {type === 1 ? (
                      <Collapsible
                        triggerStyle={{ cursor: "pointer" }}
                        trigger={[
                          blog.title +
                            " • " +
                            blog.createdAt.toDate().toLocaleDateString("ar-EG-u-nu-latn", {
                              weekday: "long",
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }),
                          <i className="fa fa-chevron-down pl-2" />,
                        ]}
                      >
                        <div className="text-right">
                          <button class="btn" onClick={() => deleteBlog(blog.uid)}>
                            <i className="fa fa-trash delete" />
                          </button>
                        </div>

                        <ReactQuill readOnly={true} theme="bubble" value={blog.post || ""} />
                      </Collapsible>
                    ) : (
                      <div className="blog-post text-right">
                        <h2 className="blog-post-title">
                          <b>{blog.title}</b>
                        </h2>
                        <p className="blog-post-meta">
                          <b>{blog.userName}</b>
                          {"  • "}
                          {blog.createdAt.toDate().toLocaleDateString("ar-EG-u-nu-latn", {
                            weekday: "long",
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}

                          <img className="rounded-circle profilePic mr-2 ml-2" src={blog.userImage} alt="profilePic" />
                        </p>
                        <div className="text-center">
                          <img className="img-fluid mr-2 ml-2" src={blog.cover} alt="cover" />
                        </div>

                        <ReactQuill readOnly={true} theme="bubble" value={blog.post || ""} />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {showForm ? (
            <div className="contact-form bg-white rounded shadow">
              <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
            </div>
          ) : null}
          {showFormSuccess ? (
            <div className="contact-form bg-white rounded shadow">
              <SuccessDialog success={t("delete-success")} onClick={() => setShowFormSuccess((showForm) => false)} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BlogsList;
