import firebase from "firebase/app";
import "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

class Fire {
  constructor() {
    this.init();
  }

  // Initialize Firebase
  init = () => {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyDeVuDAoZXAKiK6OPJBMKk_ijb-Ip8iFz4",
        authDomain: "ezzwebsite-f14f7.firebaseapp.com",
        projectId: "ezzwebsite-f14f7",
        storageBucket: "ezzwebsite-f14f7.appspot.com",
        messagingSenderId: "343296599508",
        appId: "1:343296599508:web:85ce66a2dbb1ef52316241",
        measurementId: "G-ZHK2LWRTR4",
      });
    }
  };

  /* ===========================
    Auth Api
  ============================*/
  signInWithEmail = async (email, password) => {
    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await firebase.auth().signInWithEmailAndPassword(email, password);

      //Check if user's email is verified or not
      if (firebase.auth().currentUser.emailVerified) {
        const snapshot = await this.users_db.doc(this.uid).get();

        return snapshot.data().type;
      } else {
        //Display message
        return "البريد الإلكتروني غير موثق الرجاء التحقق من البريد";
      }
    } catch (error) {
      return error.message;
    }
  };

  signUpWithEmail = async (email, password, user) => {
    try {
      const userCreds = await firebase.auth().createUserWithEmailAndPassword(email, password);

      const image = await this.uploadMedia(`Employees/${userCreds.user.uid}`, user.image, "image/jpeg");

      //Insert user object to firestore database
      user.image = image;
      user.uid = userCreds.user.uid;
      await this.users_db.doc(userCreds.user.uid).set(user);

      //Send Verification Email
      await firebase.auth().currentUser.sendEmailVerification();
      await this.signOut();
      return true;
    } catch (error) {
      alert(error.message);
      return error.message;
    }
  };

  resetPassword = async (email) => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      return "الرجاء التحقق من الايميل";
    } catch (error) {
      return error.message;
    }
  };

  signOut = async () => {
    try {
      await firebase.auth().signOut();
      window.location.reload();
      return true;
    } catch (e) {
      alert(e.message);
      return false;
    }
  };

  updateUser = async (id, data) => {
    try {
      await this.users_db.doc(id).update(data);
      alert("تم التعديل بنجاح");
    } catch (e) {
      alert(e.message);
    }
  };

  getUser = async (id) => {
    try {
      const user = await this.users_db.doc(id).get();
      return user.data();
    } catch (e) {
      return null;
    }
  };

  getAllUsers = async (type) => {
    try {
      const querySnapshot = await this.users_db.get();

      let employees = [];
      querySnapshot.forEach((user) => {
        if (type === 1) {
          employees.push(user.data());
        } else if (type === 2) {
          employees.push({
            label: `${user.data().firstName} ${user.data().lastName}`,
            value: user.data().uid,
          });
        } else {
          if (user.data().type !== "Admin") {
            employees.push(user.data());
          }
        }
      });
      return employees;
    } catch (e) {
      return e.message;
    }
  };

  deleteUser = async (user) => {
    try {
      await this.users_db.doc(user.uid).delete();

      if (user.image) {
        await this.deleteMedia(user.image);
      }

      return true;
    } catch (e) {
      return e.message;
    }
  };

  /* ===========================
    Chat Api
  ============================*/
  sendMessage = async (message) => {
    try {
      await this.chat_db.doc(message.uid).set(message);
      return true;
    } catch (error) {
      alert(error.message);
    }
  };

  getMessages = (callback) => {
    return this.chat_db.orderBy("createdAt", "asc").onSnapshot((querySnapshot) => {
      let messages = [];
      querySnapshot.forEach((message) => {
        messages.push(message.data());
      });
      callback(messages);
    });
  };

  /* ===========================
    Blog Api
  ============================*/
  addBlog = async (blog) => {
    try {
      let key = uuidv4();
      const user = await this.getUser(blog.userId);

      const image = await this.uploadMedia(`Employees/${key}`, blog.cover, "image/jpeg");

      blog.uid = key;
      blog.userName = `${user.firstName} ${user.lastName}`;
      blog.userImage = user.image;
      blog.cover = image;

      await this.blogs_db.doc(key).set(blog);
      return true;
    } catch (e) {
      return e.message;
    }
  };

  getAllBlogs = async (id) => {
    try {
      const querySnapshot = await this.blogs_db.orderBy("createdAt", "desc").get();
      let blogs = [];
      querySnapshot.forEach((blog) => {
        if (id != null) {
          if (blog.data().userId === id) {
            blogs.push(blog.data());
          }
        } else {
          blogs.push(blog.data());
        }
      });
      return blogs;
    } catch (error) {
      return null;
    }
  };

  getBlog = async (id) => {
    try {
      const documentSnapshot = await this.clubs_db.doc(id).get();
      return documentSnapshot.data();
    } catch (error) {
      alert(error.message);
      return null;
    }
  };

  updateBlog = async (id, data) => {
    try {
      await this.blogs_db.doc(id).update(data);
      alert("تم التعديل بنجاح");
    } catch (error) {
      alert(error.message);
    }
  };

  deleteBlog = async (id) => {
    try {
      await this.blogs_db.doc(id).delete();
      return true;
    } catch (error) {
      alert(error.message);
      return false;
    }
  };

  /* ===========================
    Announcments Api
  ============================*/
  addAnnouncment = async (announcment, employeeType) => {
    try {
      let key = uuidv4();
      await this.announcements_db.doc(key).set({
        uid: key,
        announcment: announcment,
        createdAt: new Date(),
        hiddenFrom: [],
        employeeType: employeeType,
      });
      return true;
    } catch (e) {
      return e.message;
    }
  };

  getAllAnnouncments = async (id) => {
    try {
      const user = await this.users_db.doc(id).get();
      const querySnapshot = await this.announcements_db.orderBy("createdAt", "desc").get();

      let announcments = [];
      querySnapshot.forEach((announcment) => {
        if (announcment.data().employeeType === "All") {
          if (announcment.data().hiddenFrom.includes(id) === false) {
            announcments.push(announcment.data());
          }
        } else {
          if (
            announcment.data().hiddenFrom.includes(id) === false &&
            announcment.data().employeeType === user.data().department
          ) {
            announcments.push(announcment.data());
          }
        }
      });

      return announcments;
    } catch (error) {
      return null;
    }
  };

  getAnnouncment = async (id) => {
    try {
      const documentSnapshot = await this.announcements_db.doc(id).get();
      return documentSnapshot.data();
    } catch (error) {
      alert(error.message);
      return null;
    }
  };

  updateAnnouncment = async (id, data) => {
    try {
      await this.announcements_db.doc(id).update(data);
    } catch (error) {
      alert(error.message);
    }
  };

  deleteAnnouncment = async (id) => {
    try {
      await this.announcements_db.doc(id).delete();
      return true;
    } catch (error) {
      alert(error.message);
      return false;
    }
  };

  /* ===========================
    Vacation Requests Api
  ============================*/
  addVacationRequest = async (vacation) => {
    try {
      let key = uuidv4();
      vacation.uid = key;
      await this.vacations_db.doc(key).set(vacation);
      return true;
    } catch (e) {
      return e.message;
    }
  };

  getAllVacationRequests = async (id) => {
    try {
      if (id != null) {
        const querySnapshot = await this.vacations_db.where("userId", "==", id).get();
        let requests = [];
        querySnapshot.forEach((request) => requests.push(request.data()));
        return requests.sort((a, b) => a.from - b.from);
      } else {
        const querySnapshot = await this.vacations_db.orderBy("from", "asc").get();
        let requests = [];
        querySnapshot.forEach((request) => {
          if (request.data().state === "في الإنتظار") requests.push(request.data());
        });
        return requests;
      }
    } catch (error) {
      return null;
    }
  };

  updateRequest = async (id, data, rejectReason) => {
    try {
      if (rejectReason != null) {
        await this.vacations_db.doc(id).update({ state: data, rejectReason: rejectReason });
      } else {
        await this.vacations_db.doc(id).update({ state: data });
      }
      return "تم الارسال بنجاح";
    } catch (error) {
      return error.message;
    }
  };

  /* ===========================
    Messages Api
  ============================*/
  addMessage = async (message) => {
    try {
      let key = uuidv4();
      message.uid = key;

      if (message.file != null) {
        const file = await this.uploadMedia(`Messages/files/${message.file.name}`, message.file, message.file.type);
        message.messageType = message.file.type;
        message.file = file;
      }

      await this.messages_db.doc(key).set(message);
      return true;
    } catch (error) {
      return error.message;
    }
  };

  getAllMessages = async (department) => {
    try {
      const querySnapshot = await this.messages_db.orderBy("createdAt", "desc").get();
      let messages = [];
      querySnapshot.forEach((message) => {
        if (department != null) {
          if (
            (message.data().toDepartment === department || message.data().fromDepartment === department) &&
            !message.data().hiddenFrom.includes(this.uid)
          ) {
            messages.push(message.data());
          }
        } else {
          if (!message.data().hiddenFrom.includes(this.uid)) messages.push(message.data());
        }
      });
      return messages;
    } catch (error) {
      return null;
    }
  };

  getAllDeletedMessages = async (department, callback) => {
    try {
      const querySnapshot = await this.messages_db.orderBy("createdAt", "desc").get();
      let messages = [];
      querySnapshot.forEach((message) => {
        if (department != null) {
          if (
            (message.data().toDepartment === department || message.data().fromDepartment === department) &&
            message.data().hiddenFrom.includes(this.uid)
          ) {
            messages.push(message.data());
          }
        } else {
          if (message.data().hiddenFrom.includes(this.uid)) messages.push(message.data());
        }
      });
      return messages;
    } catch (error) {
      return null;
    }
  };

  updateMessage = async (id, data) => {
    try {
      await this.messages_db.doc(id).update(data);
      return true;
    } catch (error) {
      return error.message;
    }
  };
  /* ===========================
    Tasks Api
  ============================*/
  addEmployeeTask = async (task) => {
    try {
      let key = uuidv4();
      task.uid = key;
      await this.users_db.doc(task.userId).collection("Tasks").doc(key).set(task);
      return true;
    } catch (e) {
      return e.message;
    }
  };

  getEmployeeTasks = async (id, type) => {
    try {
      if (type === 2) {
        const querySnapshot = await this.users_db
          .doc(id)
          .collection("Tasks")
          .where("state", "==", "لم يتم الإنهاء بعد")
          .get();
        let tasks = [];
        querySnapshot.forEach((task) => {
          tasks.push(task.data());
        });
        return tasks;
      } else {
        const querySnapshot = await this.users_db.doc(id).collection("Tasks").get();
        let tasks = [];
        querySnapshot.forEach((task) => {
          tasks.push(task.data());
        });
        return tasks;
      }
    } catch (error) {
      return null;
    }
  };

  updateEmployeeTask = async (userId, id, data) => {
    try {
      await this.users_db.doc(userId).collection("Tasks").doc(id).update(data);
      return true;
    } catch (error) {
      return error.message;
    }
  };

  /* ===========================
    Recruitment Api
  ============================*/
  addRecruitment = async (user) => {
    try {
      let key = uuidv4();
      user.uid = key;

      const pdf = await this.uploadMedia(`Recruitment/${key}`, user.resume, "application/pdf");

      user.resume = pdf;
      user.createdAt = firebase.firestore.FieldValue.serverTimestamp();

      await this.recruitment_db.doc("Recruitment").collection("Recruitments").doc(key).set(user);

      return true;
    } catch (e) {
      return e.message;
    }
  };

  getRecruitments = async () => {
    try {
      const querySnapshot = await this.recruitment_db
        .doc("Recruitment")
        .collection("Recruitments")
        .orderBy("createdAt", "desc")
        .get();

      let recruitments = [];
      querySnapshot.forEach((recruitment) => recruitments.push(recruitment.data()));
      return recruitments;
    } catch (error) {
      return null;
    }
  };

  getRecruitmentCondition = async () => {
    try {
      const response = await this.recruitment_db.doc("Recruitment").get();
      return response.data();
    } catch (error) {
      return null;
    }
  };

  deleteRecruitment = async (id) => {
    try {
      await this.recruitment_db.doc("Recruitment").collection("Recruitments").doc(id).delete();
      return true;
    } catch (error) {
      return error.message;
    }
  };

  /* ===========================
    Media Api
  ============================*/
  uploadMedia = async (path, uri, type) => {
    const ref = firebase.storage().ref().child(path);
    const snapshot = await ref.put(uri, { contentType: type });
    return await snapshot.ref.getDownloadURL();
  };

  deleteMedia = async (path) => {
    try {
      const ref = firebase.storage().refFromURL(path);
      await ref.delete();
    } catch (e) {
      alert(e.message);
    }
  };

  get users_db() {
    return firebase.firestore().collection("Users");
  }

  get blogs_db() {
    return firebase.firestore().collection("Blogs");
  }

  get announcements_db() {
    return firebase.firestore().collection("Announcements");
  }

  get vacations_db() {
    return firebase.firestore().collection("Vacations");
  }

  get privacy_db() {
    return firebase.firestore().collection("PrivacyPolicy");
  }

  get projects_db() {
    return firebase.firestore().collection("Blogs");
  }

  get chat_db() {
    return firebase.firestore().collection("Chat");
  }

  get recruitment_db() {
    return firebase.firestore().collection("Recruitment");
  }

  get messages_db() {
    return firebase.firestore().collection("Messages");
  }

  get uid() {
    return (firebase.auth().currentUser || {}).uid;
  }
}

export default new Fire();
