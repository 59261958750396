import React, { useState, useEffect, useCallback } from "react";
import Fire from "../../Api/Fire";
import ErrorDialog from "../../components/errorDialog";
import ReactQuill from "react-quill";
import SuccessDialog from "../../components/successDialog";
import Searchable from "react-searchable-dropdown";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [vacationsRequests, setVacationsRequests] = useState([]);
  const [announcments, setAnnouncments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [showText, setShowText] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [announcmentsLoading, setAnnouncmentsLoading] = useState(true);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [vacationsRequestsLoading, setVacationsRequestsLoading] = useState(true);
  const { t } = useTranslation();

  //get All Announcments
  const getAllAnnouncments = useCallback(async () => {
    setAnnouncmentsLoading((loading) => true);
    const announcments = await Fire.getAllAnnouncments(Fire.uid);
    if (announcments != null) {
      setAnnouncments(announcments);
    } else {
      setError(t("error"));
      setShowForm((showForm) => true);
    }
    setAnnouncmentsLoading((loading) => false);
  }, [t]);

  //get All Vacation Requests
  const getAllVacationRequests = useCallback(async () => {
    setVacationsRequestsLoading((loading) => true);
    const requests = await Fire.getAllVacationRequests(null);
    if (requests != null) {
      setVacationsRequests(requests);
    } else {
      setError(t("error"));
      setShowForm((showForm) => true);
    }
    setVacationsRequestsLoading((loading) => false);
  }, [t]);

  //get All Tasks
  const getAllTasks = async (id) => {
    setTasksLoading((loading) => true);
    const tasks = await Fire.getEmployeeTasks(id, 1);
    if (tasks != null) {
      setTasks(tasks);
    } else {
      setError(t("error"));
      setShowForm((showForm) => true);
    }
    setTasksLoading((loading) => false);
  };

  useEffect(() => {
    document.title = "عز للتحول الرقمي - الصفحة الرئيسية";
    //get data
    getAllVacationRequests();
    getAllAnnouncments();
    Fire.getAllUsers(2).then((users) => setEmployees(users));
  }, [getAllAnnouncments, getAllVacationRequests]);

  //update announcment
  const updateAnnouncment = async (announcment) => {
    await Fire.updateAnnouncment(announcment.uid, { hiddenFrom: [...announcment.hiddenFrom, Fire.uid] });
    getAllAnnouncments();
  };

  const updateRequest = (response, request) => {
    setLoading((loading) => true);
    const res = Fire.updateRequest(request.uid, response, rejectReason);
    if (res) {
      setShowFormSuccess((showFormSuccess) => true);
    } else {
      setError(t("error"));
      setShowForm((showForm) => true);
    }
    setLoading((loading) => false);
    getAllVacationRequests();
  };

  return (
    <div className="container bg-white rounded" data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 py-4 text-center border-bottom">
          <h2>
            <b>{t("vacation-requests")}</b>
          </h2>
          {vacationsRequestsLoading ? (
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : vacationsRequests.length === 0 ? (
            <h5 className="py-2">{t("no-requests")}</h5>
          ) : (
            <ul className="list-group text-left">
              {vacationsRequests.map((request, index) => (
                <li key={index} className="list-group-item list-group-item-action">
                  <div className="d-flex justify-content-between">
                    <h5>
                      <b>{request.title}</b>
                    </h5>
                    <p>
                      from {request.from} To {request.to}
                    </p>
                  </div>
                  <h6 className="text-primary">{`${request.userName} - ${request.userDepartment}`}</h6>
                  <p>{request.message}</p>
                  <div className="form-group d-flex justify-content-end">
                    {loading ? (
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div>
                        <button
                          type="button"
                          className="btn btn-success text-light mr-2"
                          onClick={() => updateRequest("تمت الموافقة", request)}
                        >
                          {t("accept")}
                        </button>
                        <button type="button" className="btn btn-danger text-light" onClick={() => setShowText(true)}>
                          {t("reject")}
                        </button>
                      </div>
                    )}
                  </div>
                  {showText ? (
                    <div className="d-flex justify-content-between">
                      <textarea className="w-100 mr-1" rows={3} onChange={(event) => setRejectReason(event.target.value)} />
                      {loading ? (
                        <div className="spinner-border text-dark" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-danger text-light"
                          onClick={() => updateRequest("تم الرفض", request)}
                        >
                          {t("send")}
                        </button>
                      )}
                    </div>
                  ) : null}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="col-lg-12 py-4 text-center border-bottom">
          <h2>
            <b>{t("notifications")}</b>
          </h2>
          {announcmentsLoading ? (
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : announcments.length === 0 ? (
            <h5 className="py-2">{t("no-notifications")}</h5>
          ) : (
            <ul className="list-group text-right">
              {announcments.map((item, index) => (
                <li key={index} className="list-group-item list-group-item-action">
                  <button className="btn" onClick={() => updateAnnouncment(item)}>
                    <i className="fa fa-trash delete" />
                  </button>
                  <ReactQuill readOnly={true} theme="bubble" value={item.announcment || ""} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="col-lg-12 py-4 text-center border-bottom">
        <h2>
          <b>{t("tasks")}</b>
        </h2>
        <Searchable
          value=""
          placeholder={t("search")}
          notFoundText={t("no-employees")}
          options={employees}
          onSelect={(option) => getAllTasks(option.value)}
          listMaxHeight={200}
        />
        {tasksLoading ? (
          <div className="spinner-border text-dark mt-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : tasks.length === 0 ? (
          <h5 className="py-2 mt-2">{t("no-tasks")}</h5>
        ) : (
          <div>
            <ul className="list-group text-right mt-2">
              {tasks.map((task, index) => (
                <li key={index} className="list-group-item list-group-item-action">
                  <h5 className={task.type.value}>{task.type.label}</h5>
                  <h6>{task.state}</h6>
                  {task.state === "تم الرفض" ? <p dir="rtl">السبب: {task.rejectReason} </p> : null}
                  <ReactQuill readOnly={true} theme="bubble" value={task.message || ""} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {showForm ? (
        <div className="contact-form bg-white rounded shadow">
          <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
        </div>
      ) : null}
      {showFormSuccess ? (
        <div className="contact-form bg-white rounded shadow">
          <SuccessDialog success={t("edited-successfully")} onClick={() => setShowFormSuccess((showForm) => false)} />
        </div>
      ) : null}
    </div>
  );
};

export default Home;
