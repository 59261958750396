import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import Consult from "../Assets/Images/plan.svg";

const ConsultService = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `عز للتحول الرقمي - ${t("consult")}`;
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 text-center" data-aos="zoom-in-down">
              <h1 className="p-5 text-warning font-weight-bold">{t("consult")}</h1>
            </div>
          </div>
          {t("language") === "en" ? (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={Consult} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5 mt-lg-0">
                  <h4 dir="rtl">
                    يسرنا في مؤسسة عز للتحول الرقمي تقديم الاستشارات في قطاع تكنولوجيا المعلومات والتخطيط الاستراتيجي للمؤسسات
                    والعمليات التقنية، وهذه الخدمة تقدم الاستراتيجية المثلى لتحقيق اهداف المؤسسة من النمو المستدام والقدرة على
                    المنافسة، وذلك باقتراح احدث حلول التكنولوجيا.
                  </h4>
                  <h4 dir="rtl" className="mt-3">
                    فريق العمل لدينا يركز في عمله على فهم العمليات التجارية او الخدمية حتى الصناعية، وذلك للاستفادة من العمليات
                    الموجودة وتقييم البيانات لتقديم التوصيات التي تضع في نظرها الأهداف والغاية من التطوير.
                  </h4>
                </div>
              </div>
              <div className="text-center my-5">
                <h2 dir="rtl" className="title-service">
                  <strong>
                    الاستشارات في تقنية المعلومات هي العمود الفقري لأي تطوير قد يحصل في الوقت الحالي حتى تكون إجراءات التطوير
                    سريعة وفعالة ومن هذه الخدمات:
                  </strong>
                </h2>
                <ul className="steps">
                  <li>
                    <div className="card">
                      <h2 className="number">1</h2>
                      <div className="card-body">
                        <h5 dir="rtl" className="card-title">
                          تقديم التوصيات لتحسين فعالية وكفاءة التطبيقات الموجودة
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">2</h2>
                      <div className="card-body">
                        <h5 dir="rtl" className="card-title">
                          تصميم الحلول التي تستند على التكنولوجيا، بناءً على تحليل طريقة عمل المؤسسة والمتاعب التي تواجهها
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">3</h2>
                      <div className="card-body">
                        <h5 dir="rtl" className="card-title">
                          التخطيط وتنفيذ المبادرات التطويرية
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number">4</h2>
                      <div className="card-body">
                        <h5 dir="rtl" className="card-title">
                          اعداد وتخطيط طلبات العروض للمؤسسات (RFP Request for Proposal)
                        </h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <hr className="m-5" />
            </div>
          ) : (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left mt-5 mt-lg-0">
                  <h4>
                    We are pleased at Ezz Foundation for Digital Transformation to provide consultations in the information
                    technology sector and strategic planning for enterprises And technical operations, and this service provides
                    the optimal strategy to achieve the goals of the institution of sustainable growth and ability to Competition,
                    by proposing the latest technology solutions.
                  </h4>
                  <h4 className="mt-3">
                    Our work team focuses in its work on understanding commercial or service, even industrial processes, in order
                    to benefit from the operations Existing data and assessing data to provide recommendations that put into
                    consideration the goals and objectives of the development.
                  </h4>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={Consult} alt="phone" />
                </div>
              </div>
              <div className="text-center my-5">
                <h2 className="title-service">
                  <strong>
                    Consulting in information technology is the backbone of any development that may take place at the present
                    time so that the development procedures are Fast and efficient, and among these services:
                  </strong>
                </h2>
                <ul className="steps">
                  <li>
                    <div className="card">
                      <h2 className="number"> 1 </h2>
                      <div className="card-body">
                        <h5 className="card-title">
                          Make recommendations to improve the effectiveness and efficiency of existing applications
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 2 </h2>
                      <div className="card-body">
                        <h5 className="card-title">
                          Design technology-based solutions, based on an analysis of how the organization operates and the
                          troubles it faces
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 3 </h2>
                      <div className="card-body">
                        <h5 className="card-title">Planning and implementing development initiatives</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card">
                      <h2 className="number"> 4 </h2>
                      <div className="card-body">
                        <h5 className="card-title">
                          Preparing and planning requests for proposals for institutions (RFP Request for Proposal)
                        </h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <hr className="m-5" />
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="zoom-in">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ConsultService;
