import React, { useEffect, useState } from "react";
import Fire from "../Api/Fire";
import ErrorDialog from "../components/errorDialog";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState("خطأ");
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "تغيير كلمه السر";
    window.scrollTo(0, 0);
  }, []);

  //Reset Password
  const resetPassword = (event) => {
    setLoading((loading) => true);
    event.preventDefault();
    Fire.resetPassword(email).then((response) => {
      setError((error) => response);
      setShowForm((showForm) => true);
      setLoading((loading) => false);
    });
  };

  return (
    <main>
      <div className="container">
        <div className="row pt-5" data-aos="zoom-in">
          <div className="col-lg-12">
            <div className="card-wrapper">
              <div className="card-body">
                <h4 className="card-title text-center">{t("reset-pass")}</h4>
                <form className="text-right">
                  <div class="form-group">
                    <label for="email">{t("email")}</label>
                    <input
                      type="text"
                      value={email}
                      className="col-lg-12 bg-light text-right"
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                  </div>
                  {loading ? (
                    <div className="text-center">
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group m-0">
                      <button
                        type="button"
                        className="btn btn-warning btn-block text-light"
                        onClick={(event) => resetPassword(event, email)}
                      >
                        {t("reset-pass")}
                      </button>
                    </div>
                  )}
                </form>
              </div>
              {showForm ? (
                <div className="contact-form bg-white rounded shadow">
                  <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
