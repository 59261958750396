import React, { useState, useEffect } from "react";
import Fire from "../../Api/Fire";
import ErrorDialog from "../../components/errorDialog";
import SuccessDialog from "../../components/successDialog";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

const SendMessage = () => {
  const { t } = useTranslation();
  const [error, setError] = useState(t("error"));
  const [showForm, setShowForm] = useState(false);
  const [success, setSuccess] = useState("خطأ");
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [user, setUser] = useState("");
  const [file, setFile] = useState(null);
  const [employeeType, setEmployeeType] = useState("All");

  useEffect(() => {
    Fire.getUser(Fire.uid).then((user) => setUser(user));
    document.title = "عز للتحول الرقمي - إرسال رسالة";
  }, []);

  //Publish Announcement to team.
  const sendMessage = async (event) => {
    setLoading((loading) => true);
    event.preventDefault();
    if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      setError((error) => t("fill"));
      setShowForm((showForm) => true);
      setLoading((loading) => false);
    } else {
      let message = {
        message: value,
        file: file,
        fromDepartment: user.department,
        toDepartment: employeeType,
        createdAt: new Date(),
        userId: user.uid,
        userName: `${user.firstName} ${user.lastName}`,
        hiddenFrom: [],
      };
      const response = await Fire.addMessage(message);
      if (response) {
        setValue((value) => "");
        setSuccess((success) => t("send-succss"));
        setShowFormSuccess((showFormSuccess) => true);
      } else {
        setError((error) => response);
        setShowForm((showForm) => true);
      }
      setLoading((loading) => false);
    }
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };

  const format = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "align",
    "script",
    "background",
  ];

  return (
    <div className="container bg-white rounded" data-aos="zoom-in">
      <div className="row">
        <form className="col-lg-12 py-4 text-center">
          <h4>{t("send-message")}</h4>
          <div className="form-group text-right">
            <label htmlFor="department">
              <b>{t("department")}</b>
            </label>
            <select
              className="col-lg-12 bg-light text-right"
              id="department"
              name="department"
              onChange={(event) => setEmployeeType(event.target.value)}
              required
            >
              <option value="All">All</option>
              <option value="Management">Management</option>
              <option value="Engineering">Engineering</option>
              <option value="Marketing">Marketing</option>
              <option value="Designer">Designer</option>
              <option value="Technical Consultant">Technical Consultant</option>
            </select>
          </div>
          <div className="form-group text-right">
            <label htmlFor="profileImage">
              <b>{t("file")}</b>
            </label>
            <input
              type="file"
              className="col-lg-12 bg-light text-right"
              accept="application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/jpg, image/jpeg, image/png"
              name="profileImage"
              id="profileImage"
              onChange={(event) => setFile(event.target.files[0])}
            />
          </div>
          <ReactQuill theme="snow" value={value} onChange={setValue} modules={modules} formats={format} />
          {loading ? (
            <div className="text-center mt-3">
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="form-group m-0">
              <button type="button" className="btn btn-warning btn-block text-light mt-3" onClick={(event) => sendMessage(event)}>
                {t("post")}
              </button>
            </div>
          )}
        </form>
        {showForm ? (
          <div className="contact-form bg-white rounded shadow">
            <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
          </div>
        ) : null}
        {showFormSuccess ? (
          <div className="contact-form bg-white rounded shadow">
            <SuccessDialog success={success} onClick={() => setShowFormSuccess((showForm) => false)} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SendMessage;
