import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import projectPlan from "../Assets/Images/projectPlan.svg";

const ProjectStudyService = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `عز للتحول الرقمي - ${t("project-study")}`;
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 text-center" data-aos="zoom-in-down">
              <h1 className="p-5 text-warning font-weight-bold">{t("project-study")}</h1>
            </div>
          </div>
          {t("language") === "en" ? (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={projectPlan} alt="phone" />
                </div>
                <div className="col-lg-7 text-right mt-5">
                  <h4 dir="RTL">
                    هي دراسة المشروع نقوم بها لأصحاب المشاريع الالكترونية وذلك بهدف التمكن من تنفيذ المشروع على أسس صحيحة مضمونة
                    بتحقيق الأهداف ، ودراسة الجدوى لمشروع الكتروني مثل ( دراسة جدوى تطبيق جوال ، دراسة جدوى متجر الكتروني أو غير
                    ذلك ) توضح الاستثمارات المطلوبة، والعائد المتوقع والمؤثرات الخارجية على المشروع، مثل قوانين الدولة، والمُنافسة
                    والتطور التقني والفني.
                  </h4>
                  <h4 dir="RTL" className="mt-3">
                    كما أنها تحتوي على الخدمات اللوجستية المقترحة لتقديم منتج أو خدمة ، والموارد التي ستحتاجها ، ودعم الموظفين
                    والهيكل التنظيمي المطلوب.
                  </h4>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left mt-5">
                  <h4>
                    It is the study of the project that we do for the owners of electronic projects, with the aim of being able to
                    implement the project on a guaranteed correct basis By achieving the goals, and a feasibility study for an
                    electronic project (such as (a feasibility study for a mobile application, a feasibility study for an online
                    store, or others) That) explains the required investments, the expected return and external influences on the
                    project, such as state laws, and competition And technical and technical development.
                  </h4>
                  <h4 className="mt-3">
                    It also contains the logistics of proposing a product or service, the resources you will need, and employee
                    support And the required organizational structure.
                  </h4>
                </div>
                <div className="col-lg-5 order-first order-lg-last">
                  <img className="img-fluid" src={projectPlan} alt="phone" />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="zoom-in">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ProjectStudyService;
