import React, { useState } from "react";
import Home from "./Home";
import EmployeeList from "./EmployeeList";
import AddEmployee from "./AddEmployee";
import PublishNews from "./PublishNews";
import PublicChat from "./PublicChat";
import AddBlog from "../AddBlog";
import BlogsList from "../BlogsList";
import RecruitmentsList from "./RecruitmentsList";
import MessagesList from "./MessagesList";
import AddTask from "./AddTask";
import useProtectedRoute from "../../util/useProtectedRoute";
import { useTranslation } from "react-i18next";

const AdminMain = () => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  useProtectedRoute();

  const getPage = () => {
    switch (page) {
      case 1:
        return <Home />;
      case 2:
        return <EmployeeList />;
      case 3:
        return <PublishNews />;
      case 4:
        return <BlogsList type={1} />;
      case 5:
        return <AddEmployee />;
      case 6:
        return <AddBlog />;
      case 7:
        return <AddTask />;
      case 8:
        return <RecruitmentsList />;
      case 9:
        return <PublicChat />;
      case 10:
        return <MessagesList type={1} />;
      default:
        return <Home />;
    }
  };

  return (
    <main>
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-3" data-aos="zoom-in">
            <div className="list-group">
              <button onClick={() => setPage(1)} className="list-group-item list-group-item-action">
                {t("main")}
              </button>
              <button onClick={() => setPage(3)} className="list-group-item list-group-item-action">
                {t("post-notification")}
              </button>
              <button onClick={() => setPage(2)} className="list-group-item list-group-item-action">
                {t("employees")}
              </button>
              <button onClick={() => setPage(5)} className="list-group-item list-group-item-action">
                {t("add-employee")}
              </button>
              <button onClick={() => setPage(7)} className="list-group-item list-group-item-action">
                {t("send-task")}
              </button>
              <button onClick={() => setPage(4)} className="list-group-item list-group-item-action">
                {t("blogs")}
              </button>
              <button onClick={() => setPage(6)} className="list-group-item list-group-item-action">
                {t("add-blog")}
              </button>
              <button onClick={() => setPage(8)} className="list-group-item list-group-item-action">
                {t("recruitments")}
              </button>
              <button onClick={() => setPage(10)} className="list-group-item list-group-item-action">
                {t("department-messages")}
              </button>
              <button onClick={() => setPage(9)} className="list-group-item list-group-item-action">
                {t("chat")}
              </button>
            </div>
          </div>
          <div className="col-lg-9 mt-4 mt-lg-0" data-aos="zoom-in">
            {getPage()}
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdminMain;
