import React from "react";
import { useTranslation } from "react-i18next";

const ErrorDialog = ({ onClick, error }) => {
  const { t } = useTranslation();

  return (
    <div className="row" data-aos="zoom-in">
      <div className="col-lg-12 text-center align-items-center p-3">
        <i className="fa fa-exclamation-triangle h1 text-danger"></i>
        <p className="m-3 error">{error}</p>
        <div className="form-group m-3">
          <button type="button" className="btn btn-warning btn-block text-light" onClick={onClick}>
            {t("ok")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorDialog;
