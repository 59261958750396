import React, { useEffect } from "react";
import ParentalControl from "../Assets/Images/parnetalcontrol.svg";
import HopIn from "../Assets/Images/HopIn.svg";
import CryptoBud from "../Assets/Images/cryptobud.svg";
import Ber from "../Assets/Images/ber website.png";
import Br from "../Assets/Images/websiteBr.png";
import Twitter from "../Assets/Images/twitterapp.png";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t } = useTranslation();

  useEffect(() => {
    //Movement Animation to happen
    const card = document.querySelectorAll(".proj .li-container .card");
    const container = document.querySelectorAll(".proj .li-container");
    //Items
    const icon = document.querySelectorAll(".proj img");

    for (let i = 0; i < card.length; i++) {
      //Moving Animation Event
      container[i].addEventListener("mousemove", (e) => {
        let xAxis = (window.innerWidth / 2 - e.pageX) / (25 + i * 15);
        let yAxis = (window.innerHeight / 2 - e.pageY) / (25 + i * 15);
        card[i].style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
      });
      //Animate In
      container[i].addEventListener("mouseenter", (e) => {
        card[i].style.transition = "none";
        //Popout
        icon[i].style.transform = "translateZ(100px)";
      });
      //Animate Out
      container[i].addEventListener("mouseleave", (e) => {
        card[i].style.transition = "all 0.5s ease";
        card[i].style.transform = `rotateY(0deg) rotateX(0deg)`;
        //Popback
        icon[i].style.transform = "translateZ(0px) rotateZ(0deg)";
      });
    }

    document.title = "عز للتحول الرقمي -المشاريع";
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <div className="container">
        <div className="row ">
          <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex text-center align-items-center">
            <div data-aos="zoom-in">
              <h1 className="p-5 text-warning font-weight-bold">{t("projects-we-did")}</h1>
              <ul className="proj">
                <li className="li-container">
                  <div className="card p-3 text-center d-flex flex-column-reverse flex-lg-row justify-content-between">
                    <div className="text-center text-lg-left m-4 d-flex flex-column">
                      <h1 className="p-2 text-warning font-weight-bold">Hop In (هوب ان)</h1>
                      <h2 className="p-2 text-secondary font-weight-bold">{t("hopIn-desc")}</h2>
                      <div>
                        <div className="text-center text-lg-left pt-2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.snipertech.hopinn"
                            className="btn-get-started"
                          >
                            {t("android-download")}
                            <span>
                              <i className="fa fa-android pl-2" aria-hidden="true"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <img className="card-img-top" src={HopIn} alt="Hop In" />
                      <h5 className="text-secondary mt-3">Hop In (هوب ان)</h5>
                    </div>
                  </div>
                </li>
                <li className="li-container">
                  <div className="card p-3 text-center d-flex flex-column-reverse flex-lg-row justify-content-between">
                    <div className="text-center text-lg-left m-4 d-flex flex-column">
                      <h1 className="p-2 text-warning font-weight-bold">BER Website موقع جمعية البر الخيرية بالجديدة</h1>
                      <h2 className="p-2 text-secondary font-weight-bold">{t("ber-desc")}</h2>
                      <div>
                        <div className="text-center text-lg-left pt-2">
                          <a target="_blank" rel="noreferrer" href="https://albiraljdeedh.org.sa" className="btn-get-started">
                            {t("link")}
                            <span>
                              <i className="fa fa-globe pl-2" aria-hidden="true"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <img className="card-img-top" src={Br} alt="Hop In" />
                      <h5 className="text-secondary">BER Website موقع جمعية البر الخيرية</h5>
                    </div>
                  </div>
                </li>
                <li className="li-container">
                  <div className="card p-3 text-center d-flex flex-column-reverse flex-lg-row justify-content-between">
                    <div className="text-center text-lg-left m-4 d-flex flex-column">
                      <h1 className="p-2 text-warning font-weight-bold">BER Store متجر جمعية البر الخيرية بالجديدة</h1>
                      <h2 className="p-2 text-secondary font-weight-bold">{t("ber-store-desc")}</h2>
                      <div>
                        <div className="text-center text-lg-left pt-2">
                          <a target="_blank" rel="noreferrer" href="https://albraljdeedh.com" className="btn-get-started">
                            {t("link")}
                            <span>
                              <i className="fa fa-globe pl-2" aria-hidden="true"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <img className="card-img-top" src={Ber} alt="Hop In" />
                      <h5 className="text-secondary">BER Store متجر جمعية البر الخيرية</h5>
                    </div>
                  </div>
                </li>
                <li className="li-container">
                  <div className="card p-3 text-center d-flex flex-column-reverse flex-lg-row justify-content-between">
                    <div className="text-center text-lg-left m-4 d-flex flex-column">
                      <h1 className="p-2 text-warning font-weight-bold">Twitter App برنامج تويتر</h1>
                      <h2 className="p-2 text-secondary font-weight-bold">{t("twitter-desc")}</h2>
                    </div>
                    <div className="p-3">
                      <img className="card-img-top" src={Twitter} alt="Hop In" />
                      <h5 className="text-secondary mt-3">Twitter App برنامج تويتر</h5>
                    </div>
                  </div>
                </li>
                <li className="li-container">
                  <div className="card p-3 text-center d-flex flex-column-reverse flex-lg-row justify-content-between">
                    <div className="text-center text-lg-left m-4 d-flex flex-column">
                      <h1 className="p-2 text-warning font-weight-bold">Parental Control منصة التحكم الابوي</h1>
                      <h2 className="p-2 text-secondary font-weight-bold">{t("parental-desc")}</h2>
                      <div className="d-lg-flex">
                        <div className="text-center pr-lg-4 pt-2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://github.com/Abdulaziz-sudo/Platform-Parental-Control"
                            className="btn-get-started"
                          >
                            {t("windows-download")}
                            <span>
                              <i className="fa fa-windows pl-2" aria-hidden="true"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <img className="card-img-top" src={ParentalControl} alt="Hop In" />
                      <h5 className="text-secondary">Parental Control منصة التحكم الابوي</h5>
                    </div>
                  </div>
                </li>
                <li className="li-container">
                  <div className="card p-3 text-center d-flex flex-column-reverse flex-lg-row justify-content-between">
                    <div className="text-center text-lg-left m-4 d-flex flex-column">
                      <h1 className="p-2 text-warning font-weight-bold">CryptoBud (كريبتو بد)</h1>
                      <h2 className="p-2 text-secondary font-weight-bold">{t("crypto-desc")}</h2>
                      <div className="d-lg-flex">
                        <div className="text-center pr-lg-4 pt-2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.snipertech.cryptobud"
                            className="btn-get-started"
                          >
                            {t("android-download")}
                            <span>
                              <i className="fa fa-android pl-2" aria-hidden="true"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <img className="card-img-top" src={CryptoBud} alt="Hop In" />
                      <h5 className="text-secondary mt-3">CryptoBud</h5>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Projects;
