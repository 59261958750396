import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import test from "../Assets/Images/test.svg";

const MaintainWebAppService = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `عز للتحول الرقمي - ${t("maintainance")}`;
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 text-center" data-aos="zoom-in-down">
              <h1 className="p-5 text-warning font-weight-bold">{t("maintainance")}</h1>
            </div>
          </div>
          {t("language") === "en" ? (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-5">
                  <img className="img-fluid" src={test} alt="phone" />
                </div>
                <div className="col-lg-7 text-right">
                  <h4 dir="RTL">
                    <strong>
                      الإنترنت بيئة سريعة التطور وتوقعات عملائك تتغير بسرعة. لكي تظل أنت الأكثر أهمية وتحديثا تحتاج لأن تتقدم
                      بخطوات على منافسيك.« <a href="http://www.ezzdt.com/">عز</a>» تساعدك على أن تبقى الأكثر تحديثا من خلال
                      الخدمات التي تقدمها لصيانة مواقع الإنترنت والتطبيقات.
                    </strong>
                  </h4>
                  <h4 dir="RTL" className="mt-5">
                    <strong>
                      تساعد الصيانة المنتظمة لتكنولوجيا المعلومات على استمرار الأداء السلس للأنظمة والعمليات. كما تمكن من اكتشاف
                      وحل المشكلات قبل نشوئها. نحن نساعدك في الحفاظ على موقعك على الإنترنت في شكل ممتاز ، سواء كان ذلك من خلال
                      ترقيات تقنية أو تغييرات في المحتوى.
                    </strong>
                  </h4>
                  <h4 dir="RTL" className="mt-5">
                    <strong>
                      استثماركم في صيانة تقنية المعلومات يوفر التكاليف التي قد تترتب لاحقا. من ناحية أخرى تحافظ خدمات الصيانة
                      الوقائية على نظام الموقع في أفضل حال و تحسين إنتاجيته بالإضافة إلى زيادة العمر الافتراضي للنظام.
                    </strong>
                  </h4>
                </div>
              </div>
              <br />
              <hr />
            </div>
          ) : (
            <div>
              <div className="row align-items-center my-5">
                <div className="col-lg-7 text-left mt-5">
                  <h4>
                    The Internet is a rapidly evolving environment and the expectations of your customers are changing rapidly. In
                    order to remain the most important and up-to-date you need to take steps over your competitors. “
                    <a href="http://www.ezzdt.com/"> ezz </a>” helps you stay up-to-date through the services you provide for
                    website maintenance. Internet and applications.
                  </h4>
                  <h4 className="mt-5">
                    Regular IT maintenance helps keep systems and processes running smoothly. It is also able to discover and
                    solve problems before they arise. We help you keep your website in great shape, whether that's through
                    technical upgrades or changes to content.
                  </h4>
                  <h4 className="mt-5">
                    Your investment in IT maintenance saves costs that may incur later. On the other hand, preventive maintenance
                    services keep the site system in optimum condition, improve its productivity, as well as increase the life of
                    the system.
                  </h4>
                </div>
                <div className="col-lg-5 order-lg-last order-first">
                  <img className="img-fluid" src={test} alt="phone" />
                </div>
              </div>
              <br />
              <hr />
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="zoom-in">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default MaintainWebAppService;
