import React, { useState, useEffect, useCallback } from "react";
import Fire from "../../Api/Fire";
import ErrorDialog from "../../components/errorDialog";
import ReactQuill from "react-quill";
import SuccessDialog from "../../components/successDialog";
import Collapsible from "react-collapsible";
import { renderFile } from "../../util/methods";
import firebase from "firebase/app";
import { useTranslation } from "react-i18next";

const MessagesList = ({ type }) => {
  const [messages, setMessages] = useState([]);
  const [deletedMessages, setDeletedMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [deletedMessagesLoading, setDeletedMessagesLoading] = useState(true);
  const { t } = useTranslation();

  //get All Announcments
  const getAllMessages = useCallback(async () => {
    setMessagesLoading((loading) => true);
    if (type === 1) {
      const messages = await Fire.getAllMessages(null);
      setMessages(messages);
      setMessagesLoading(false);
    } else {
      const user = await Fire.getUser(Fire.uid);
      const messages = await Fire.getAllMessages(user.department);
      setMessages(messages);
      setMessagesLoading(false);
    }
  }, [type]);

  //get All Announcments
  const getDeletedMessages = useCallback(async () => {
    setDeletedMessagesLoading((loading) => true);
    if (type === 1) {
      const messages = await Fire.getAllDeletedMessages(null);
      setDeletedMessages(messages);
      setDeletedMessagesLoading(false);
    } else {
      const user = await Fire.getUser(Fire.uid);
      const messages = await Fire.getAllDeletedMessages(user.department);
      setDeletedMessages(messages);
      setDeletedMessagesLoading(false);
    }
  }, [type]);

  useEffect(() => {
    document.title = "عز للتحول الرقمي - الرسائل";
    getAllMessages();
    getDeletedMessages();
  }, [getAllMessages, getDeletedMessages]);

  //Hide a message
  const hideMessage = async (message) => {
    setLoading((loading) => true);
    const response = await Fire.updateMessage(message.uid, { hiddenFrom: firebase.firestore.FieldValue.arrayUnion(Fire.uid) });
    if (response) {
      setShowFormSuccess((showFormSuccess) => true);
    } else {
      setError("لقد حصل خطأ");
      setShowForm((showForm) => true);
    }
    setLoading((loading) => false);
    getAllMessages();
    getDeletedMessages();
  };

  //Show a message
  const showMessage = async (message) => {
    setLoading((loading) => true);
    const response = await Fire.updateMessage(message.uid, { hiddenFrom: firebase.firestore.FieldValue.arrayRemove(Fire.uid) });
    if (response) {
      setShowFormSuccess((showFormSuccess) => true);
    } else {
      setError(t("error"));
      setShowForm((showForm) => true);
    }
    setLoading((loading) => false);
    getAllMessages();
    getDeletedMessages();
  };

  return (
    <div className="container bg-white rounded" data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 py-4 text-center border-bottom">
          <h2>
            <b>{t("department-messages")}</b>
          </h2>
          {messagesLoading ? (
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : messages.length === 0 ? (
            <h5 className="py-2">{t("no-messages")}</h5>
          ) : (
            <ul className="list-group text-left">
              {messages.map((message, index) => (
                <li key={index} className="list-group-item list-group-item-action">
                  <Collapsible
                    triggerStyle={{ cursor: "pointer" }}
                    trigger={[
                      `from ${message.userName} • ${message.fromDepartment} to ${message.toDepartment}`,
                      <i className="fa fa-chevron-down pl-2" />,
                    ]}
                  >
                    <div className="text-center">{renderFile(message)}</div>
                    <div className="text-center">
                      {loading ? (
                        <div className="spinner-border text-dark" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button className="btn" onClick={() => hideMessage(message)}>
                          <i className="fa fa-trash delete" />
                        </button>
                      )}
                    </div>

                    <ReactQuill readOnly={true} theme="bubble" value={message.message || ""} />
                  </Collapsible>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="col-lg-12 py-4 text-center border-bottom">
          <h2>
            <b>{t("deleted-messages")}</b>
          </h2>
          {deletedMessagesLoading ? (
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : deletedMessages.length === 0 ? (
            <h5 className="py-2">{t("no-messages")}</h5>
          ) : (
            <ul className="list-group text-left">
              {deletedMessages.map((message, index) => (
                <li key={index} className="list-group-item list-group-item-action">
                  <Collapsible
                    triggerStyle={{ cursor: "pointer" }}
                    trigger={[
                      `from ${message.userName} • ${message.fromDepartment} to ${message.toDepartment}`,
                      <i className="fa fa-chevron-down pl-2" />,
                    ]}
                  >
                    <div className="text-center">{renderFile(message)}</div>
                    <div className="text-center">
                      {loading ? (
                        <div className="spinner-border text-dark" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button className="btn" onClick={() => showMessage(message)}>
                          <i className="fa fa-plus delete text-success" />
                        </button>
                      )}
                    </div>

                    <ReactQuill readOnly={true} theme="bubble" value={message.message || ""} />
                  </Collapsible>
                </li>
              ))}
            </ul>
          )}
        </div>
        {showForm ? (
          <div className="contact-form bg-white rounded shadow">
            <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
          </div>
        ) : null}
        {showFormSuccess ? (
          <div className="contact-form bg-white rounded shadow">
            <SuccessDialog success={t("delete-success")} onClick={() => setShowFormSuccess((showForm) => false)} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MessagesList;
