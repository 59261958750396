import React, { useState, useEffect } from "react";
import Fire from "../../Api/Fire";
import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from "uuid";

const PublicChat = () => {
  const [user, setUser] = useState({});
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    document.title = "عز للتحول الرقمي - المحادثة";
    Fire.getUser(Fire.uid).then((user) => setUser(user));
    const subscriber = Fire.getMessages((messages) => setMessages(messages));
    return () => subscriber();
  }, []);

  //Send Message
  const handleSendText = async () => {
    let message = {
      uid: uuidv4(),
      createdAt: new Date(),
      user: { uid: user.uid, name: `${user.firstName} ${user.lastName}`, avatar: user.image, department: user.department },
      message: text,
      messageType: "text",
    };
    if (!(text.trim().length === 0)) {
      await Fire.sendMessage(message);
      setText((text) => "");
    }
  };

  //Send File
  const handleSendFile = async (file) => {
    const f = await Fire.uploadMedia(`Chat/files/${file.name}`, file, file.type);
    let message = {
      uid: uuidv4(),
      createdAt: new Date(),
      user: { uid: user.uid, name: `${user.firstName} ${user.lastName}`, avatar: user.image },
      message: f,
      messageType: file.type,
    };
    await Fire.sendMessage(message);
  };

  const renderFile = (message) => {
    switch (message.messageType) {
      case "text":
        return <p className="msg">{message.message}</p>;
      case "image/jpeg":
        return (
          <div className="fileMsg">
            <img src={message.message} height={100} width={100} alt="img" />
          </div>
        );
      case "image/jpg":
        return (
          <div className="fileMsg">
            <img src={message.message} height={100} width={100} alt="img" />
          </div>
        );
      case "image/png":
        return (
          <div className="fileMsg">
            <img src={message.message} height={100} width={100} alt="img" />
          </div>
        );
      case "application/pdf":
        return (
          <div className="fileMsg">
            <a href={message.message} target="_blank" rel="noreferrer">
              <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
            </a>
          </div>
        );
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return (
          <div className="fileMsg">
            <a href={message.message} target="_blank" rel="noreferrer">
              <i className="fa fa-file-excel-o" aria-hidden="true"></i>
            </a>
          </div>
        );
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return (
          <div className="fileMsg">
            <a href={message.message} target="_blank" rel="noreferrer">
              <i className="fa fa-file-powerpoint-o" aria-hidden="true"></i>
            </a>
          </div>
        );
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (
          <div className="fileMsg">
            <a href={message.message} target="_blank" rel="noreferrer">
              <i className="fa fa-file-word-o" aria-hidden="true"></i>
            </a>
          </div>
        );
      default:
        <di></di>;
    }
  };

  return (
    <div className="wrapper">
      <div className="main">
        <div className="px-2 scroll">
          <div className="text-center">
            <span className="between">Chat started at {new Date().toDateString()}</span>
          </div>
          {messages.map((message) => {
            if (message.user.uid === Fire.uid) {
              return (
                <div key={message.uid} className="d-flex align-items-center text-right justify-content-end">
                  <div className="pr-2">
                    <span className="name">
                      <b>
                        <span className="text-danger">{message.user.department}</span>
                        {" • "}
                        {message.user.name}
                      </b>
                    </span>
                    {renderFile(message)}
                  </div>
                  <div>
                    <img src={message.user.avatar} width="30" className="img1" alt="avater" />
                  </div>
                </div>
              );
            } else {
              return (
                <div key={message.uid} className="d-flex align-items-center">
                  <div className="text-left pr-1">
                    <img src={message.user.avatar} width="30" className="img1" alt="avater" />
                  </div>
                  <div className="pr-2 pl-1">
                    <span className="name">
                      <b>
                        <span className="text-danger">{message.user.department}</span>
                        {" • "}
                        {message.user.name}
                      </b>
                    </span>
                    {renderFile(message)}
                  </div>
                </div>
              );
            }
          })}
        </div>
        <nav className="navbar bg-white navbar-expand-sm d-flex justify-content-between">
          <input
            type="text number"
            name="text"
            value={text}
            className="form-control"
            placeholder="Type a message..."
            onChange={(event) => setText(event.target.value)}
            onKeyPress={(e) => {
              if (e.key !== "Enter") return;
              handleSendText();
            }}
          />
          <div className="d-flex justify-content-evenly align-items-center text-center ml-2">
            <label htmlFor="file" className="icon1">
              <span className="fa fa-paperclip"></span>
            </label>
            <input
              type="file"
              id="file"
              accept="application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/jpg, image/jpeg, image/png"
              style={{ display: "none" }}
              onChange={(event) => handleSendFile(event.target.files[0])}
            />

            <i className="fa fa-arrow-circle-right icon2" onClick={handleSendText}></i>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default PublicChat;
