import React, { useEffect } from "react";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import question from "../Assets/Images/question.svg";
import programming from "../Assets/Images/programming.svg";
import test from "../Assets/Images/test.svg";
import launch from "../Assets/Images/launch.svg";
import analysis from "../Assets/Images/analysis.svg";
import platform from "../Assets/Images/intro.svg";

const PlatformDesignService = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `عز للتحول الرقمي - ${t("create-platform")}`;
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <main>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-5 pt-lg-0 text-center" data-aos="zoom-in-down">
              <h1 className="p-5 text-warning font-weight-bold">{t("create-platform")}</h1>
            </div>
          </div>
          {t("language") === "en" ? (
            <div>
              <div className="container">
                <div className="row align-items-center my-5">
                  <div className="col-lg-5">
                    <img className="img-fluid" src={question} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-center mt-5">
                    <h3 dir="RTL">
                      <strong>هل لديك فكره مشروع تطبيق رائع وتريد نشره على جميع المنصات في اسرع وقت واقل تكلفة؟</strong>
                    </h3>
                  </div>
                </div>
                <br />
                <br />
                <div className="text-center">
                  <h4 dir="RTL">
                    ان مؤسسة عز للتحول الرقمي تقدم خدمة إنشاء منصة متكاملة من تطبيق أندرويد، أي او اس (ايفون)، و موقع متكامل.
                  </h4>
                </div>
                <br />
                <br />
                <div className="text-center">
                  <h2 dir="RTL" className="title-service">
                    <strong>خطوات انشاء المنصة المتكاملة :</strong>
                  </h2>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-5">
                    <img className="img-fluid" src={analysis} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-right mt-5">
                    <h3 dir="RTL">
                      <strong>تحليل وتخطيط المتطلبات بدقة</strong>
                    </h3>
                    <p dir="RTL" className="mt-5">
                      تّعد هذه المرحلة من أهم مراحل انشاء المنصة ويتم بدقة من خلال فريق من المحللين والمبرمجين المتميزين في عز
                      للتحول الرقمي. تتم مراجعة الخطة المكتملة مع العميل للمراجعة واخذ الموافقة.
                    </p>
                  </div>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-7 text-right mt-5">
                    <h3 dir="RTL">
                      <strong> تصميم المنصة</strong>
                    </h3>
                    <p dir="RTL" className="mt-5">
                      يتكفل فريق من المصممين المحترفين تصميم المنصة (الموقع – تطبيق الأندرويد – أي او اس) بدقة ليكون تصميم حديث
                      وسهل وانيق لكي يستمتع المستخدمين بتجربة رائعة.
                    </p>
                  </div>
                  <div className="col-lg-5 order-first order-lg-last">
                    <img className="img-fluid" src={platform} alt="phone" />
                  </div>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-5 ">
                    <img className="img-fluid" src={programming} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-right mt-5">
                    <h3 dir="RTL">
                      <strong> برمجة المنصة</strong>
                    </h3>
                    <p dir="RTL" className="mt-5">
                      يتم برمجة التطبيق باستخدام خطة scrum وهي برمجة التطبيق و الموقع على دفعات. كل دفعة يضاف عليها المزيد من
                      المزايا وتكون كل دفعة تطبيق و موقع جاهز يمكن نشره اذا أراد العميل ذلك. ويتم الاجتماع مع العميل أسبوعيا
                      لمناقشة التقدم والتعديلات المراد بها لضمان جودة عالية للتطبيق ويكون مطابقا لمواصفات العميل.
                    </p>
                  </div>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-7 text-right mt-5">
                    <h3 dir="RTL">
                      <strong> اختبار المنصة</strong>
                    </h3>
                    <p dir="RTL" className="mt-5">
                      بعد الانتهاء من المنصة يتكفل فريق من مختبرين برمجيات باختبار التطبيق والتأكد من سلامة التطبيق من جميع
                      النواحي وتصليح الأخطاء.
                    </p>
                  </div>
                  <div className="col-lg-5 order-first order-lg-last">
                    <img className="img-fluid" src={test} alt="phone" />
                  </div>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-5 ">
                    <img className="img-fluid" src={launch} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-right mt-5">
                    <h3 dir="RTL">
                      <strong> نشر المنصة</strong>
                    </h3>
                    <p dir="RTL" className="mt-5">
                      يتم نشر المنصة باقل تكلفة في جميع المنصات و يتم متابعة التطبيق والاهتمام به من قبل مبرمجين مختصين
                    </p>
                  </div>
                </div>
                <h2 dir="RTL" className="title-service text-center">
                  <strong>الأسئلة الشائعة عن خدمة تصميم المنصة </strong>
                </h2>
                <h5 className="mt-4 text-right">
                  <strong>سؤال: هل يمكنني تجربة المنصة قبل اطلاقها؟</strong>
                </h5>
                <h5 className="mt-4 text-right">
                  جواب: نعم بالتأكيد، سوف يتم ارسال المنصة اليك أولاً لتجربتها والتأكد من مطابقتها لمواصفاتك قبل اطلاقها.
                </h5>
                <h5 className="mt-4 text-right">
                  <strong>سؤال: هل يمكن التعديل على المنصة بعد اطلاقها؟</strong>
                </h5>
                <h5 className="mt-4 text-right">
                  جواب: نعم، المنصة سوف تكون تحت اشراف فريق من المبرمجين لمتابعة المنصة وإصلاح الأخطاء وإذا هناك أي تعديلات أخرى.
                </h5>
              </div>
            </div>
          ) : (
            <div>
              <div className="container">
                <div className="row align-items-center my-5">
                  <div className="col-lg-5">
                    <img className="img-fluid" src={question} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-center mt-5">
                    <h3>
                      <strong>
                        {" "}
                        Do you have an idea for a great application project and want to publish it on all platforms in the fastest
                        time and least cost?{" "}
                      </strong>
                    </h3>
                  </div>
                </div>
                <br />
                <br />
                <div className="text-center">
                  <h4>
                    The Ezz Foundation for Digital Transformation provides the service of creating an integrated platform from the
                    Android application, iOS (iPhone), and an integrated website.
                  </h4>
                </div>
                <br />
                <br />
                <div className="text-center">
                  <h2 className="title-service">
                    <strong> Steps to create an integrated platform: </strong>
                  </h2>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-5">
                    <img className="img-fluid" src={analysis} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-left mt-5">
                    <h3>
                      <strong> Carefully analyze and plan requirements </strong>
                    </h3>
                    <p className="mt-5">
                      This stage is considered one of the most important stages of establishing the platform and is done strictly
                      by a team of distinguished analysts and programmers at Ezz For digital transformation. The completed plan is
                      reviewed with the customer for review and approval.
                    </p>
                  </div>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-7 text-left mt-5">
                    <h3>
                      <strong> Platform Design </strong>
                    </h3>
                    <p className="mt-5">
                      A team of professional designers takes care of accurately designing the platform (website - Android app -
                      iOS) to be a modern design. Easy and elegant for users to enjoy a wonderful experience.
                    </p>
                  </div>
                  <div className="col-lg-5 order-first order-lg-last">
                    <img className="img-fluid" src={platform} alt="phone" />
                  </div>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-5 ">
                    <img className="img-fluid" src={programming} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-left mt-5">
                    <h3>
                      <strong> Platform Programming </strong>
                    </h3>
                    <p className="mt-5">
                      The application is programmed using a scrum plan which is batch programming of the application and the site.
                      More is added to each batch Advantages and each batch is an application and a ready-made website can be
                      published if the customer so desires. The meeting with the client takes place weekly To discuss the progress
                      and modifications required to ensure a high quality of the application and conform to the customer's
                      specifications.
                    </p>
                  </div>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-7 text-left mt-5">
                    <h3>
                      <strong> Platform Testing </strong>
                    </h3>
                    <p className="mt-5">
                      After completing the platform, a team of software testers will test the application and ensure the safety of
                      all the application Respects and bug fixes.
                    </p>
                  </div>
                  <div className="col-lg-5 order-first order-lg-last">
                    <img className="img-fluid" src={test} alt="phone" />
                  </div>
                </div>

                <div className="row align-items-center my-5">
                  <div className="col-lg-5">
                    <img className="img-fluid" src={launch} alt="phone" />
                  </div>
                  <div className="col-lg-7 text-left mt-5">
                    <h3>
                      <strong> Publish Platform </strong>
                    </h3>
                    <p className="mt-5">
                      The platform is deployed at the lowest cost in all platforms, and the application is followed up and taken
                      care of by specialized programmers
                    </p>
                  </div>
                </div>
                <h2 className="title-service text-center">
                  <strong> Platform Design Service FAQ </strong>
                </h2>
                <h5 className="mt-4 text-left">
                  <strong> Question: Can I test the platform before launching it? </strong>
                </h5>
                <h5 className="mt-4 text-left">
                  Answer: Yes, of course, the platform will be sent to you first to test it and make sure that it matches your
                  specifications before it is launched.
                </h5>
                <h5 className="mt-4 text-left">
                  <strong> Question: Can the platform be modified after its launch? </strong>
                </h5>
                <h5 className="mt-4 text-left">
                  Answer: Yes, the platform will be under the supervision of a team of programmers to follow the platform and fix
                  errors and if there are any other modifications.
                </h5>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="zoom-in">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PlatformDesignService;
