import React, { useState, useEffect } from "react";
import Zaki from "../Assets/Images/ZakiBrahmi.JPG";
import rashedImage from "../Assets/Images/Rashed.jpg";
import Abdulaziz from "../Assets/Images/Abdulaziz.jpeg";
import maleAvatar from "../Assets/Images/male_avatar.svg";
import femaleAvatar from "../Assets/Images/female_avatar.svg";
import Crossplatform from "../Assets/Images/Cross-Platfrom.svg";
import Consult from "../Assets/Images/plan.svg";
import Android from "../Assets/Images/Android.svg";
import Web from "../Assets/Images/page.svg";
import market from "../Assets/Images/business.svg";
import projectPlan from "../Assets/Images/projectPlan.svg";
import FixImage from "../Assets/Images/fix.svg";
import webDesign from "../Assets/Images/web_design.svg";
import { Link } from "react-router-dom";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import ServiceCard from "../components/ServiceCard";

const LandingPage = () => {
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي | Ezz for digital services";
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <div className="container">
        <div id="landing" className="row">
          <div
            dir={t("language") === "en" ? "rtl" : "ltr"}
            className={`col-lg-7 text-center align-self-center ${
              t("language") === "en" ? "order-last text-lg-right" : "order-last order-lg-first text-lg-left"
            }`}
          >
            <h1 data-aos="zoom-out">
              <span>{t("success")}</span>
            </h1>
            <h2 data-aos="zoom-out">{t("intro")}</h2>
            <div data-aos="zoom-out" className="d-flex flex-column flex-lg-row">
              <div className="text-center pr-lg-2 mb-5 mb-lg-0">
                <Link to="" className="btn-get-started" onClick={() => setShowForm((showForm) => !showForm)}>
                  {t("startProject")}
                </Link>
              </div>
              <div className="text-center px-lg-2">
                <Link to="/services" className="btn-get-started">
                  {t("services")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mb-5 mb-lg-0" data-aos="zoom-out">
            <iframe
              src="https://player.vimeo.com/video/529458079?autoplay=1"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />
          </div>
        </div>

        <div id="services-container" className="row">
          <div className="col-lg-12 pt-5 text-center align-items-center">
            <div>
              <h1 data-aos="fade-down" className="p-5 text-warning font-weight-bold">
                {t("services")}
              </h1>
              <ul className="services-list">
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("create-mobile")}
                    text={t("mobile-text-landing")}
                    icon={Android}
                    route="/services/appDesignService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("create-web")}
                    text={t("web-text-landing")}
                    icon={Web}
                    route="/services/webDesignService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("create-platform")}
                    text={t("platform-text-landing")}
                    icon={Crossplatform}
                    route="/services/platformDesignService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("maintainance")}
                    text={t("maintainance-text-landing")}
                    icon={FixImage}
                    route="/services/maintainWebAppService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("market-identity")}
                    text={t("market-text-landing")}
                    icon={market}
                    route="/services/businessDesignService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("consult")}
                    text={t("consult-text-landing")}
                    icon={Consult}
                    route="/services/consultService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("project-study")}
                    text={t("project-text-landing")}
                    icon={projectPlan}
                    route="/services/projectStudyService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("e-commerce-design")}
                    text={t("commerce-text-landing")}
                    icon={webDesign}
                    route="/services/ecommerceService"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row text-center text-warning mt-5" data-aos="fade-up">
          <div className="col-lg-12 pt-5">
            <h1>
              <b> {t("comments")} </b>
            </h1>
          </div>
        </div>

        <div data-aos="fade-up" className="testimonial text-center mt-5">
          <div
            id="testimonial4"
            className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
            data-ride="carousel"
            data-pause="hover"
            data-interval="5000"
            data-duration="2000"
          >
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="testimonial4_slide">
                  <div className="row">
                    <div className="col-lg-8 mx-auto">
                      <blockquote className="blockquote text-center blockquote-custom bg-light p-5 shadow rounded">
                        <div className="blockquote-custom-icon bg-warning shadow-sm">
                          <i className="fa fa-quote-left text-white"></i>
                        </div>
                        <p className="mb-0 mt-2 font-italic text-secondary">
                          "انتم بجد ناس يرتاح الشخص معهم ولي الشرف بمعرفتكم، شركة محترفة و متعاونة مع العميل الى اكبر حدد وإن شاء
                          الله اي مشروع ثاني يكون عندكم"
                        </p>
                        <footer className="blockquote-footer pt-4 mt-4 border-top">
                          <cite className="pl-1 text-warning" title="Source Title">
                            صالح محمد البلوي
                          </cite>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="testimonial4_slide">
                  <div className="row">
                    <div className="col-lg-8 mx-auto">
                      <blockquote className="blockquote blockquote-custom bg-light p-5 shadow rounded">
                        <div className="blockquote-custom-icon bg-warning shadow-sm">
                          <i className="fa fa-quote-left text-white"></i>
                        </div>
                        <p className="mb-0 mt-2 font-italic text-secondary">
                          "من افضل المؤسسات تعاملا و تنظيمياً، تم تسيلم المشروع بالوقت المحدد و صيانة المشروع أول بأول. شكرأ لكم"
                        </p>
                        <footer className="blockquote-footer pt-4 mt-4 border-top">
                          <cite className="pl-1 text-warning" title="Source Title">
                            سليمان البلوي
                          </cite>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#testimonial4" data-slide="prev">
              <div className="control">
                <span className="carousel-control-prev-icon"></span>
              </div>
            </a>
            <a className="carousel-control-next" href="#testimonial4" data-slide="next">
              <div className="control">
                <span className="carousel-control-next-icon"></span>
              </div>
            </a>
          </div>
        </div>

        <div className="row text-center text-warning" data-aos="fade-up">
          <div className="col-lg-12 pt-3">
            <h1>
              <b>{t("team")} </b>
            </h1>
          </div>
        </div>

        <div data-aos="fade-up" className="testimonial text-center mt-5">
          <div
            id="testimonial5"
            className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
            data-ride="carousel"
            data-pause="hover"
            data-interval="5000"
            data-duration="2000"
          >
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="testimonial4_slide">
                  <img src={Abdulaziz} className="img-circle img-responsive" alt="ss" />
                  <p className="text-secondary">The first step is always the hardest, stop thinking about it and just do it.</p>
                  <h4>Abdulaziz Aljohani</h4>
                  <h5>Co-founder & CEO</h5>
                </div>
              </div>
              <div className="carousel-item">
                <div className="testimonial4_slide">
                  <img src={rashedImage} className="img-circle img-responsive" alt="ss" />
                  <p className="text-secondary">
                    Work for the better and never stop. There are no limits that you can't overcome!
                  </p>
                  <h4>Rashed Mahyoub</h4>
                  <h5>Co-founder & CTO</h5>
                </div>
              </div>
              <div className="carousel-item">
                <div className="testimonial4_slide">
                  <img src={Zaki} className="img-circle img-responsive" alt="ss" />
                  <p className="text-secondary">Never lose hope. Move forward towards success.</p>
                  <h4>Zaki Brahmi</h4>
                  <h5>Technical Consultant & Back-end Developer</h5>
                </div>
              </div>
              <div className="carousel-item">
                <div className="testimonial4_slide">
                  <img src={maleAvatar} className="img-circle img-responsive" alt="ss" />
                  <p className="text-secondary">You have to be passionate in what you do.</p>
                  <h4>Fahad Hakim</h4>
                  <h5>Web Developer</h5>
                </div>
              </div>
              <div className="carousel-item">
                <div className="testimonial4_slide">
                  <img src={femaleAvatar} className="img-circle img-responsive" alt="ss" />
                  <p className="text-secondary">Try to improve each day. It is never too late</p>
                  <h4>Samah Omar</h4>
                  <h5>Android Developer</h5>
                </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#testimonial5" data-slide="prev">
              <div className="control">
                <span className="carousel-control-prev-icon"></span>
              </div>
            </a>
            <a className="carousel-control-next" href="#testimonial5" data-slide="next">
              <div className="control">
                <span className="carousel-control-next-icon"></span>
              </div>
            </a>
          </div>
        </div>

        {showForm ? (
          <div className="contact-form bg-white rounded shadow">
            <ContactForm onClick={() => setShowForm((showForm) => !showForm)} />
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default LandingPage;
