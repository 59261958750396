import React, { useState, useEffect } from "react";
import Home from "./Home";
import AddBlog from "../AddBlog";
import BlogsList from "../BlogsList";
import EmployeeDetails from "./EmployeeDetails";
import AddVacationRequest from "./AddVacationRequest";
import useProtectedRoute from "../../util/useProtectedRoute";
import SendMessage from "./SendMessage";
import MessagesList from "../Admin/MessagesList";
import Fire from "../../Api/Fire";
import { useTranslation } from "react-i18next";

const AdminMain = () => {
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  useProtectedRoute();

  useEffect(() => {
    Fire.getUser(Fire.uid).then((user) => setUser(user));
  }, []);

  const getPage = () => {
    switch (page) {
      case 1:
        return <Home />;
      case 2:
        return user != null ? user.department === "Designer" ? <BlogsList type={1} /> : null : null;
      case 3:
        return user != null ? user.department === "Designer" ? <AddBlog /> : null : null;
      case 4:
        return <EmployeeDetails />;
      case 5:
        return <AddVacationRequest />;
      case 6:
        return <MessagesList type={2} />;
      case 7:
        return <SendMessage />;
      default:
        return <Home />;
    }
  };

  return (
    <main>
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-3" data-aos="zoom-in">
            <div className="list-group">
              <button onClick={() => setPage(1)} className="list-group-item list-group-item-action">
                {t("main")}
              </button>
              <button onClick={() => setPage(4)} className="list-group-item list-group-item-action">
                {t("profile")}
              </button>
              {user != null ? (
                user.department === "Designer" ? (
                  <button onClick={() => setPage(2)} className="list-group-item list-group-item-action">
                    {t("blog")}
                  </button>
                ) : null
              ) : null}
              {user != null ? (
                user.department === "Designer" ? (
                  <button onClick={() => setPage(3)} className="list-group-item list-group-item-action">
                    {t("add-blog")}
                  </button>
                ) : null
              ) : null}

              <button onClick={() => setPage(5)} className="list-group-item list-group-item-action">
                {t("add-vacation-requests")}
              </button>
              <button onClick={() => setPage(6)} className="list-group-item list-group-item-action">
                {t("department-messages")}
              </button>
              <button onClick={() => setPage(7)} className="list-group-item list-group-item-action">
                {t("send-message")}
              </button>
            </div>
          </div>
          <div className="col-lg-9 mt-4 mt-lg-0" data-aos="zoom-in">
            {getPage()}
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdminMain;
