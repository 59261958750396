export const goToWhatsApp = () => {
  window.open("https://api.whatsapp.com/message/SFSFICINXKYEJ1", "_blank");
};

export const renderFile = (message) => {
  switch (message.messageType) {
    case "image/jpeg":
      return (
        <div>
          <a href={message.file} target="_blank" rel="noreferrer" download>
            <i className="fa fa-picture-o h2" aria-hidden="true"></i>
            <p>تحميل</p>
          </a>
        </div>
      );
    case "image/jpg":
      return (
        <div>
          <a href={message.file} target="_blank" rel="noreferrer" download>
            <i className="fa fa-picture-o h2" aria-hidden="true"></i>
            <p>تحميل</p>
          </a>
        </div>
      );
    case "image/png":
      return (
        <div>
          <a href={message.file} target="_blank" rel="noreferrer" download>
            <i className="fa fa-picture-o h2" aria-hidden="true"></i>
            <p>تحميل</p>
          </a>
        </div>
      );
    case "application/pdf":
      return (
        <div>
          <a href={message.file} target="_blank" rel="noreferrer" download>
            <i className="fa fa-file-pdf-o h2" aria-hidden="true"></i>
            <p>تحميل</p>
          </a>
        </div>
      );
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return (
        <div>
          <a href={message.file} target="_blank" rel="noreferrer" download>
            <i className="fa fa-file-excel-o h2" aria-hidden="true"></i>
            <p>تحميل</p>
          </a>
        </div>
      );
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return (
        <div>
          <a href={message.file} target="_blank" rel="noreferrer" download>
            <i className="fa fa-file-powerpoint-o h2" aria-hidden="true"></i>
            <p>تحميل</p>
          </a>
        </div>
      );
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return (
        <div>
          <a href={message.file} target="_blank" rel="noreferrer" download>
            <i className="fa fa-file-word-o h2" aria-hidden="true"></i>
            <p>تحميل</p>
          </a>
        </div>
      );
    default:
      <di></di>;
  }
};
