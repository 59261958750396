import React, { useEffect } from "react";
import Crossplatform from "../Assets/Images/Cross-Platfrom.svg";
import Consult from "../Assets/Images/plan.svg";
import Android from "../Assets/Images/Android.svg";
import Web from "../Assets/Images/page.svg";
import market from "../Assets/Images/business.svg";
import projectPlan from "../Assets/Images/projectPlan.svg";
import FixImage from "../Assets/Images/fix.svg";
import webDesign from "../Assets/Images/web_design.svg";
import ContactForm from "../components/contactform";
import { useTranslation } from "react-i18next";
import ServiceCard from "../components/ServiceCard";

const Services = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - خدماتنا";
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <div className="container" id="services">
        <div className="row">
          <div className="col-lg-12 pt-5 text-center align-items-center">
            <div data-aos="zoom-in">
              <h1 className="p-5 text-warning font-weight-bold">{t("services")}</h1>
              <ul className="num-skills">
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("create-mobile")}
                    text={t("mobile-text")}
                    icon={Android}
                    route="/services/appDesignService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard title={t("create-web")} text={t("web-text")} icon={Web} route="/services/webDesignService" />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("create-platform")}
                    text={t("platform-text")}
                    icon={Crossplatform}
                    route="/services/platformDesignService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("maintainance")}
                    text={t("maintainance-text")}
                    icon={FixImage}
                    route="/services/maintainWebAppService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("market-identity")}
                    text={t("market-text")}
                    icon={market}
                    route="/services/businessDesignService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard title={t("consult")} text={t("consult-text")} icon={Consult} route="/services/consultService" />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("project-study")}
                    text={t("project-text")}
                    icon={projectPlan}
                    route="/services/projectStudyService"
                  />
                </li>
                <li data-aos="fade-up" className="li-container">
                  <ServiceCard
                    title={t("e-commerce-design")}
                    text={t("commerce-text")}
                    icon={webDesign}
                    route="/services/ecommerceService"
                  />
                </li>
              </ul>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Services;
