const countries = [
  {
    id: "AD",
    name: "أندورا",
  },
  {
    id: "AE",
    name: "الامارات العربية المتحدة",
  },
  {
    id: "AF",
    name: "أفغانستان",
  },
  {
    id: "AG",
    name: "أنتيجوا وبربودا",
  },
  {
    id: "AI",
    name: "أنجويلا",
  },
  {
    id: "AL",
    name: "ألبانيا",
  },
  {
    id: "AM",
    name: "أرمينيا",
  },
  {
    id: "AO",
    name: "أنجولا",
  },
  {
    id: "AQ",
    name: "القطب الجنوبي",
  },
  {
    id: "AR",
    name: "الأرجنتين",
  },
  {
    id: "AS",
    name: "ساموا الأمريكية",
  },
  {
    id: "AT",
    name: "النمسا",
  },
  {
    id: "AU",
    name: "أستراليا",
  },
  {
    id: "AW",
    name: "آروبا",
  },
  {
    id: "AX",
    name: "جزر أولان",
  },
  {
    id: "AZ",
    name: "أذربيجان",
  },
  {
    id: "BA",
    name: "البوسنة والهرسك",
  },
  {
    id: "BB",
    name: "بربادوس",
  },
  {
    id: "BD",
    name: "بنجلاديش",
  },
  {
    id: "BE",
    name: "بلجيكا",
  },
  {
    id: "BF",
    name: "بوركينا فاسو",
  },
  {
    id: "BG",
    name: "بلغاريا",
  },
  {
    id: "BH",
    name: "البحرين",
  },
  {
    id: "BI",
    name: "بوروندي",
  },
  {
    id: "BJ",
    name: "بنين",
  },
  {
    id: "BL",
    name: "سان بارتيلمي",
  },
  {
    id: "BM",
    name: "برمودا",
  },
  {
    id: "BN",
    name: "بروناي",
  },
  {
    id: "BO",
    name: "بوليفيا",
  },
  {
    id: "BQ",
    name: "بونير",
  },
  {
    id: "BR",
    name: "البرازيل",
  },
  {
    id: "BS",
    name: "الباهاما",
  },
  {
    id: "BT",
    name: "بوتان",
  },
  {
    id: "BV",
    name: "جزيرة بوفيه",
  },
  {
    id: "BW",
    name: "بتسوانا",
  },
  {
    id: "BY",
    name: "روسيا البيضاء",
  },
  {
    id: "BZ",
    name: "بليز",
  },
  {
    id: "CA",
    name: "كندا",
  },
  {
    id: "CC",
    name: "جزر كوكوس",
  },
  {
    id: "CD",
    name: "جمهورية الكونغو الديمقراطية",
  },
  {
    id: "CF",
    name: "جمهورية افريقيا الوسطى",
  },
  {
    id: "CG",
    name: "الكونغو - برازافيل",
  },
  {
    id: "CH",
    name: "سويسرا",
  },
  {
    id: "CI",
    name: "ساحل العاج",
  },
  {
    id: "CK",
    name: "جزر كوك",
  },
  {
    id: "CL",
    name: "شيلي",
  },
  {
    id: "CM",
    name: "الكاميرون",
  },
  {
    id: "CN",
    name: "الصين",
  },
  {
    id: "CO",
    name: "كولومبيا",
  },
  {
    id: "CR",
    name: "كوستاريكا",
  },
  {
    id: "CU",
    name: "كوبا",
  },
  {
    id: "CV",
    name: "الرأس الأخضر",
  },
  {
    id: "CW",
    name: "كوراساو",
  },
  {
    id: "CX",
    name: "جزيرة الكريسماس",
  },
  {
    id: "CY",
    name: "قبرص",
  },
  {
    id: "CZ",
    name: "جمهورية التشيك",
  },
  {
    id: "DE",
    name: "ألمانيا",
  },
  {
    id: "DJ",
    name: "جيبوتي",
  },
  {
    id: "DK",
    name: "الدانمرك",
  },
  {
    id: "DM",
    name: "دومينيكا",
  },
  {
    id: "DO",
    name: "جمهورية الدومينيك",
  },
  {
    id: "DZ",
    name: "الجزائر",
  },
  {
    id: "EC",
    name: "الاكوادور",
  },
  {
    id: "EE",
    name: "استونيا",
  },
  {
    id: "EG",
    name: "مصر",
  },
  {
    id: "EH",
    name: "الصحراء الغربية",
  },
  {
    id: "ER",
    name: "اريتريا",
  },
  {
    id: "ES",
    name: "أسبانيا",
  },
  {
    id: "ET",
    name: "اثيوبيا",
  },
  {
    id: "FI",
    name: "فنلندا",
  },
  {
    id: "FJ",
    name: "فيجي",
  },
  {
    id: "FK",
    name: "جزر فوكلاند",
  },
  {
    id: "FM",
    name: "ميكرونيزيا",
  },
  {
    id: "FO",
    name: "جزر فارو",
  },
  {
    id: "FR",
    name: "فرنسا",
  },
  {
    id: "GA",
    name: "الجابون",
  },
  {
    id: "GB",
    name: "المملكة المتحدة",
  },
  {
    id: "GD",
    name: "جرينادا",
  },
  {
    id: "GE",
    name: "جورجيا",
  },
  {
    id: "GF",
    name: "غويانا",
  },
  {
    id: "GG",
    name: "غيرنزي",
  },
  {
    id: "GH",
    name: "غانا",
  },
  {
    id: "GI",
    name: "جبل طارق",
  },
  {
    id: "GL",
    name: "جرينلاند",
  },
  {
    id: "GM",
    name: "غامبيا",
  },
  {
    id: "GN",
    name: "غينيا",
  },
  {
    id: "GP",
    name: "جوادلوب",
  },
  {
    id: "GQ",
    name: "غينيا الاستوائية",
  },
  {
    id: "GR",
    name: "اليونان",
  },
  {
    id: "GS",
    name: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  },
  {
    id: "GT",
    name: "جواتيمالا",
  },
  {
    id: "GU",
    name: "جوام",
  },
  {
    id: "GW",
    name: "غينيا بيساو",
  },
  {
    id: "GY",
    name: "غيانا",
  },
  {
    id: "HK",
    name: "هونج كونج الصينية",
  },
  {
    id: "HM",
    name: "جزيرة هيرد وماكدونالد",
  },
  {
    id: "HN",
    name: "هندوراس",
  },
  {
    id: "HR",
    name: "كرواتيا",
  },
  {
    id: "HT",
    name: "هايتي",
  },
  {
    id: "HU",
    name: "المجر",
  },
  {
    id: "ID",
    name: "اندونيسيا",
  },
  {
    id: "IE",
    name: "أيرلندا",
  },
  {
    id: "IM",
    name: "جزيرة مان",
  },
  {
    id: "IN",
    name: "الهند",
  },
  {
    id: "IO",
    name: "المحيط الهندي البريطاني",
  },
  {
    id: "IQ",
    name: "العراق",
  },
  {
    id: "IR",
    name: "ايران",
  },
  {
    id: "IS",
    name: "أيسلندا",
  },
  {
    id: "IT",
    name: "ايطاليا",
  },
  {
    id: "JE",
    name: "جيرسي",
  },
  {
    id: "JM",
    name: "جامايكا",
  },
  {
    id: "JO",
    name: "الأردن",
  },
  {
    id: "JP",
    name: "اليابان",
  },
  {
    id: "KE",
    name: "كينيا",
  },
  {
    id: "KG",
    name: "قرغيزستان",
  },
  {
    id: "KH",
    name: "كمبوديا",
  },
  {
    id: "KI",
    name: "كيريباتي",
  },
  {
    id: "KM",
    name: "جزر القمر",
  },
  {
    id: "KN",
    name: "سانت كيتس ونيفيس",
  },
  {
    id: "KP",
    name: "كوريا الشمالية",
  },
  {
    id: "KR",
    name: "كوريا الجنوبية",
  },
  {
    id: "KW",
    name: "الكويت",
  },
  {
    id: "KY",
    name: "جزر الكايمن",
  },
  {
    id: "KZ",
    name: "كازاخستان",
  },
  {
    id: "LA",
    name: "لاوس",
  },
  {
    id: "LB",
    name: "لبنان",
  },
  {
    id: "LC",
    name: "سانت لوسيا",
  },
  {
    id: "LI",
    name: "ليختنشتاين",
  },
  {
    id: "LK",
    name: "سريلانكا",
  },
  {
    id: "LR",
    name: "ليبيريا",
  },
  {
    id: "LS",
    name: "ليسوتو",
  },
  {
    id: "LT",
    name: "ليتوانيا",
  },
  {
    id: "LU",
    name: "لوكسمبورج",
  },
  {
    id: "LV",
    name: "لاتفيا",
  },
  {
    id: "LY",
    name: "ليبيا",
  },
  {
    id: "MA",
    name: "المغرب",
  },
  {
    id: "MC",
    name: "موناكو",
  },
  {
    id: "MD",
    name: "مولدافيا",
  },
  {
    id: "ME",
    name: "الجبل الأسود",
  },
  {
    id: "MF",
    name: "سانت مارتين",
  },
  {
    id: "MG",
    name: "مدغشقر",
  },
  {
    id: "MH",
    name: "جزر المارشال",
  },
  {
    id: "MK",
    name: "مقدونيا",
  },
  {
    id: "ML",
    name: "مالي",
  },
  {
    id: "MM",
    name: "ميانمار",
  },
  {
    id: "MN",
    name: "منغوليا",
  },
  {
    id: "MO",
    name: "ماكاو الصينية",
  },
  {
    id: "MP",
    name: "جزر ماريانا الشمالية",
  },
  {
    id: "MQ",
    name: "مارتينيك",
  },
  {
    id: "MR",
    name: "موريتانيا",
  },
  {
    id: "MS",
    name: "مونتسرات",
  },
  {
    id: "MT",
    name: "مالطا",
  },
  {
    id: "MU",
    name: "موريشيوس",
  },
  {
    id: "MV",
    name: "جزر الملديف",
  },
  {
    id: "MW",
    name: "ملاوي",
  },
  {
    id: "MX",
    name: "المكسيك",
  },
  {
    id: "MY",
    name: "ماليزيا",
  },
  {
    id: "MZ",
    name: "موزمبيق",
  },
  {
    id: "NA",
    name: "ناميبيا",
  },
  {
    id: "NC",
    name: "كاليدونيا الجديدة",
  },
  {
    id: "NE",
    name: "النيجر",
  },
  {
    id: "NF",
    name: "جزيرة نورفوك",
  },
  {
    id: "NG",
    name: "نيجيريا",
  },
  {
    id: "NI",
    name: "نيكاراجوا",
  },
  {
    id: "NL",
    name: "هولندا",
  },
  {
    id: "NO",
    name: "النرويج",
  },
  {
    id: "NP",
    name: "نيبال",
  },
  {
    id: "NR",
    name: "نورو",
  },
  {
    id: "NU",
    name: "نيوي",
  },
  {
    id: "NZ",
    name: "نيوزيلاندا",
  },
  {
    id: "OM",
    name: "عمان",
  },
  {
    id: "PA",
    name: "بنما",
  },
  {
    id: "PE",
    name: "بيرو",
  },
  {
    id: "PF",
    name: "بولينيزيا الفرنسية",
  },
  {
    id: "PG",
    name: "بابوا غينيا الجديدة",
  },
  {
    id: "PH",
    name: "الفيلبين",
  },
  {
    id: "PK",
    name: "باكستان",
  },
  {
    id: "PL",
    name: "بولندا",
  },
  {
    id: "PM",
    name: "سانت بيير وميكولون",
  },
  {
    id: "PN",
    name: "بتكايرن",
  },
  {
    id: "PR",
    name: "بورتوريكو",
  },
  {
    id: "PS",
    name: "فلسطين",
  },
  {
    id: "PT",
    name: "البرتغال",
  },
  {
    id: "PW",
    name: "بالاو",
  },
  {
    id: "PY",
    name: "باراجواي",
  },
  {
    id: "QA",
    name: "قطر",
  },
  {
    id: "RE",
    name: "روينيون",
  },
  {
    id: "RO",
    name: "رومانيا",
  },
  {
    id: "RS",
    name: "صربيا",
  },
  {
    id: "RU",
    name: "روسيا",
  },
  {
    id: "RW",
    name: "رواندا",
  },
  {
    id: "SA",
    name: "المملكة العربية السعودية",
  },
  {
    id: "SB",
    name: "جزر سليمان",
  },
  {
    id: "SC",
    name: "سيشل",
  },
  {
    id: "SD",
    name: "السودان",
  },
  {
    id: "SE",
    name: "السويد",
  },
  {
    id: "SG",
    name: "سنغافورة",
  },
  {
    id: "SH",
    name: "سانت هيلنا",
  },
  {
    id: "SI",
    name: "سلوفينيا",
  },
  {
    id: "SJ",
    name: "سفالبارد وجان مايان",
  },
  {
    id: "SK",
    name: "سلوفاكيا",
  },
  {
    id: "SL",
    name: "سيراليون",
  },
  {
    id: "SM",
    name: "سان مارينو",
  },
  {
    id: "SN",
    name: "السنغال",
  },
  {
    id: "SO",
    name: "الصومال",
  },
  {
    id: "SR",
    name: "سورينام",
  },
  {
    id: "SS",
    name: "جنوب السودان",
  },
  {
    id: "ST",
    name: "ساو تومي وبرينسيبي",
  },
  {
    id: "SV",
    name: "السلفادور",
  },
  {
    id: "SX",
    name: "سينت مارتن",
  },
  {
    id: "SY",
    name: "سوريا",
  },
  {
    id: "SZ",
    name: "سوازيلاند",
  },
  {
    id: "TC",
    name: "جزر الترك وجايكوس",
  },
  {
    id: "TD",
    name: "تشاد",
  },
  {
    id: "TF",
    name: "المقاطعات الجنوبية الفرنسية",
  },
  {
    id: "TG",
    name: "توجو",
  },
  {
    id: "TH",
    name: "تايلند",
  },
  {
    id: "TJ",
    name: "طاجكستان",
  },
  {
    id: "TK",
    name: "توكيلو",
  },
  {
    id: "TL",
    name: "تيمور الشرقية",
  },
  {
    id: "TM",
    name: "تركمانستان",
  },
  {
    id: "TN",
    name: "تونس",
  },
  {
    id: "TO",
    name: "تونجا",
  },
  {
    id: "TR",
    name: "تركيا",
  },
  {
    id: "TT",
    name: "ترينيداد وتوباغو",
  },
  {
    id: "TV",
    name: "توفالو",
  },
  {
    id: "TW",
    name: "تايوان",
  },
  {
    id: "TZ",
    name: "تانزانيا",
  },
  {
    id: "UA",
    name: "أوكرانيا",
  },
  {
    id: "UG",
    name: "أوغندا",
  },
  {
    id: "UM",
    name: "جزر الولايات المتحدة البعيدة الصغيرة",
  },
  {
    id: "US",
    name: "الولايات المتحدة الأمريكية",
  },
  {
    id: "UY",
    name: "أورجواي",
  },
  {
    id: "UZ",
    name: "أوزبكستان",
  },
  {
    id: "VA",
    name: "الفاتيكان",
  },
  {
    id: "VC",
    name: "سانت فنسنت وغرنادين",
  },
  {
    id: "VE",
    name: "فنزويلا",
  },
  {
    id: "VG",
    name: "جزر فرجين البريطانية",
  },
  {
    id: "VI",
    name: "جزر فرجين الأمريكية",
  },
  {
    id: "VN",
    name: "فيتنام",
  },
  {
    id: "VU",
    name: "فانواتو",
  },
  {
    id: "WF",
    name: "جزر والس وفوتونا",
  },
  {
    id: "WS",
    name: "ساموا",
  },
  {
    id: "XK",
    name: "كوسوفو",
  },
  {
    id: "YE",
    name: "اليمن",
  },
  {
    id: "YT",
    name: "مايوت",
  },
  {
    id: "ZA",
    name: "جمهورية جنوب افريقيا",
  },
  {
    id: "ZM",
    name: "زامبيا",
  },
  {
    id: "ZW",
    name: "زيمبابوي",
  },
];

export default countries;
