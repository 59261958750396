import React, { useState, useEffect } from "react";
import Fire from "../../Api/Fire";
import ErrorDialog from "../../components/errorDialog";
import SuccessDialog from "../../components/successDialog";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Searchable from "react-searchable-dropdown";
import { useTranslation } from "react-i18next";

const AddTask = () => {
  const [error, setError] = useState("خطأ");
  const [showForm, setShowForm] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [user, setUser] = useState("");
  const [urgency, setUrgency] = useState("Normal");
  const [employees, setEmolpyees] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "عز للتحول الرقمي - ارسال مهمة";
    //Get List of Employees
    Fire.getAllUsers(2).then((users) => setEmolpyees(users));
  }, []);

  //Publish Announcement to team.
  const addTask = async (event) => {
    setLoading((loading) => true);
    event.preventDefault();
    if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0 || user.trim().length === 0) {
      setError((error) => t("fill"));
      setShowForm((showForm) => true);
      setLoading((loading) => false);
    } else {
      const response = await Fire.addEmployeeTask({ userId: user, type: urgency, message: value, state: "لم يتم الإنهاء بعد" });
      if (response) {
        setValue((value) => "");
        setShowFormSuccess((showFormSuccess) => true);
      } else {
        setError((error) => response);
        setShowForm((showForm) => true);
      }
      setLoading((loading) => false);
    }
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };

  const format = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "align",
    "script",
    "background",
  ];

  return (
    <div className="container bg-white rounded" data-aos="zoom-in">
      <div className="row">
        <div className="col-lg-12 py-4 text-center">
          <h4>
            <b>{t("send-task")}</b>
          </h4>
          <Searchable
            value=""
            placeholder={t("search")}
            notFoundText={t("no-employee")}
            options={employees}
            onSelect={(option) => setUser((u) => option.value)}
            listMaxHeight={200}
          />
          <div className="text-center mt-3">
            <Searchable
              value=""
              placeholder={t("choose-importance")}
              notFoundText={t("no-importance")}
              options={[
                { label: "Normal", value: "text-success" },
                { label: "Important", value: "text-warning" },
                { label: "Urgency", value: "text-danger" },
              ]}
              onSelect={(option) => setUrgency((u) => option)}
              listMaxHeight={200}
            />
          </div>

          <ReactQuill className="py-3" theme="snow" value={value} onChange={setValue} modules={modules} formats={format} />
          {loading ? (
            <div className="text-center mt-3">
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="form-group m-0">
              <button type="button" className="btn btn-warning btn-block text-light mt-3" onClick={(event) => addTask(event)}>
                {t("post")}
              </button>
            </div>
          )}
        </div>
        {showForm ? (
          <div className="contact-form bg-white rounded shadow">
            <ErrorDialog error={error} onClick={() => setShowForm((showForm) => false)} />
          </div>
        ) : null}
        {showFormSuccess ? (
          <div className="contact-form bg-white rounded shadow">
            <SuccessDialog success={t("sent-success")} onClick={() => setShowFormSuccess((showForm) => false)} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AddTask;
